import { gql, useQuery } from "@apollo/client";
import {
  CompCycleSubmitChangesButtonLoadingBoundaryQuery,
  CompCycleSubmitChangesButtonLoadingBoundaryQueryVariables,
  GetParticipantsInput,
} from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { CompCycleSubmitChangesButton } from "./CompCycleSubmitChangesButton";

type Props = { compCycleId: number; filter: GetParticipantsInput };

export function CompCycleSubmitChangesButtonLoadingBoundary({
  compCycleId,
  filter,
}: Props): JSX.Element | null {
  const { data, loading } = useQuery<
    CompCycleSubmitChangesButtonLoadingBoundaryQuery,
    CompCycleSubmitChangesButtonLoadingBoundaryQueryVariables
  >(CompCycleSubmitChangesButtonLoadingBoundary.query, {
    variables: { compCycleId, filter, skipEligibility: true },
  });

  if (!data || !data.compCycle2 || !data.whoami) {
    return loading ? <LoadingSpinner /> : <>An error has occurred</>;
  }

  return (
    <CompCycleSubmitChangesButton
      compCycleId={compCycleId}
      currentEmployee={data.whoami.user.employee ?? null}
      participants={data.compCycle2.participants.participants}
    />
  );
}

CompCycleSubmitChangesButtonLoadingBoundary.query = gql`
  ${CompCycleSubmitChangesButton.fragments.participant}
  ${CompCycleSubmitChangesButton.fragments.currentEmployee}
  query CompCycleSubmitChangesButtonLoadingBoundaryQuery(
    $compCycleId: Int!
    $filter: GetParticipantsInput
    $skipEligibility: Boolean
  ) {
    whoami {
      user {
        employee {
          id
          ...CompCycleSubmitChangesButton_currentEmployee
        }
      }
    }
    compCycle2(id: $compCycleId) {
      id
      participants(input: $filter) {
        participants {
          subjectId
          compCycleId
          ...CompCycleSubmitChangesButton_participant
        }
      }
    }
  }
`;
