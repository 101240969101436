import { gql, useQuery } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { contramap } from "@asmbl/shared/sort";
import { CircularProgress } from "@material-ui/core";
import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "src/components/Auth/AuthContext";
import {
  CompCycleFilterBarQuery,
  CompCycleFilterBarQueryVariables,
  GetParticipantsInput,
  GetParticipantsInputReports,
} from "../../../__generated__/graphql";
import {
  SearchableFilter,
  SearchableFilterOption,
} from "../../../components/Filter/SearchableFilter";
import { SearchableFilterContainer } from "../../../components/Filter/SearchableFilterContainer";
import { BandPlacementDisplay } from "../../../models/Band";
import { CompItemRecommendationTypeTitle } from "../../../models/CompRecommendation";

type Props = {
  compCycleId: number;
  filter: GetParticipantsInput;
  onChange(
    field: keyof GetParticipantsInput,
    values: string[] | number[]
  ): unknown;
  onReset(): unknown;
};

enum FilterName {
  MANAGER = "Manager",
  REPORTS = "Reports",
  STATUS = "Status",
  CURRENT_DEPARTMENT = "Current Department",
  CURRENT_LADDER = "Current Ladder",
  CURRENT_LEVEL = "Current Level",
  LOCATION_GROUP = "Location Group",
  PERFORMANCE_RATING = "Performance Rating",
  ADJUSTMENT_TYPE = "Adjustment Type",
  CURRENT_BAND_POSITION = "Current Band Position",
}

function CompCycleFilterBar({
  compCycleId,
  filter,
  onChange,
  onReset,
}: Props): JSX.Element | null {
  const { employeeId, permissions } = useAuth();
  const [urlParams] = useSearchParams();

  const { data } = useQuery<
    CompCycleFilterBarQuery,
    CompCycleFilterBarQueryVariables
  >(CompCycleFilterBar.query, {
    variables: {
      compCycleId,
    },
  });

  if (!data) return <CircularProgress />;

  const managerOptions = (
    permissions.isHRBP() ? data.actableManagers ?? [] : data.managers
  )
    .map((m) => option(m.displayName ?? "", m.id))
    .sort(contramap((o) => o.label));

  const reportOptions = [
    option("Direct", GetParticipantsInputReports.direct),
    option("Indirect", GetParticipantsInputReports.indirect),
  ];

  // Location Groups, sorted first by Market name, and then Location Group name.
  const locationOptions = data.markets
    .flatMap((m) => m.locationGroups.map((l) => option(l.name, l.id, m.name)))
    .sort(contramap((o) => o.label))
    .sort(contramap((o) => o.group));

  const departmentOptions = data.organization.departments
    .map((d) => option(d.name, d.id))
    .sort(contramap((o) => o.label));

  const ladderOptions = data.organization.departments
    .flatMap((d) => d.ladders.map((l) => option(l.name, l.id)))
    .sort(contramap((o) => o.label));

  const levelOptions =
    data.organization.compStructure?.levels
      .map((l) => option(l, l))
      .sort(contramap((o) => o.value)) ?? [];

  const perfRatingOptions =
    data.compCycle?.perfRatings
      .map((p) => option(p, p))
      .sort(contramap((o) => o.label)) ?? [];

  const adjustmentTypeOptions = Object.entries(
    CompItemRecommendationTypeTitle
  ).map(([key, value]) => option(value, key));

  const bandPlacementOptions = Object.entries(BandPlacementDisplay).map(
    ([key, value]) => option(value, key)
  );

  const activeFilterMap: Record<
    Exclude<FilterName, FilterName.STATUS>,
    boolean
  > = {
    [FilterName.MANAGER]: (filter.managerIds?.length ?? 0) > 0,
    [FilterName.LOCATION_GROUP]: (filter.locationGroupIds?.length ?? 0) > 0,
    [FilterName.CURRENT_DEPARTMENT]: (filter.departmentIds?.length ?? 0) > 0,
    [FilterName.CURRENT_LADDER]: (filter.ladderIds?.length ?? 0) > 0,
    [FilterName.CURRENT_LEVEL]: (filter.levels?.length ?? 0) > 0,
    [FilterName.PERFORMANCE_RATING]: (filter.perfRatings?.length ?? 0) > 0,
    [FilterName.ADJUSTMENT_TYPE]: (filter.recItems?.length ?? 0) > 0,
    [FilterName.REPORTS]: (filter.reports?.length ?? 0) > 0,
    [FilterName.CURRENT_BAND_POSITION]: (filter.bandPlacement?.length ?? 0) > 0,
  } as const;

  const activeFilters = Object.entries(activeFilterMap)
    .filter(([, value]) => value)
    .map(([key]) => key);

  return (
    <SearchableFilterContainer activeFilters={activeFilters} onReset={onReset}>
      <SearchableFilter
        name={FilterName.MANAGER}
        options={managerOptions}
        selected={filter.managerIds ?? []}
        onChange={(values) => onChange("managerIds", values)}
        multiple={!permissions.isHRBP()}
        disableChip={
          !permissions.canViewGlobal(Noun.Employee) &&
          urlParams.has("manager") &&
          filter.managerIds?.length === 1 &&
          filter.managerIds[0] === employeeId
        }
      />
      <SearchableFilter
        name={FilterName.REPORTS}
        options={reportOptions}
        selected={getSelectedReportsFilter(filter.reports)}
        onChange={(values) => onChange("reports", values)}
        disabled={(filter.managerIds?.length ?? 0) === 0}
      />
      <SearchableFilter
        name={FilterName.CURRENT_DEPARTMENT}
        options={departmentOptions}
        selected={filter.departmentIds ?? []}
        onChange={(values) => onChange("departmentIds", values)}
      />
      <SearchableFilter
        name={FilterName.CURRENT_LADDER}
        options={ladderOptions}
        selected={filter.ladderIds ?? []}
        onChange={(values) => onChange("ladderIds", values)}
      />
      <SearchableFilter
        name={FilterName.CURRENT_LEVEL}
        options={levelOptions}
        selected={filter.levels ?? []}
        onChange={(values) => onChange("levels", values)}
      />
      <SearchableFilter
        name={FilterName.LOCATION_GROUP}
        options={locationOptions}
        selected={filter.locationGroupIds ?? []}
        onChange={(values) => onChange("locationGroupIds", values)}
      />
      <SearchableFilter
        name={FilterName.PERFORMANCE_RATING}
        options={perfRatingOptions}
        selected={filter.perfRatings ?? []}
        onChange={(values) => onChange("perfRatings", values)}
      />
      <SearchableFilter
        name={FilterName.ADJUSTMENT_TYPE}
        options={adjustmentTypeOptions}
        selected={filter.recItems ?? []}
        onChange={(values) => onChange("recItems", values)}
      />
      <SearchableFilter
        name={FilterName.CURRENT_BAND_POSITION}
        options={bandPlacementOptions}
        selected={filter.bandPlacement ?? []}
        onChange={(values) => onChange("bandPlacement", values)}
      />
    </SearchableFilterContainer>
  );
}

export default memo(CompCycleFilterBar);

CompCycleFilterBar.query = gql`
  query CompCycleFilterBarQuery($compCycleId: Int!) {
    organization {
      id
      compStructure {
        id
        levels
      }
      departments {
        id
        name
        ladders {
          id
          name
        }
      }
    }
    compCycle(id: $compCycleId) {
      id
      perfRatings
    }
    managers {
      id
      displayName
    }
    actableManagers(compCycleId: $compCycleId) {
      id
      displayName
    }
    markets {
      id
      name
      locationGroups {
        id
        name
      }
    }
  }
`;

function option<T>(
  label: string | number,
  value: T
): { label: string; value: T; group: undefined };
function option<T>(
  label: string | number,
  value: T,
  group: string
): { label: string; value: T; group: string };
function option<T>(
  label: string | number,
  value: T,
  group?: string
): SearchableFilterOption<T> {
  return { label: label.toString(), value, group };
}

// Since "All" is a valid option on the server but not the client, we have to
// turn it into a combination of direct + indirect reports in the UI.
function getSelectedReportsFilter(
  reports: GetParticipantsInputReports | null | undefined
): GetParticipantsInputReports[] {
  if (reports == null) return [];
  if (reports === GetParticipantsInputReports.all) {
    return [
      GetParticipantsInputReports.direct,
      GetParticipantsInputReports.indirect,
    ];
  }
  return [reports];
}
