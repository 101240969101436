import { gql, useQuery } from "@apollo/client";
import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Slide,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import {
  GetLadder,
  GetLadderVariables,
  Noun,
  LadderDetail_permissionSettings as OrganizationPermissionSettings,
} from "../../__generated__/graphql";
import { AccessBoundary } from "../../components/AccessBoundary";
import { AdjustmentsBar } from "../../components/AdjustmentsBar";
import {
  AssembleBreadCrumb,
  AssembleBreadCrumbs,
  AssembleDropdownBreadCrumb,
} from "../../components/AssembleBreadCrumbs";
import { useCurrencies } from "../../components/CurrenciesContext";
import { NoCompStructure } from "../../components/EmptyState/EmptyState";
import { ErrorView } from "../../components/ErrorView";
import { useLocations } from "../../components/LocationsContext";
import { isSupportedCompensationType } from "../../models/AnonymizedCompensation";
import { bandNameComparator } from "../../utils";
import { AnonymizedCompensationSwitch } from "./AnonymizedCompensation/AnonymizedCompensationSwitch";
import { useExcludedBandNames } from "./ExcludedBandNameContext";
import { LadderDetailTable } from "./LadderDetailTable";
import { LadderForm } from "./LadderForm";
import { LadderPositionCreator } from "./LadderPositionCreator";

const useStyles = makeStyles((theme) => ({
  nameTextField: {
    fontSize: theme.typography.h5.fontSize,
    marginLeft: -theme.spacing(1),
    marginTop: "1px",
  },
  selectedRowActionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "40px",
  },
  rightActionContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(2.5),
    alignItems: "center",
  },
  breadCrumbContainer: {
    "& .MuiBreadcrumbs-ol > li:last-child": {
      width: "100%",
    },
  },
  leafCrumb: {
    height: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  comparisonButtonContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
}));

function getVisiblePositions<P extends { id: number; level: number }>(
  positions: P[],
  selectedIds: Set<number>,
  isComparing: boolean
): P[] {
  let visiblePositions = positions;
  if (isComparing) {
    visiblePositions = positions.filter((p) => selectedIds.has(p.id));
  }
  return visiblePositions.slice().sort((a, b) => a.level - b.level);
}

type Props = {
  organizationPermissionSettings: OrganizationPermissionSettings;
};

export function LadderDetail({
  organizationPermissionSettings,
}: Props): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const { selectedCurrency } = useCurrencies();
  const { selectedLocation } = useLocations();

  const [isEditingLadder, setIsEditingLadder] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set<number>());
  const [isComparing, setIsComparing] = useState(false);
  const { excludedBandNames, toggleBandExclusion } = useExcludedBandNames();
  const [anonymizedView, updateAnonymizedView] = useState(false);

  const { data, loading, error } = useQuery<GetLadder, GetLadderVariables>(
    LadderDetail.query,
    {
      variables: {
        ladderId: Number(id),
        marketId: selectedLocation?.[0].id ?? -1,
        locationGroupId: selectedLocation?.[1].id ?? null,
        currencyCode: selectedCurrency.code,
      },
    }
  );

  // Clear comparison state if source data changes (e.g., from navigating to a
  // different position)
  useEffect(() => {
    setIsComparing(false);
    setSelectedRows(new Set());
  }, [data?.ladder?.positions]);

  if (loading) return <LinearProgress />;
  if (error) return <ErrorView text={JSON.stringify(error)} />;
  if (!data || !data.ladder) return <ErrorView text="Not Found" />;
  if (!data.compStructure) return <NoCompStructure />;

  const { ladder } = data;
  const ladders = ladder.department.ladders
    .slice()
    .sort(contramap((d) => d.name, caseInsensitiveComparator));

  const totalPositions = ladder.positions.length;
  const visiblePositions = getVisiblePositions(
    ladder.positions,
    selectedRows,
    isComparing
  );

  const bandNames = data.compStructure.allBandTypes
    .slice()
    .sort(bandNameComparator);

  const handleRowSelect = (positionId: number) => {
    const newSet = new Set(selectedRows);
    selectedRows.has(positionId)
      ? newSet.delete(positionId)
      : newSet.add(positionId);
    setSelectedRows(newSet);
    if (newSet.size === 0) setIsComparing(false);
  };

  const handleSelectedAll = () => {
    if (selectedRows.size > 0) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(visiblePositions.map((p) => p.id)));
    }
  };

  return (
    <Box px={5} py={4}>
      {isEditingLadder && (
        <LadderForm
          mode="edit"
          persistedLadder={ladder}
          departmentId={ladder.department.id}
          onCancel={() => setIsEditingLadder(false)}
        />
      )}
      <AdjustmentsBar />

      <div className={classes.breadCrumbContainer}>
        <AssembleBreadCrumbs variant="vertical">
          <AssembleBreadCrumb to="/" display={ladder.organization.name} />
          <AssembleBreadCrumb
            to={`/departments/${ladder.department.id}`}
            display={ladder.department.name}
          />
          <div className={classes.leafCrumb}>
            <div className={classes.leftContainer}>
              <AssembleDropdownBreadCrumb
                options={ladders.map(({ id, name }) => ({
                  id,
                  name,
                  to: `/ladders/${id}`,
                }))}
                selectedOption={{
                  id: ladder.id,
                  name: ladder.name,
                  to: `/ladders/${ladder.id}`,
                }}
              />

              <Box m={1} />

              <AccessBoundary
                scope="global"
                verb="edit"
                noun={Noun.JobStructure}
              >
                <Tooltip title="Edit Ladder" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => setIsEditingLadder(true)}
                  >
                    <EditIcon
                      color={GRAY_4}
                      hoverColor={PURPLE_2}
                      width="24px"
                      height="24px"
                    />
                  </IconButton>
                </Tooltip>
              </AccessBoundary>
            </div>
            <LadderPositionCreator ladderId={ladder.id} />
          </div>
        </AssembleBreadCrumbs>
      </div>
      <Box m={2} />
      <Box className={classes.selectedRowActionsContainer}>
        <Box display="flex" flexDirection="row">
          {bandNames.map((bandName) => {
            if (anonymizedView && !isSupportedCompensationType(bandName)) {
              return null;
            }

            return (
              <FormControlLabel
                key={bandName}
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={!excludedBandNames.has(bandName)}
                    onChange={() => toggleBandExclusion(bandName)}
                  />
                }
                label={
                  <Typography variant="body2" noWrap>
                    {bandName}
                  </Typography>
                }
              />
            );
          })}
        </Box>
      </Box>
      <Box m={2} />
      <LadderDetailTable
        permissionSettings={organizationPermissionSettings}
        positions={visiblePositions}
        totalPositions={totalPositions}
        selectedRows={selectedRows}
        isComparing={isComparing}
        bandNames={bandNames}
        handleRowSelect={handleRowSelect}
        handleSelectedAll={handleSelectedAll}
        selectedCurrency={selectedCurrency}
        anonymizedView={anonymizedView}
        updateAnonymizedView={updateAnonymizedView}
        key={anonymizedView ? "anonymized" : "non-anonymized"}
      />
      <div className={classes.comparisonButtonContainer}>
        <Slide in={selectedRows.size > 0} direction="up">
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsComparing(!isComparing)}
          >
            {isComparing ? "Show all positions" : "Compare positions"}
          </Button>
        </Slide>
      </div>
    </Box>
  );
}

LadderDetail.fragments = {
  position: gql`
    ${LadderDetailTable.fragments.position}
    fragment LadderDetail_position on Position {
      ...LadderDetailTable_position
    }
  `,
  permissionSettings: gql`
    ${AnonymizedCompensationSwitch.fragments.permissionSettings}
    ${LadderDetailTable.fragments.permissionSettings}
    fragment LadderDetail_permissionSettings on PermissionSettings {
      id
      anonymizedCompensationStatisticsAccess
      ...AnonymizedCompensationSwitch_permissionSettings
      ...LadderDetailTable_permissionSettings
    }
  `,
};

LadderDetail.query = gql`
  ${LadderDetail.fragments.position}
  query GetLadder(
    $ladderId: Int!
    $currencyCode: CurrencyCode!
    $marketId: Int!
    $locationGroupId: Int
  ) {
    ladder(id: $ladderId) {
      id
      name
      description
      organization {
        id
        name
      }
      department {
        id
        name
        ladders {
          id
          name
        }
      }
      positions {
        id
        ...LadderDetail_position
      }
    }
    compStructure {
      id
      bandPointTypes
      cashBandTypes
      equityBandTypes
      allBandTypes
      vestingMonths
    }
  }
`;
