import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { LinkTab } from "src/components/Layout/LinkTab";
import { PageHeader, PageTitle } from "src/components/Layout/PageHeader";
import { GRAY_1, GRAY_4 } from "src/theme";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    height: "100%",
    gap: theme.spacing(0.5),
  },
  tabBarContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tabBar: {
    display: "flex",
    flexGrow: 1,
    minHeight: theme.spacing(4.4),
  },
  tabsContainer: {
    display: "flex",
    gap: theme.spacing(3),
  },
  tab: {
    color: GRAY_4,
    textDecoration: "none",

    "&:hover": {
      color: GRAY_1,
      opacity: 1,
    },
  },
  activeTab: {
    color: GRAY_1,
  },
}));

export function InsightsHeader(): JSX.Element | null {
  const location = useLocation();
  const classes = useStyles();

  const value = location.pathname.includes("bands")
    ? "bands"
    : "band-placement";

  return (
    <PageHeader>
      <div className={classes.header}>
        <PageTitle>Insights</PageTitle>
        {
          <div className={classes.tabBarContainer}>
            <Tabs
              value={value}
              className={classes.tabBar}
              classes={{ flexContainer: classes.tabsContainer }}
            >
              <Tab
                component={LinkTab}
                route="/insights/bands"
                title="Bands"
                classes={{
                  selected: classes.activeTab,
                  textColorInherit: classes.tab,
                }}
                value="bands"
              />
              <Tab
                component={LinkTab}
                route="/insights/band-placement"
                title="Band Placement"
                classes={{
                  selected: classes.activeTab,
                  textColorInherit: classes.tab,
                }}
                value="band-placement"
              />
            </Tabs>
          </div>
        }
      </div>
    </PageHeader>
  );
}
