import { gql } from "@apollo/client";
import { Divider, makeStyles } from "@material-ui/core";
import { BudgetVariableDetail } from "src/components/CompCycle/CompCycleBudgetBar/BudgetVariableDetail";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import {
  BudgetBarComponents2_compCycle,
  BudgetBarComponents2_compCycleBudget,
  BudgetBarComponents2_valuation,
  CashCompType,
  RecItemType,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { GRAY_4, GRAY_6, GRAY_7, PURPLE_1, WHITE } from "../../../theme";
import { CompCycleGrouping } from "../types";
import { BudgetCashDetail } from "./BudgetCashDetail";
import { BudgetEquityDetail } from "./BudgetEquityDetail";

const useStyles = makeStyles((theme) => ({
  pane: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  root: {
    top: "auto",
    bottom: 0,
    backgroundColor: "rgba(255, 0, 0, 0)",
    boxShadow: "none",
    left: theme.spacing(10.125), // account for navbar and border
  },
  tabContainer: {
    display: "flex",
    overflow: "hidden",
    paddingTop: "5px",
    maxHeight: "2rem",
  },
  tab: {
    backgroundColor: WHITE,
    minWidth: "7.75rem",
    height: "2.5rem",
    boxShadow: `0px 0px 0px 1px ${GRAY_6}`,
    borderRadius: "0px 5px 0px 0px",
    zIndex: 1,
    justifySelf: "flex-start",
  },
  tabButton: {
    padding: theme.spacing(0, 0, 0, 1.5),
  },
  tabText: {
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "0.75rem",
    lineHeight: "140%",
    "&:hover": {
      color: PURPLE_1,
    },
  },
  rotatedIcon: {
    transform: "rotate(180deg)",
  },
  toolbar: {
    height: "5.75rem",
    backgroundColor: WHITE,
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    width: "100%",
  },
  totalBudgetContainer: {
    backgroundColor: GRAY_7,
    borderRadius: "5px",
    height: "3.75rem",
    width: "6.25rem",
    margin: theme.spacing(0, 2, 0, 1.5),
    padding: theme.spacing(1.25, 0, 0, 2),
  },
  totalBudgetText: {
    color: GRAY_4,
    fontSize: "1.125rem",
    letterSpacing: "-0.25px",
    lineHeight: "110%",
    fontWeight: 400,
  },
  subComponentContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    height: "100%",
  },
  verticalDivider: {
    margin: theme.spacing(0, 2),
  },
  toolbarHeight: {
    height: theme.spacing(11.5),
  },
}));

type Props = {
  filter: CompCycleGrouping;
  recommendationItems: RecItemWithSubject[];
  compCycle: BudgetBarComponents2_compCycle;
  budget: BudgetBarComponents2_compCycleBudget | null;
  valuation: BudgetBarComponents2_valuation;
};

export const BudgetBarComponents2 = ({
  filter,
  recommendationItems,
  compCycle,
  budget,
  valuation,
}: Props): JSX.Element => {
  const classes = useStyles();
  const {
    allowSalary,
    allowSalaryMarket,
    allowSalaryMerit,
    allowSalaryPromotion,
    allowActualRecurringBonus,
    allowTargetRecurringBonus,
    allowTargetCommission,
    allowBonus,
    allowEquity,
  } = compCycle;

  return (
    <div className={classes.subComponentContainer}>
      {/* salary pane components */}
      {allowSalaryPromotion && filter === "salary" && (
        <>
          <BudgetCashDetail
            compCycleBudget={budget?.salaryPromotion ?? undefined}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.PROMOTION
            )}
            budgetType={BudgetType.PROMOTION}
          />
          <VerticalDivider />
        </>
      )}
      {allowSalaryMarket && filter === "salary" && (
        <>
          <BudgetCashDetail
            compCycleBudget={budget?.salaryMarket}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.MARKET
            )}
            budgetType={BudgetType.MARKET}
          />
          <VerticalDivider />
        </>
      )}
      {allowSalaryMerit && filter === "salary" && (
        <>
          <BudgetCashDetail
            compCycleBudget={budget?.salaryMerit ?? undefined}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.MERIT_INCREASE
            )}
            budgetType={BudgetType.MERIT}
          />
          <VerticalDivider />
        </>
      )}
      {/* totals components */}
      {allowSalary && (filter === "salary" || filter === "all") && (
        <>
          <BudgetCashDetail
            compCycleBudget={budget?.salary ?? undefined}
            items={recommendationItems.filter(
              (item) =>
                item.recommendationType === RecItemType.PROMOTION ||
                item.recommendationType === RecItemType.MERIT_INCREASE ||
                item.recommendationType === RecItemType.MARKET
            )}
            budgetType={BudgetType.TOTAL_SALARY}
          />
          <VerticalDivider />
        </>
      )}
      {allowTargetCommission && (filter === "target" || filter === "all") && (
        <>
          <BudgetVariableDetail
            compCycleBudget={budget?.targetCommission ?? undefined}
            items={recommendationItems.filter(
              (i) => i.recommendationType === RecItemType.TARGET_COMMISSION
            )}
            budgetType={BudgetType.TARGET_COMMISSION}
            variableCompType={CashCompType.COMMISSION}
          />
          <VerticalDivider />
        </>
      )}
      {allowTargetRecurringBonus &&
        (filter === "target" || filter === "all") && (
          <>
            <BudgetVariableDetail
              compCycleBudget={budget?.targetRecurringBonus ?? undefined}
              items={recommendationItems.filter(
                (i) =>
                  i.recommendationType === RecItemType.TARGET_RECURRING_BONUS
              )}
              budgetType={BudgetType.TARGET_RECURRING_BONUS}
              variableCompType={CashCompType.RECURRING_BONUS}
            />
            <VerticalDivider />
          </>
        )}
      {allowActualRecurringBonus &&
        (filter === "actual" || filter === "all") && (
          <>
            <BudgetCashDetail
              compCycleBudget={budget?.actualRecurringBonus ?? undefined}
              items={recommendationItems.filter(
                (i) =>
                  i.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS
              )}
              budgetType={BudgetType.ACTUAL_RECURRING_BONUS}
            />
            <VerticalDivider />
          </>
        )}
      {allowBonus && (filter === "actual" || filter === "all") && (
        <>
          <BudgetCashDetail
            compCycleBudget={budget?.bonus ?? undefined}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.MERIT_BONUS
            )}
            budgetType={BudgetType.ONE_TIME_BONUS}
          />
          <VerticalDivider />
        </>
      )}
      {allowEquity && (filter === "equity" || filter === "all") && (
        <>
          <BudgetEquityDetail
            compCycleBudget={budget?.equity ?? undefined}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.EQUITY_GRANT
            )}
            budgetType={BudgetType.EQUITY}
            valuation={valuation}
          />
          <VerticalDivider />
        </>
      )}
    </div>
  );
};

function VerticalDivider(): JSX.Element {
  const classes = useStyles();
  return (
    <Divider
      orientation="vertical"
      flexItem
      className={classes.verticalDivider}
    />
  );
}

BudgetBarComponents2.fragments = {
  compCycle: gql`
    fragment BudgetBarComponents2_compCycle on CompCycle2 {
      id
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      allowActualRecurringBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowEquity
      allowBonus
    }
  `,
  compCycleBudget: gql`
    fragment BudgetBarComponents2_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      salary
      salaryMarket
      salaryMerit
      salaryPromotion
      bonus
      equity
      targetRecurringBonus
      targetCommission
      actualRecurringBonus
    }
  `,
  valuation: gql`
    ${BudgetEquityDetail.fragments.valuation}
    fragment BudgetBarComponents2_valuation on Valuation {
      ...BudgetEquityDetail_valuation
    }
  `,
};
