import { gql, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { useFeatureFlags } from "src/components/FeatureContext";
import { ErrorView } from "../../../components/ErrorView";
import {
  GetPotentialParticipants,
  GetPotentialParticipantsVariables,
} from "../../../__generated__/graphql";
import { usePagination } from "../Plan/Contexts/PaginationContext";
import { EligibleIneligibleTable } from "./EligibleIneligibleTable";

type Props = {
  compCycleId: number;
};

export function ParticipantsLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const [showEligible, setShowEligible] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const featureFlags = useFeatureFlags();
  const { currentPage, pageSize } = usePagination();
  const { data, loading } = useQuery<
    GetPotentialParticipants,
    GetPotentialParticipantsVariables
  >(ParticipantsLoadingBoundary.query, {
    variables: {
      compCycleId,
      limit: featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
        ? pageSize
        : undefined,
      offset: featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
        ? currentPage * pageSize
        : undefined,
      eligibleOrIneligible: showEligible ? "eligible" : "ineligible",
      displayName: featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
        ? searchTerm
        : undefined,
      order,
      orderBy,
    },
  });

  if (!data) {
    return loading ? (
      <div style={{ marginBottom: "40rem" }}>
        <CircularProgress />
      </div>
    ) : (
      <ErrorView />
    );
  }

  const setOrderParams = (field: string) => {
    if (field === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  return (
    <EligibleIneligibleTable
      people={data.compCycle?.potentialParticipants}
      showEligible={showEligible}
      setShowEligible={setShowEligible}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      order={order}
      setOrderParams={setOrderParams}
      orderBy={orderBy}
    />
  );
}

ParticipantsLoadingBoundary.query = gql`
  ${EligibleIneligibleTable.fragments.employee}
  ${EligibleIneligibleTable.fragments.perfRating}
  query GetPotentialParticipants(
    $compCycleId: Int!
    $limit: Int
    $offset: Int
    $eligibleOrIneligible: String
    $displayName: String
    $order: String
    $orderBy: String
  ) {
    compCycle(id: $compCycleId) {
      id
      potentialParticipants(
        limit: $limit
        offset: $offset
        eligibleOrIneligible: $eligibleOrIneligible
        displayName: $displayName
        order: $order
        orderBy: $orderBy
      ) {
        eligible {
          ...EligibleIneligibleTable_employee
          perfRating(compCycleId: $compCycleId) {
            id
            ...EligibleIneligibleTable_perfRating
          }
        }
        ineligible {
          ...EligibleIneligibleTable_employee
          perfRating(compCycleId: $compCycleId) {
            id
            ...EligibleIneligibleTable_perfRating
          }
        }
        counts {
          eligible
          ineligible
        }
      }
    }
  }
`;
