import { gql } from "@apollo/client";
import { collapseAdjustedBands } from "@asmbl/shared/band";
import { eq, moneyComparator } from "@asmbl/shared/money";
import { Tooltip, makeStyles } from "@material-ui/core";
import { memo, useState } from "react";
import { getBandPointCashEquivalent } from "src/models/BandPoint";
import {
  TotalCashCondensedSlider_band as CashBand,
  TotalCashCondensedSlider_cash as CashCompensation,
  PayPeriodType,
  TotalCashPopover_position as Position,
} from "../../../__generated__/graphql";
import { TotalCash } from "../../../models/CashCompensation";
import { PURPLE_1, WHITE } from "../../../theme";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { CondensedHourlyBandPoint, CondensedSlider } from "../CondensedSlider";
import { TotalCashPopover } from "./TotalCashPopover";

export type Props = {
  cash: TotalCash<CashCompensation>;
  newCash?: TotalCash<CashCompensation>;
  bands: CashBand[];
  position?: Position;
  size?: "default" | "wide";
  color?: "default" | "gray";
  showBreakdown?: boolean;
  isHourly?: boolean;
  payTypeSelection?: PayPeriodType;
};

const useStyles = makeStyles(() => ({
  root: {
    width: ({ size }: Props) => (size === "wide" ? "7.5rem" : "3.75rem"),
    display: "flex",
    justifyContent: "center",
  },
  tooltip: {
    borderRadius: "10px",
    padding: "1rem 1.25rem",
    width: "320px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: WHITE,
  },
  toggleButton: {
    position: "fixed",
    right: "4px",
    fontSize: "0.8125rem",
    fontWeight: 400,
    color: PURPLE_1,
    transform: "translateY(-4px)",
  },
}));

const TotalCashCondensedSliderMemo = memo(function TotalCashCondensedSlider(
  props: Props
): JSX.Element {
  const classes = useStyles(props);

  const {
    cash,
    newCash,
    bands,
    position,
    color,
    showBreakdown,
    payTypeSelection,
    isHourly = false,
  } = props;

  const combinedBand = collapseAdjustedBands(bands);

  const sortedPoints = combinedBand
    .map((bp) => getBandPointCashEquivalent(bp, cash.currency, isHourly))
    .sort(moneyComparator);

  const [min, max] = [sortedPoints[0], sortedPoints[sortedPoints.length - 1]];
  const bandPointsAreEqual = sortedPoints.every((point) => eq(point, min));
  const sliderNewCash = newCash ? newCash : cash;
  const altValue = cash.annualTotal.value;

  return (
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        placement="top"
        interactive
        title={
          <TotalCashComparisonPopover
            cash={cash}
            newCash={newCash}
            bands={bands}
            position={position}
            showBreakdown={showBreakdown}
            isHourly={isHourly}
            payTypeSelection={payTypeSelection}
          />
        }
        disableFocusListener
      >
        {bandPointsAreEqual && isHourly ? (
          <CondensedHourlyBandPoint
            value={sliderNewCash.hourlyTotal.value}
            altValue={newCash ? altValue : undefined}
            bandPointValue={min.value}
            color={color}
          />
        ) : (
          <CondensedSlider
            value={
              isHourly
                ? sliderNewCash.hourlyTotal.value
                : sliderNewCash.annualTotal.value
            }
            altValue={newCash ? altValue : undefined}
            min={min.value}
            max={max.value}
            color={color}
            outOfRangeStyle="band"
          />
        )}
      </Tooltip>
    </div>
  );
});

function TotalCashComparisonPopover(props: Props) {
  const classes = useStyles(props);
  const {
    cash,
    newCash,
    bands,
    position,
    showBreakdown,
    payTypeSelection = PayPeriodType.ANNUAL,
    isHourly = false,
  } = props;

  const [isNewVisible, setIsNewVisible] = useState(true);

  return (
    <div>
      {newCash && (
        <AssembleButton
          className={classes.toggleButton}
          variant="text"
          onClick={() => setIsNewVisible(!isNewVisible)}
          label={`Show ${isNewVisible ? "Current" : "New"}`}
          size="small"
        />
      )}
      <TotalCashPopover
        cash={newCash && isNewVisible ? newCash : cash}
        bands={bands}
        position={position}
        showBreakdown={showBreakdown}
        isHourly={isHourly}
        payTypeSelection={payTypeSelection}
      />
    </div>
  );
}

export const TotalCashCondensedSlider = Object.assign(
  TotalCashCondensedSliderMemo,
  {
    fragments: {
      cash: gql`
        ${TotalCashPopover.fragments.cash}
        fragment TotalCashCondensedSlider_cash on CashCompensation2 {
          ...TotalCashPopover_cash
        }
      `,
      band: gql`
        ${TotalCashPopover.fragments.band}
        fragment TotalCashCondensedSlider_band on AdjustedCashBand {
          ...TotalCashPopover_band
        }
      `,
      position: gql`
        ${TotalCashPopover.fragments.position}
        fragment TotalCashCondensedSlider_position on Position {
          ...TotalCashPopover_position
        }
      `,
    },
  }
);
