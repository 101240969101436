import {
  CashCompType,
  CompUnit,
  CurrencyCode,
} from "src/__generated__/graphql";
import { annualizedCashCompToBandPoint, getCompaRatio } from "./BandPoint";

// Determine which merit guidance option should be returned for a given employee
export const getGuidance = (
  employee: {
    adjustedCashBands:
      | {
          id: string;
          name: string;
          bandPoints: {
            __typename: string;
            id: string;
            bandName: string;
            name: string;
            value: {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            };
          }[];
        }[]
      | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    perfRating: { perfRating?: string | null } | null;
  },
  meritAdjustmentGuides: {
    perfRatingOptionId: number;
    meritAdjustmentRangeId: number;
    percent: number;
  }[],
  meritAdjustmentRanges: {
    rangeStart: number;
    id: number;
  }[],
  perfRatingOptions: {
    name: string;
    id: number;
  }[]
): number | null => {
  // Find the employee's adjusted salary
  const adjustedSalaryBand = employee.adjustedCashBands?.find(
    (band) => band.name === "Salary"
  );

  if (!adjustedSalaryBand || !employee.activeEmployment?.salary) return null;

  // Find the employee's comparatio, since merit guidance is based on this
  const compaRatio = getCompaRatio(adjustedSalaryBand.bandPoints)(
    annualizedCashCompToBandPoint({
      type: CashCompType.SALARY,
      annualCashEquivalent: employee.activeEmployment.salary,
      hourlyCashEquivalent: employee.activeEmployment.salary,
      percentOfSalary: 100,
      unit: CompUnit.CASH,
    })
  );

  if (compaRatio == null) return null;

  const sorted = meritAdjustmentRanges
    .slice(0)
    .sort((a, b) => a.rangeStart - b.rangeStart);

  // Find which range the employee's comparatio puts them in
  const meritAdjustmentRange = sorted.find((range, i) => {
    if (i === 0 && range.rangeStart > compaRatio) {
      return true;
    }
    if (i === sorted.length - 1) {
      return true;
    }
    if (
      range.rangeStart <= compaRatio &&
      sorted[i + 1].rangeStart > compaRatio
    ) {
      return true;
    }
  });

  if (!meritAdjustmentRange) return null;

  // Find which perf rating the employee has been given
  // If there is only one without a name, it applies to all employees
  const perfRating =
    perfRatingOptions.length === 1 && perfRatingOptions[0].name === ""
      ? perfRatingOptions[0]
      : perfRatingOptions.find(
          (rating) => rating.name === employee.perfRating?.perfRating
        );

  if (!perfRating) return null;

  // Find the correct guidance based on a combination of their adjustment
  // range and perf rating
  const guide = meritAdjustmentGuides.find(
    (guide) =>
      guide.perfRatingOptionId === perfRating.id &&
      guide.meritAdjustmentRangeId === meritAdjustmentRange.id
  );

  return guide?.percent ?? null;
};

// Determine which merit guidance option should be returned for a given employee
export const getGuidance2 = (
  employee: {
    adjustedCashBands:
      | {
          id: string;
          name: string;
          bandPoints: {
            __typename: string;
            id: string;
            bandName: string;
            name: string;
            value: {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            };
          }[];
        }[]
      | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    perfRating: string | null;
  },
  meritAdjustmentGuides: {
    perfRatingOptionId: number;
    meritAdjustmentRangeId: number;
    percent: number;
  }[],
  meritAdjustmentRanges: {
    rangeStart: number;
    id: number;
  }[],
  perfRatingOptions: {
    name: string;
    id: number;
  }[]
): number | null => {
  // If all merit guidance options are 0, then guidance hasn't been set
  if (meritAdjustmentGuides.every((guide) => guide.percent === 0)) {
    return null;
  }
  // Find the employee's adjusted salary
  const adjustedSalaryBand = employee.adjustedCashBands?.find(
    (band) => band.name === "Salary"
  );

  if (!adjustedSalaryBand || !employee.activeEmployment?.salary) return null;

  // Find the employee's comparatio, since merit guidance is based on this
  const compaRatio = getCompaRatio(adjustedSalaryBand.bandPoints)(
    annualizedCashCompToBandPoint({
      type: CashCompType.SALARY,
      annualCashEquivalent: employee.activeEmployment.salary,
      hourlyCashEquivalent: employee.activeEmployment.salary,
      percentOfSalary: 100,
      unit: CompUnit.CASH,
    })
  );

  if (compaRatio == null) return null;

  const sorted = meritAdjustmentRanges
    .slice(0)
    .sort((a, b) => a.rangeStart - b.rangeStart);

  // Find which range the employee's comparatio puts them in
  const meritAdjustmentRange = sorted.find((range, i) => {
    if (i === 0 && range.rangeStart > compaRatio) {
      return true;
    }
    if (i === sorted.length - 1) {
      return true;
    }
    if (
      range.rangeStart <= compaRatio &&
      sorted[i + 1].rangeStart > compaRatio
    ) {
      return true;
    }
  });

  if (!meritAdjustmentRange) return null;

  // Find which perf rating the employee has been given
  // If there is only one without a name, it applies to all employees
  const perfRating =
    perfRatingOptions.length === 1 && perfRatingOptions[0].name === ""
      ? perfRatingOptions[0]
      : perfRatingOptions.find((rating) => rating.name === employee.perfRating);

  if (!perfRating) return null;

  // Find the correct guidance based on a combination of their adjustment
  // range and perf rating
  const guide = meritAdjustmentGuides.find(
    (guide) =>
      guide.perfRatingOptionId === perfRating.id &&
      guide.meritAdjustmentRangeId === meritAdjustmentRange.id
  );

  return guide?.percent ?? null;
};
