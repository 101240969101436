import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getPromotionSalary } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { CondensedTableSalaryPromoCell2_participant as Participant } from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryPromoCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getPromotionSalary(
          recItems,
          employee.subject.activeEmployment?.salary?.currency ??
            defaultCurrencyCode
        )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryPromoCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryPromoCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableSalaryPromoCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_PROMO
);
CondensedTableSalaryPromoCell2.id = ColumnIds.SALARY_PROMO;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryPromoCell2,
  Header: CondensedTableSalaryPromoCell2.Header,
  id: CondensedTableSalaryPromoCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableSalaryPromoCell2.column = column;
CondensedTableSalaryPromoCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const salaryIncrease = getPromotionSalary(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(salaryIncrease.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(salaryIncrease, currentCurrency, defaultCurrency)
      .value;
  });
