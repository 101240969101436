import { gql } from "@apollo/client";
import { EquityBandName } from "@asmbl/shared/constants";
import { moneyComparator, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import { EquityRefreshCondensedSlider } from "src/components/CompSlider/Equity/EquityRefreshCondensedSlider";
import { useCurrencies } from "src/components/CurrenciesContext";
import {
  CondensedTableEquityBandCell2_compCycleParticipant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { BAND_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  slider: {
    width: "3.75rem",
  },
});

type Props = {
  row: { original: Participant };
};
export function CondensedTableEquityBandCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const classes = useStyles();

  const { currencies, defaultCurrency } = useCurrencies();

  const employeeCurrency =
    employee.subject.activeEmployment?.salary?.currency == null
      ? defaultCurrency
      : currencies.get(employee.subject.activeEmployment.salary.currency);

  // This should never hit, but just satisfying TypeScript
  if (employeeCurrency == null) return <></>;

  const latestEquityIncrease =
    employee.compRecommendation?.latestSubmittedItems.find(
      (item) => item.recommendationType === RecItemType.EQUITY_GRANT
    );

  return (
    <div className={classes.container}>
      <span className={classes.slider}>
        <EquityRefreshCondensedSlider
          bands={employee.subject.adjustedEquityBands}
          position={employee.subject.activeEmployment?.position}
          value={
            latestEquityIncrease?.recommendedCashValue ??
            zero(employeeCurrency.code)
          }
          narrow
        />
      </span>
    </div>
  );
}

CondensedTableEquityBandCell2.ordering = () =>
  contramap((employee: Participant) => {
    const latestEquityIncrease =
      employee.compRecommendation?.latestSubmittedItems.find(
        (item) => item.recommendationType === RecItemType.EQUITY_GRANT
      );

    const equityBand = employee.subject.adjustedEquityBands?.find(
      (band) => band.name === EquityBandName.EQUITY_REFRESH_GRANT
    );

    if (
      equityBand == null ||
      latestEquityIncrease?.recommendedCashValue?.value == null
    )
      return 0;

    const sortedBandPoints = equityBand.bandPoints
      .slice(0)
      .sort((a, b) =>
        moneyComparator(a.annualCashEquivalent, b.annualCashEquivalent)
      );

    const midpoint =
      sortedBandPoints[sortedBandPoints.length].annualCashEquivalent.value /
      sortedBandPoints[0].annualCashEquivalent.value;

    return latestEquityIncrease.recommendedCashValue.value / midpoint;
  });

CondensedTableEquityBandCell2.fragments = {
  participant: gql`
    ${EquityRefreshCondensedSlider.fragments.equityBand}
    ${EquityRefreshCondensedSlider.fragments.position}
    fragment CondensedTableEquityBandCell2_compCycleParticipant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        id
        adjustedEquityBands(currencyCode: $currencyCode) {
          id
          ...EquityRefreshCondensedSlider_equityBand
        }
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
          position {
            id
            ...EquityRefreshCondensedSlider_position
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableEquityBandCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.EQUITY_BAND_POINT
);
CondensedTableEquityBandCell2.id = ColumnIds.EQUITY_BAND_POINT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableEquityBandCell2,
  Header: CondensedTableEquityBandCell2.Header,
  id: CondensedTableEquityBandCell2.id,
  width: BAND_COL_WIDTH,
};
CondensedTableEquityBandCell2.column = column;
