import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { basicComparator, contramapWithOrder } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { GRAY_4 } from "src/theme";
import {
  CompRecommendationStatus,
  CondensedTableNewPositionIfChangedCell2_participant as Participant,
  PositionType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { findRecommendedPosition } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  rowActedOn: {
    "& path": {
      stroke: `${GRAY_4} !important`,
    },
  },
}));

export function CondensedTableNewPositionIfChangedCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { availablePositions } = useTableData();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const classes = useStyles();

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const newPosition = findRecommendedPosition(recItems, availablePositions);

  const label = newPosition?.name ?? "-";

  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);
  const positionType = newPosition?.type;

  const reviewStatus = employee.compRecommendation?.reviewStatus ?? null;
  const actionNeededStatuses = [
    CompRecommendationStatus.FUTURE_PHASE,
    CompRecommendationStatus.NEEDS_REVIEW,
    CompRecommendationStatus.LATE,
  ];
  const rowActedOn = Boolean(
    reviewStatus && !actionNeededStatuses.includes(reviewStatus)
  );

  return (
    <div className={clsx(classes.root, rowActedOn && classes.rowActedOn)}>
      {showHourly && (
        <>
          {positionType === PositionType.ANNUAL && <CalendarIcon />}
          {positionType === PositionType.HOURLY && <ClockIcon />}
        </>
      )}
      <AssembleTruncatedTypography lines={1} title={label}>
        {label}
      </AssembleTruncatedTypography>
    </div>
  );
}

CondensedTableNewPositionIfChangedCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewPositionIfChangedCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          payPeriod
          position {
            id
            name
            type
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
        latestSubmittedItems {
          id
          recommendationType
          recommendedPosition {
            id
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableNewPositionIfChangedCell_position on Position {
      id
      name
    }
  `,
};

CondensedTableNewPositionIfChangedCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_POSITION_IF_CHANGED
);
CondensedTableNewPositionIfChangedCell2.id = ColumnIds.NEW_POSITION_IF_CHANGED;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewPositionIfChangedCell2,
  Header: CondensedTableNewPositionIfChangedCell2.Header,
  id: CondensedTableNewPositionIfChangedCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableNewPositionIfChangedCell2.column = column;
CondensedTableNewPositionIfChangedCell2.ordering = ({
  availablePositions,
}: Parameters<ColumnComponent2["ordering"]>[0]) =>
  contramapWithOrder(
    (e: Participant) => {
      const recItems = e.compRecommendation?.latestSubmittedItems ?? [];
      const newPosition = findRecommendedPosition(recItems, availablePositions);
      return newPosition?.name ?? "-";
    },
    (a, b, order) => {
      const isSortAsc = order === "asc";
      if (a === "-") {
        return isSortAsc ? 1 : -1;
      }
      if (b === "-") {
        return isSortAsc ? -1 : 1;
      }
      return basicComparator(a, b);
    }
  );
