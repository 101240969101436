import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMeritBonus } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { CondensedTableBonusCell2_participant as Participant } from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableBonusCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const increase = getMeritBonus(
    recItems,
    employee.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
  );
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(increase)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableBonusCell2.fragments = {
  participant: gql`
    fragment CondensedTableBonusCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableBonusCell2.Header = ColumnIdsToHeaders.get(ColumnIds.BONUS);
CondensedTableBonusCell2.id = ColumnIds.BONUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableBonusCell2,
  Header: CondensedTableBonusCell2.Header,
  id: CondensedTableBonusCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableBonusCell2.column = column;
CondensedTableBonusCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const meritBonus = getMeritBonus(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(meritBonus.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(meritBonus, currentCurrency, defaultCurrency).value;
  });
