import {
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { memo } from "react";
import { CompComponentDisplay } from "../../../models/Budget";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleLabel } from "../../../components/Form/AssembleLabel";
import { useCurrencies } from "../../CurrenciesContext";
import Section from "../../Workflow/Section";
import { CashInput } from "./CashInput";
import { CompComponentsForm } from "./CompComponentsForm";
import { EquityInput } from "./EquityInput";
import { CompCycleData, CompCycleDataChangeHandler, Valuation } from "./types";
import { PURPLE_1, WARN_TEXT } from "src/theme";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  settingsContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBlock: theme.spacing(2),
  },
  disclaimer: {
    color: WARN_TEXT,
  },
}));

export type Props = {
  currentStep: number;
  data: CompCycleData;
  valuation: Valuation;
  handleChange: CompCycleDataChangeHandler;
  onNext: () => unknown;
};

export const CompCycleSettings = memo(function GenerateCompCycleSettings({
  currentStep,
  data,
  valuation,
  handleChange,
  onNext,
}: Props) {
  const classes = useStyles();
  const isNextDisabled = !isFormComplete(data);
  const { defaultCurrencyCode } = useCurrencies();

  const headerDescription = <>Customize your comp cycle to fit your needs.</>;
  const currYear = new Date().getUTCFullYear();

  return (
    <div className={classes.content}>
      <Section
        active={currentStep === 0}
        header="Comp Cycle Management"
        description={headerDescription}
      >
        <AssembleLabel htmlFor="comp-cycle-name">Cycle Name</AssembleLabel>
        <TextField
          fullWidth
          id="name"
          label="Name"
          placeholder={`i.e. - Q4 ${currYear}, ${currYear} Review...`}
          onChange={(e) => handleChange("name", e.target.value)}
          variant="outlined"
          value={data.name ?? ""}
        />
        <div className={classes.settingsContainer}>
          <Typography variant="h4" color="textPrimary">
            Budget Settings
          </Typography>
        </div>
        <div className={classes.settingsContainer}>
          <Typography variant="h5" color="textPrimary">
            Compensation Components in this Cycle
          </Typography>
          <Typography variant="body2">
            These are the compensation components that will be used during this
            cycle.
            <br />
            <b className={classes.disclaimer}>
              Note: selected components can't be changed after cycle setup.
            </b>
          </Typography>
          <CompComponentsForm
            compComponents={data.compComponents}
            handleChange={handleChange}
          />
        </div>
        {Object.values(data.compComponents).some(Boolean) && (
          <div className={classes.settingsContainer}>
            <Typography variant="h5" color="textPrimary">
              Total Budget
            </Typography>
            <Typography variant="body2">
              Enter the total budget for the compensation cycle, including any
              discretionary budget. You can change this later if you need to.
            </Typography>
            {data.compComponents.allowSalary && (
              <CashInput
                label={CompComponentDisplay.salary}
                id="comp-cycle-new-salary-input"
                currencyCode={defaultCurrencyCode}
                value={data.salaryBudget}
                onChange={(value) => handleChange("salaryBudget", value)}
              />
            )}
            {data.compComponents.allowTargetCommission && (
              <CashInput
                label={CompComponentDisplay.targetCommission}
                id="comp-cycle-new-target-commission-input"
                currencyCode={defaultCurrencyCode}
                value={data.targetCommissionBudget}
                onChange={(value) =>
                  handleChange("targetCommissionBudget", value)
                }
              />
            )}
            {data.compComponents.allowTargetRecurringBonus && (
              <CashInput
                label={CompComponentDisplay.targetRecurringBonus}
                id="comp-cycle-new-target-recurring-bonus-input"
                currencyCode={defaultCurrencyCode}
                value={data.targetRecurringBonusBudget}
                onChange={(value) =>
                  handleChange("targetRecurringBonusBudget", value)
                }
              />
            )}
            {data.compComponents.allowBonus && (
              <CashInput
                label={CompComponentDisplay.bonus}
                id="comp-cycle-new-bonus-input"
                currencyCode={defaultCurrencyCode}
                value={data.bonusBudget}
                onChange={(value) => handleChange("bonusBudget", value)}
              />
            )}
            {data.compComponents.allowActualRecurringBonus && (
              <CashInput
                label={CompComponentDisplay.actualRecurringBonus}
                id="comp-cycle-actual-recurring-bonus-input"
                currencyCode={defaultCurrencyCode}
                value={data.actualRecurringBonusBudget}
                onChange={(value) =>
                  handleChange("actualRecurringBonusBudget", value)
                }
              />
            )}
            {data.compComponents.allowEquity && (
              <EquityInput
                value={data.equityBudget}
                valuation={valuation}
                onChange={(value) => handleChange("equityBudget", value)}
              />
            )}
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Tooltip
            title="Enter all the comp cycle details to continue"
            placement="top"
            arrow
            disableHoverListener={!isNextDisabled}
          >
            <div>
              <AssembleButton
                disabled={isNextDisabled}
                onClick={onNext}
                variant="contained"
                label="Next"
                size="medium"
              />
            </div>
          </Tooltip>
        </div>
      </Section>
    </div>
  );
});

/**
 * Checks that the form is complete by three criteria:
 * 1. The user has filled out a name
 * 2. The user has selected at least one compensation component
 * 3. The user has entered a total budget for each selected component
 */
function isFormComplete(data: CompCycleData): boolean {
  const { compComponents } = data;

  const budgetsAreUnfilled = [
    [compComponents.allowSalary, data.salaryBudget],
    [compComponents.allowBonus, data.bonusBudget],
    [compComponents.allowEquity, data.equityBudget],
    [compComponents.allowTargetCommission, data.targetCommissionBudget],
    [compComponents.allowTargetRecurringBonus, data.targetRecurringBonusBudget],
    [compComponents.allowActualRecurringBonus, data.actualRecurringBonusBudget],
  ].some(([predicate, budget]) => predicate === true && budget == null);

  if (budgetsAreUnfilled) return false;

  return (
    data.name !== undefined && Object.values(data.compComponents).some(Boolean)
  );
}
