import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import {
  CashCompType,
  CurrencyCode,
  CondensedTablePrevSalaryCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { getExchangedSalary } from "../../../../models/Employment";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTablePrevSalaryCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const salary =
    employee.subject.activeCashCompensation?.find(
      (acc) => acc.type === CashCompType.SALARY
    )?.annualCashEquivalent ?? zero(defaultCurrencyCode);

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(salary)}
    </AssembleTruncatedTypography>
  );
}

CondensedTablePrevSalaryCell2.fragments = {
  participant: gql`
    fragment CondensedTablePrevSalaryCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
        }
      }
    }
  `,
};

CondensedTablePrevSalaryCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURR_SALARY
);
CondensedTablePrevSalaryCell2.id = ColumnIds.CURR_SALARY;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTablePrevSalaryCell2,
  Header: CondensedTablePrevSalaryCell2.Header,
  id: CondensedTablePrevSalaryCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTablePrevSalaryCell2.column = column;

CondensedTablePrevSalaryCell2.ordering = ({
  defaultCurrency,
  currencies,
}: {
  defaultCurrency: Currency;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const salary =
      e.subject.activeCashCompensation?.find(
        (acc) => acc.type === CashCompType.SALARY
      )?.annualCashEquivalent ?? zero(defaultCurrency.code);

    const exchangedSalary = getExchangedSalary(
      defaultCurrency,
      { salary },
      currencies
    );

    return exchangedSalary ? exchangedSalary.value : 0;
  });
