import { TreeNode } from "../../models/JobStructure";
import { TreeSelect } from "./TreeSelect";

interface JobStructureSelectProps {
  selectionTree: TreeNode;
  onChange(selectionTree: TreeNode): unknown;
}
export function JobStructureSelect({
  selectionTree,
  onChange,
}: JobStructureSelectProps): JSX.Element {
  return (
    <TreeSelect
      defaultExpand={true}
      node={selectionTree}
      indentation={[]}
      onChange={onChange}
    />
  );
}
