import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { getSalaryCashComp } from "src/models/CashCompensation";
import {
  CashRows_compCycle,
  CashRows_employee,
  CashRows_position,
  CashRows_promotedPosition,
  PayPeriodType,
  PositionType,
  RecItemInput,
  RecItemType,
} from "../../../__generated__/graphql";
import { BudgetType, CompSubComponentDisplay } from "../../../models/Budget";
import { CompRecommendation } from "../../../models/CompRecommendation";
import { CompCycleLineItemRowButton } from "./CompCycleLineItemRowButton";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";
import { NewSalaryRow } from "./LineItemRows/NewSalary";
import { PositionRow } from "./LineItemRows/Position";
import { SalaryIncreaseRow } from "./LineItemRows/SalaryIncrease";

type Props = {
  show: boolean;
  employee: CashRows_employee;
  positions: CashRows_position[];
  promotedPosition: CashRows_promotedPosition | null;
  compCycle: CashRows_compCycle;
  submittedRecommendation: CompRecommendation | null;
  revisedRecommendation: CompRecommendation | null;
  guidance?: number;
  handleNewRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};
export function CashRows({
  show,
  employee,
  positions,
  promotedPosition,
  compCycle,
  submittedRecommendation,
  revisedRecommendation,
  handleNewRecommendationItem,
  guidance,
}: Props): JSX.Element {
  const salary = getSalaryCashComp(employee.activeCashCompensation);
  const activeEmploymentSalaryCurrency = salary?.annualCashEquivalent
    .currency as CurrencyCode | null;

  const basePay =
    promotedPosition?.type === PositionType.HOURLY
      ? salary?.hourlyCashEquivalent
      : employee.activeEmployment?.position?.type === PositionType.HOURLY
        ? salary?.hourlyCashEquivalent
        : salary?.annualCashEquivalent;

  return (
    <>
      <HeaderSeparatorRow text={BudgetType.SALARY} filtered={!show} first />
      {show && (
        <>
          {compCycle.allowSalaryPromotion && (
            <PositionRow
              employee={employee}
              positions={positions}
              promotedPosition={promotedPosition}
              basePay={basePay ?? null}
              payCurrencyCode={activeEmploymentSalaryCurrency}
              revisedRecommendation={revisedRecommendation}
              submittedRecommendation={submittedRecommendation}
              onChangeRecommendationItem={handleNewRecommendationItem}
            />
          )}
          {compCycle.allowSalaryMarket && (
            <SalaryIncreaseRow
              employee={employee}
              subComponent={"salaryMarket"}
              label={CompSubComponentDisplay.salaryMarket}
              baseSalary={basePay ?? null}
              payCurrencyCode={activeEmploymentSalaryCurrency}
              revisedRecommendation={revisedRecommendation}
              submittedRecommendation={submittedRecommendation}
              recommendationType={RecItemType.MARKET}
              onChangeRecommendationItem={handleNewRecommendationItem}
              isHourly={
                (promotedPosition &&
                  promotedPosition.type === PositionType.HOURLY) ||
                employee.activeEmployment?.payPeriod === PayPeriodType.HOURLY
              }
            />
          )}
          {compCycle.allowSalaryMerit && (
            <SalaryIncreaseRow
              employee={employee}
              subComponent={"salaryMerit"}
              label={CompSubComponentDisplay.salaryMerit}
              baseSalary={basePay ?? null}
              payCurrencyCode={activeEmploymentSalaryCurrency}
              revisedRecommendation={revisedRecommendation}
              submittedRecommendation={submittedRecommendation}
              recommendationType={RecItemType.MERIT_INCREASE}
              onChangeRecommendationItem={handleNewRecommendationItem}
              guidance={guidance}
              isHourly={
                (promotedPosition &&
                  promotedPosition.type === PositionType.HOURLY) ||
                employee.activeEmployment?.payPeriod === PayPeriodType.HOURLY
              }
            />
          )}
          <NewSalaryRow
            employee={employee}
            revisedRecommendation={revisedRecommendation}
            promotedPosition={promotedPosition}
          />
        </>
      )}
    </>
  );
}

CashRows.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    ${NewSalaryRow.fragments.employee}
    fragment CashRows_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      ...NewSalaryRow_employee
      id
      activeEmployment {
        id
        salary
        payPeriod
        payRate
        payCurrencyCode
      }
      activeCashCompensation(currencyCode: $currencyCode) {
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
      }
    }
  `,
  position: gql`
    ${PositionRow.fragments.position}
    fragment CashRows_position on Position {
      id
      ...PositionRow_position
    }
  `,
  promotedPosition: gql`
    ${NewSalaryRow.fragments.position}
    fragment CashRows_promotedPosition on Position {
      id
      ...NewSalaryRow_position
    }
  `,
  compCycle: gql`
    fragment CashRows_compCycle on CompCycle2 {
      id
      allowSalary
      allowSalaryMerit
      allowSalaryMarket
      allowSalaryPromotion
      allowBonus
    }
  `,
};
