import { gql } from "@apollo/client";
import { EquityRefreshCondensedSlider } from "src/components/CompSlider/Equity/EquityRefreshCondensedSlider";
import {
  EquityRows_employee,
  EquityRows_valuation,
  RecItemInput,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { CompRecommendation } from "../../../models/CompRecommendation";
import { CompCycleLineItemRowButton } from "./CompCycleLineItemRowButton";
import { EquityGrantRow } from "./LineItemRows/EquityGrant";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";

type Props = {
  show: boolean;
  employee: EquityRows_employee;
  currentValuation: EquityRows_valuation;
  submittedRecommendation: CompRecommendation | null;
  revisedRecommendation: CompRecommendation | null;
  handleNewRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};

export function EquityRows({
  show,
  employee,
  currentValuation,
  submittedRecommendation,
  revisedRecommendation,
  handleNewRecommendationItem,
}: Props): JSX.Element {
  return (
    <>
      <HeaderSeparatorRow text={BudgetType.EQUITY} filtered={!show} />
      {show && (
        <EquityGrantRow
          employee={employee}
          submittedRecommendation={submittedRecommendation}
          revisedRecommendation={revisedRecommendation}
          currency={employee.activeEmployment?.salary?.currency ?? null}
          valuation={currentValuation}
          onChangeRecommendationItem={handleNewRecommendationItem}
        />
      )}
    </>
  );
}

EquityRows.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    ${EquityRefreshCondensedSlider.fragments.equityBand}
    ${EquityRefreshCondensedSlider.fragments.position}
    fragment EquityRows_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      id
      activeEmployment {
        id
        salary
        position {
          id
          ...EquityRefreshCondensedSlider_position
        }
      }
      adjustedEquityBands {
        id
        ...EquityRefreshCondensedSlider_equityBand
      }
    }
  `,
  valuation: gql`
    ${EquityGrantRow.fragments.valuation}
    fragment EquityRows_valuation on Valuation {
      ...EquityGrantRow_valuation
      id
    }
  `,
};
