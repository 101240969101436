import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableNewSalaryCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { getNewSalary } from "../../../../models/Employee";
import { getExchangedSalary } from "../../../../models/Employment";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableNewSalaryCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();
  const newSalary = getNewSalary(
    {
      activeCashCompensation: employee.subject.activeCashCompensation,
      compRecommendation: employee.compRecommendation,
    },
    currencies,
    defaultCurrency
  );

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        newSalary ??
          zero(
            employee.subject.activeEmployment?.salary?.currency ??
              defaultCurrency.code
          )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewSalaryCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewSalaryCell2_participant on CompCycleParticipant {
      subject {
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            value {
              ... on CashValue {
                annualRate
                currencyCode
              }
            }
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedSalaryIncrease(currencyCode: $currencyCode)
      }
    }
  `,
};

CondensedTableNewSalaryCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_SALARY
);
CondensedTableNewSalaryCell2.id = ColumnIds.NEW_SALARY;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewSalaryCell2,
  Header: CondensedTableNewSalaryCell2.Header,
  id: CondensedTableNewSalaryCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableNewSalaryCell2.column = column;
CondensedTableNewSalaryCell2.ordering = ({
  currencies,
  defaultCurrency,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrency: Currency;
}) =>
  contramap((employee: Participant) => {
    const newSalary = getNewSalary(
      {
        activeCashCompensation: employee.subject.activeCashCompensation,
        compRecommendation: employee.compRecommendation,
      },
      currencies,
      defaultCurrency
    );
    return newSalary
      ? getExchangedSalary(defaultCurrency, { salary: newSalary }, currencies)
          ?.value ?? 0
      : 0;
  });
