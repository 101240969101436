import { gql, useLazyQuery } from "@apollo/client";
import { AuthError, AuthErrorMessage } from "@asmbl/shared/auth";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "@firebase/auth";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import assemblePlatformA from "../../assets/images/assemble-platform-a.png";
import headerLogo from "../../assets/svgs/header-logo.svg";
import { AssembleTypography } from "../../components/AssembleTypography";
import { useAuth } from "../../components/Auth/AuthContext";
import { isEnabledWithUser } from "../../components/FeatureContext";
import { authInstance } from "../../firebase";
import { noop } from "../../test-helpers";
import { GRAY_2 } from "../../theme";
import { ValidateTokenAndEmail } from "../../__generated__/graphql";
import { SignInWithGoogleButton } from "./SignInWithGoogleButton";

export enum AuthenticationFormState {
  SignIn,
  Register,
  ForgotPassword,
  InvalidToken,
}

type FormOption = {
  headerText: string;
  subheaderText?: string;
  submitText?: string;
  submit(): unknown;
  left?: {
    link: string;
    text: string;
  };
  right?: {
    link: string;
    text: string;
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
    width: "460px",
  },
  greeting: {
    fontSize: "2rem",
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  subtext: {
    fontWeight: 450,
    color: GRAY_2,
    textAlign: "center",
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  optionDivider: {
    padding: theme.spacing(0, 2),
  },
  fullWidth: {
    display: "flex",
    width: "100vw",
  },
  left: {
    position: "relative",
    width: "50vw",
  },
  content: {
    left: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    maxWidth: "540px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
  },
  paperContent: {
    border: "1px solid #DAEAF9",
    boxShadow: "0 15px 45px -15px rgba(10,36,64,.15)",
  },
  right: {
    borderLeft: "1px solid #DAEAF9",
    display: "flex",
    height: "100vh",
    width: "50vw",
  },
  canduBarContainer: {
    backgroundImage: `url(${assemblePlatformA}), linear-gradient(150deg, #10236F 20.08%, #12163A 83.84%)`,
    backgroundPosition: "right bottom",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
  },
  canduBar: {
    left: "50%",
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

/**
 * Transform some default firebase errors into more user-friendly messages.
 *
 * Note: It's important that we return the same string for all login errors.
 * Otherwise, attackers could use this to determine what accounts we have or
 * don't have.
 */
export function formatError(e: unknown): string {
  const error = e as { code: string; message: string };

  const loginErrors = [
    "auth/invalid-email",
    "auth/user-not-found",
    "auth/wrong-password",
  ];

  return loginErrors.includes(error.code)
    ? "Please check that you have entered valid user information."
    : error.message;
}

type Props = {
  formState: AuthenticationFormState;
  token: string | null;
  userInvitation: {
    email: string;
    name: string;
  } | null;
};

export function Authentication({
  formState,
  token,
  userInvitation,
}: Props): JSX.Element {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { update: updateIntercom } = useIntercom();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(userInvitation?.name ?? "");
  const [email, setEmail] = useState(userInvitation?.email ?? "");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);

  const [validateTokenAndEmail] = useLazyQuery<ValidateTokenAndEmail>(
    Authentication.query.validateTokenAndEmail
  );

  // If a user's org restricts username/password sign-in, don't let them sign
  // in, sign up, or reset a password.
  const exclusivelyUseSso = async () => {
    const ssoOnly = await isEnabledWithUser(FeatureFlag.SsoOnly, email);

    if (!ssoOnly) return false;

    displaySsoOnlyToast(enqueueSnackbar);
    return true;
  };

  const handleSignIn = async () => {
    if (await exclusivelyUseSso()) return;

    try {
      await signInWithEmailAndPassword(authInstance, email, password);
    } catch (e) {
      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  const validateTokenAndEmailHandler = async (): Promise<boolean> => {
    const response = await validateTokenAndEmail({
      variables: { token, email },
    }).then((response) => response.data);

    const result = Boolean(response?.validateTokenAndEmail);

    setIsValid(result);

    return result;
  };

  const handleRegister = async () => {
    if (await exclusivelyUseSso()) return;

    try {
      const result = await validateTokenAndEmailHandler();

      if (!result) {
        return;
      }

      const response = await createUserWithEmailAndPassword(
        authInstance,
        email,
        password
      );

      await updateProfile(response.user, { displayName: name });

      await sendEmailVerification(response.user);
      updateUserAnalytics(name);
    } catch (e) {
      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  // Note: It's important that we return the same string for all password reset
  // errors. Otherwise, attackers could use this to determine what accounts we
  // have or don't have.
  const handleResetPassword = async () => {
    if (await exclusivelyUseSso()) return;

    const message =
      "If we find that email address in our system, \n" +
      "we'll send you a password reset email!";
    try {
      await sendPasswordResetEmail(authInstance, email);
      return enqueueSnackbar(message, {
        variant: "info",
        style: { whiteSpace: "pre-line" },
        autoHideDuration: 8_000,
      });
    } catch {
      return enqueueSnackbar(message, {
        variant: "info",
        style: { whiteSpace: "pre-line" },
        autoHideDuration: 8_000,
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    await formOptions.get(formState)?.submit();
  };

  const updateUserAnalytics = (name: string) => {
    window.analytics.identify({ name });
    updateIntercom({ name });
  };

  const formOptions = new Map<AuthenticationFormState, FormOption>([
    [
      AuthenticationFormState.SignIn,
      {
        headerText: "Hello, please sign in.",
        submitText: "Sign In",
        submit: handleSignIn,
        left: {
          link: "/authentication/forgot-password",
          text: "Forgot Password?",
        },
        right: {
          link: "/authentication/sso",
          text: "Sign in with SSO",
        },
      },
    ],
    [
      AuthenticationFormState.Register,
      {
        headerText: "Welcome, please create your new account.",
        submitText: "Sign Up",
        submit: handleRegister,
        left: {
          link: "/authentication/sign-in",
          text: "Already have an account? Sign in.",
        },
        right: undefined,
      },
    ],
    [
      AuthenticationFormState.ForgotPassword,
      {
        headerText: "Reset your password.",
        submitText: "Reset Password",
        submit: handleResetPassword,
        left: {
          link: "/authentication/sign-in",
          text: "Sign in",
        },
        right: undefined,
      },
    ],
    [
      AuthenticationFormState.InvalidToken,
      {
        headerText: "This invite is no longer valid",
        subheaderText:
          "This sign up link has already been used, expired, or revoked. Please ask the sender for a new invitation.",
        submit: noop,
        left: {
          link: "/authentication/sign-in",
          text: "Already have an account? Sign in.",
        },
        right: undefined,
      },
    ],
  ]);

  const formOption = formOptions.get(formState);

  if (user) return <Navigate to="/" replace />;
  if (isLoading) setIsLoading(false);

  return (
    <main className={classes.fullWidth}>
      <div className={classes.left}>
        <div className={classes.content}>
          <Grid className={classes.logo} container>
            <img src={headerLogo} alt="Assemble" width="40%" />
          </Grid>
          <Box mx={6} p={4} component={Paper} className={classes.paperContent}>
            <Container component="main" maxWidth="xs">
              <div className={classes.paper}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.greeting}
                >
                  {formOption?.headerText}
                </Typography>
                <AssembleTypography
                  variant="productParagraphLarge"
                  className={classes.subtext}
                >
                  {formOption?.subheaderText}
                </AssembleTypography>
                {formState !== AuthenticationFormState.InvalidToken && (
                  <SignInWithGoogleButton
                    onClick={async () => {
                      await signInWithPopup(
                        authInstance,
                        new GoogleAuthProvider()
                      );
                    }}
                  />
                )}
                {formState !== AuthenticationFormState.InvalidToken && (
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography
                        align="center"
                        color="textSecondary"
                        className={classes.optionDivider}
                      >
                        or sign in with your email
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                  </Grid>
                )}
                <form className={classes.form} onSubmit={handleSubmit}>
                  {formState === AuthenticationFormState.Register && (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Full name"
                      name="name"
                      autoComplete="name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  )}
                  {formState !== AuthenticationFormState.InvalidToken && (
                    <TextField
                      disabled={userInvitation?.email != null}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Work email address"
                      name="email"
                      autoComplete="email"
                      value={email}
                      error={!isValid}
                      helperText={
                        !isValid
                          ? "Your organization requires you to be invited to join Assemble. Please check your email or ask your Assemble admin for an invite."
                          : undefined
                      }
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  )}
                  {formState !== AuthenticationFormState.ForgotPassword &&
                    formState !== AuthenticationFormState.InvalidToken && (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    )}

                  {formState !== AuthenticationFormState.InvalidToken && (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      disabled={isLoading}
                    >
                      {formOption?.submitText}
                    </Button>
                  )}
                </form>
              </div>
            </Container>
          </Box>
          <Box mx={6} py={2}>
            <Grid container>
              <Grid item xs>
                {formOption?.left && (
                  <Link variant="body2" href={formOption.left.link}>
                    {formOption.left.text}
                  </Link>
                )}
              </Grid>
              <Grid item>
                {formOption?.right && (
                  <Link variant="body2" href={formOption.right.link}>
                    {formOption.right.text}
                  </Link>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.canduBarContainer}>
          <div id="canduBar" className={classes.canduBar}></div>
        </div>
      </div>
    </main>
  );
}

Authentication.fragments = {
  userInvitation: gql`
    fragment Authentication_userInvitation on UserInvitation {
      id
      email
      name
    }
  `,
};

Authentication.query = {
  validateTokenAndEmail: gql`
    query ValidateTokenAndEmail($email: String!, $token: String) {
      validateTokenAndEmail(email: $email, token: $token)
    }
  `,
};

export function displaySsoOnlyToast(
  enqueueSnackbar: SnackbarProvider["enqueueSnackbar"]
) {
  enqueueSnackbar(AuthErrorMessage[AuthError.SSO_ONLY], {
    variant: "info",
    preventDuplicate: true,
    action: (
      <Button
        variant="contained"
        component={RouterLink}
        to="/authentication/sso"
      >
        Sign in with SSO
      </Button>
    ),
  });
}
