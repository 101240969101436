import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTableSalaryMeritDifferenceCell2_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
} from "src/__generated__/graphql";
import { getGuidance2 } from "src/models/MeritGuidance";
import { RED } from "src/theme";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritDifferenceCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const tableData = useTableData();
  const guidance = getGuidance2(
    {
      ...employee.subject,
      perfRating: employee.perfRating,
    },
    tableData.meritGuidance.meritAdjustmentGuides,
    tableData.meritGuidance.meritAdjustmentRanges,
    tableData.meritGuidance.perfRatingOptions
  );

  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const percent = calculatePercent(
    employee.subject.activeEmployment?.salary,
    getMeritSalaryIncrease(
      recItems,
      employee.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
    ).value
  );

  const difference = guidance != null ? percent - guidance * 100 : null;
  return (
    <div>
      {guidance != null && difference !== null ? (
        <AssembleTruncatedTypography
          textColor={difference === 0 ? "inherit" : RED}
          align="right"
        >
          {difference >= 0 ? "+" : ""}
          {formatNumeral(difference / 100, {
            style: "percent",
            maximumFractionDigits: 2,
          })}
        </AssembleTruncatedTypography>
      ) : (
        <AssembleTruncatedTypography align="right">
          -
        </AssembleTruncatedTypography>
      )}
    </div>
  );
}

CondensedTableSalaryMeritDifferenceCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritDifferenceCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                currencyCode
              }
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
      perfRating
    }
  `,
};

CondensedTableSalaryMeritDifferenceCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF
);
CondensedTableSalaryMeritDifferenceCell2.id =
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritDifferenceCell2,
  Header: CondensedTableSalaryMeritDifferenceCell2.Header,
  id: CondensedTableSalaryMeritDifferenceCell2.id,
};

CondensedTableSalaryMeritDifferenceCell2.column = column;
CondensedTableSalaryMeritDifferenceCell2.ordering = ({
  meritGuidance,
}: {
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
}) =>
  contramap((e: Participant) => {
    const guidance = getGuidance2(
      {
        ...e.subject,
        perfRating: e.perfRating,
      },
      meritGuidance.meritAdjustmentGuides,
      meritGuidance.meritAdjustmentRanges,
      meritGuidance.perfRatingOptions
    );

    const { defaultCurrencyCode } = useCurrencies();
    const recItems = e.compRecommendation?.latestSubmittedItems ?? [];
    const percent = calculatePercent(
      e.subject.activeEmployment?.salary,
      getMeritSalaryIncrease(
        recItems,
        e.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
      ).value
    );

    const difference = guidance != null ? percent - guidance * 100 : null;
    return difference ?? 0;
  });

function calculatePercent(
  baseSalary?: Money | null,
  absolute?: number
): number {
  if (baseSalary == null || absolute == null) return 0;
  return baseSalary.value !== 0
    ? Math.round((absolute / baseSalary.value) * 10000) / 100
    : 0;
}
