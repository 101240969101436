import { gql } from "@apollo/client";
import { AppBar, Button, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import {
  CycleBudgetAppBar2_compCycle,
  CycleBudgetAppBar2_compCycleBudget,
  CycleBudgetAppBar2_valuation,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { GRAY_4, GRAY_6, PURPLE_1, WHITE } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { ChevronUpIcon } from "../AssembleIcons/Brand/ChevronUpIcon";
import { CompCycleBudgetBar2 } from "./CompCycleBudgetBar/CompCycleBudgetBar2";
import { CompCycleGrouping } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    top: "auto",
    backgroundColor: "rgba(255, 0, 0, 0)",
    boxShadow: "none",
    left: theme.spacing(10.125), // account for navbar and border
    overflow: "hidden",
  },
  tabContainer: {
    display: "flex",
    overflow: "hidden",
    paddingTop: "5px",
    maxHeight: "2rem",
  },
  tab: {
    backgroundColor: WHITE,
    minWidth: "7.75rem",
    height: "2.5rem",
    boxShadow: `0px 0px 0px 1px ${GRAY_6}`,
    borderRadius: "0px 5px 0px 0px",
    zIndex: 1,
    justifySelf: "flex-start",
  },
  tabButton: {
    padding: theme.spacing(0, 0, 0, 1.5),
  },
  tabText: {
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "0.75rem",
    lineHeight: "140%",
    "&:hover": {
      color: PURPLE_1,
    },
  },
  rotatedIcon: {
    transform: "rotate(180deg)",
  },
  barContainer: {
    display: "flex",
    flexDirection: "row",
    boxShadow: `0 -5px 15px -5px rgba(10,36,64,.1)`,
  },
  rightBuffer: {
    display: "flex",
    flexDirection: "column",
    width: "17%",
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    backgroundColor: WHITE,
    overflow: "hidden",
  },
}));

type Props = {
  recommendationItems: RecItemWithSubject[];
  compCycle: CycleBudgetAppBar2_compCycle;
  budget: CycleBudgetAppBar2_compCycleBudget | null;
  valuation: CycleBudgetAppBar2_valuation;
  bottomOffset?: number;
};

export const CycleBudgetAppBar2 = ({
  recommendationItems,
  compCycle,
  budget,
  valuation,
  bottomOffset = 0,
}: Props): JSX.Element => {
  const { trackEvent } = useTrack();
  const [showBudget, setShowBudget] = useState(true);
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();

  const filter = (urlSearchParams.get("filter") ?? "all") as CompCycleGrouping;
  const managerFilter = urlSearchParams.get("manager");
  const hasManagerFilter = managerFilter !== null;

  const handleClick = () => {
    setShowBudget(!showBudget);
    trackEvent({
      object: "Budget Drawer Visibility",
      action: "Toggled",
      isVisible: !showBudget,
    });
  };
  return (
    <AppBar
      component="div"
      className={classes.root}
      style={{ bottom: bottomOffset }}
    >
      <div className={classes.tabContainer}>
        <div className={classes.tab}>
          <Button
            className={classes.tabButton}
            endIcon={
              showBudget ? (
                <ChevronDownIcon color={GRAY_4} inline />
              ) : (
                <ChevronUpIcon color={GRAY_4} inline />
              )
            }
            onClick={handleClick}
          >
            <Typography color="primary" className={classes.tabText}>
              {showBudget ? "Hide Budget" : "Show Budget"}
            </Typography>
          </Button>
        </div>
      </div>
      {showBudget && (
        <div
          className={classes.barContainer}
          data-intercom-target={"budget-bar"}
        >
          <CompCycleBudgetBar2
            prefixText={
              !hasManagerFilter
                ? " Org's"
                : managerFilter.includes(",")
                  ? "Managers'"
                  : "Manager's"
            }
            filter={filter}
            budget={budget}
            valuation={valuation}
            compCycle={compCycle}
            recommendationItems={recommendationItems}
          />
          <div className={classes.rightBuffer} />
        </div>
      )}
    </AppBar>
  );
};

CycleBudgetAppBar2.fragments = {
  compCycle: gql`
    ${CompCycleBudgetBar2.fragments.compCycle}
    fragment CycleBudgetAppBar2_compCycle on CompCycle2 {
      ...CompCycleBudgetBar2_compCycle
    }
  `,
  compCycleBudget: gql`
    ${CompCycleBudgetBar2.fragments.compCycleBudget}
    fragment CycleBudgetAppBar2_compCycleBudget on CompCycleBudget {
      ...CompCycleBudgetBar2_compCycleBudget
    }
  `,
  valuation: gql`
    ${CompCycleBudgetBar2.fragments.valuation}
    fragment CycleBudgetAppBar2_valuation on Valuation {
      ...CompCycleBudgetBar2_valuation
    }
  `,
};
