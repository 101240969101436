import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { CondensedTableSalaryMeritCell2_participant as Participant } from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getMeritSalaryIncrease(
          recItems,
          employee.subject.activeEmployment?.salary?.currency ??
            defaultCurrencyCode
        )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryMeritCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableSalaryMeritCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT
);
CondensedTableSalaryMeritCell2.id = ColumnIds.SALARY_MERIT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritCell2,
  Header: CondensedTableSalaryMeritCell2.Header,
  id: CondensedTableSalaryMeritCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableSalaryMeritCell2.column = column;
CondensedTableSalaryMeritCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const meritSalaryIncrease = getMeritSalaryIncrease(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(meritSalaryIncrease.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(meritSalaryIncrease, currentCurrency, defaultCurrency)
      .value;
  });
