import { gql, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { useState } from "react";
import {
  CondensedTablePeopleLoadingBoundaryQuery,
  CondensedTablePeopleLoadingBoundaryQueryVariables,
  GetParticipantsInput,
} from "src/__generated__/graphql";
import { useAuth } from "src/components/Auth/AuthContext";
import { ErrorView } from "src/components/ErrorView";
import { useFeatureFlags } from "src/components/FeatureContext";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { CondensedTablePeople2 } from "./CondensedTablePeople2";
import { usePagination } from "./Contexts/PaginationContext";

type Props = {
  compCycleId: number;
  filter: GetParticipantsInput;
  employeeIds: number[];
  isAdmin: boolean;
};

export function CondensedTablePeopleLoadingBoundary({
  compCycleId,
  filter,
  employeeIds,
  isAdmin,
}: Props): JSX.Element {
  const { permissions } = useAuth();
  const [selectedCurrency, setSelectedCurrency] =
    useState<CurrencyCodeWithAll>("all");
  const [nameSearch, setNameSearch] = useState<string | null>();
  const { pageSize, currentPage, setTotalCount, sortBy, sortDir } =
    usePagination();
  const { isEnabled } = useFeatureFlags();
  const urlSearchParams = useURLSearchParams();
  const managerId = urlSearchParams.get("manager");
  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;

  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;

  const shouldPaginate = isEnabled(FeatureFlag.ClientPlanTable030124);
  const queryVariables = {
    compCycleId,
    filter: { ...filter, displayName: nameSearch },
    currencyCode,
    employeeIds,
    limit: shouldPaginate ? pageSize : undefined,
    offset: shouldPaginate ? currentPage * pageSize : undefined,
    isAdmin,
    skipEligibility: true,
    sortBy: shouldPaginate ? sortBy : undefined,
    sortDir: shouldPaginate ? sortDir : undefined,
    actingManagerEmployeeId,
  };

  const { data, loading } = useQuery<
    CondensedTablePeopleLoadingBoundaryQuery,
    CondensedTablePeopleLoadingBoundaryQueryVariables
  >(CondensedTablePeopleLoadingBoundary.query, {
    variables: queryVariables,
    onCompleted: (data: CondensedTablePeopleLoadingBoundaryQuery) => {
      if (data.compCycle2?.participants.count != null) {
        setTotalCount(data.compCycle2.participants.count);
      }
    },
  });

  if (
    !data ||
    data.compCycle2 == null ||
    data.valuation === null ||
    data.whoami?.user == null
  ) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="Reach out to your comp admin for assistance." />
    );
  }

  return (
    <CondensedTablePeople2
      compCycle={data.compCycle2}
      filter={filter}
      availablePositions={data.positions}
      selectedCurrency={selectedCurrency}
      onChangeSelectedCurrency={setSelectedCurrency}
      budget={data.compCycleBudgets}
      valuation={data.valuation}
      user={data.whoami.user}
      setNameSearch={setNameSearch}
      nameSearch={nameSearch}
      isAdmin={isAdmin}
      meritGuidance={{
        meritAdjustmentGuides: data.compCycle2.meritAdjustmentGuides ?? [],
        meritAdjustmentRanges: data.meritAdjustmentRanges,
        perfRatingOptions: data.perfRatingOptions,
      }}
    />
  );
}

const MERIT_ADJUSTMENT_GUIDE = gql`
  fragment CondensedTable_meritAdjustmentGuide on MeritAdjustmentGuide {
    id
    perfRatingOptionId
    meritAdjustmentRangeId
    percent
    compCycleId
    organizationId
  }
`;

const PERF_RATING_OPTION = gql`
  fragment CondensedTable_perfRatingOption on PerfRatingOption {
    id
    name
    compCycleId
    organizationId
    rank
  }
`;

const MERIT_ADJUSTMENT_RANGE = gql`
  fragment CondensedTable_meritAdjustmentRange on MeritAdjustmentRange {
    id
    rangeStart
    compCycleId
    organizationId
  }
`;

CondensedTablePeopleLoadingBoundary.query = gql`
  ${CondensedTablePeople2.fragments.compCycle}
  ${CondensedTablePeople2.fragments.compCycleBudget}
  ${CondensedTablePeople2.fragments.participant}
  ${CondensedTablePeople2.fragments.position}
  ${CondensedTablePeople2.fragments.valuation}
  ${CondensedTablePeople2.fragments.user}
  ${MERIT_ADJUSTMENT_GUIDE}
  ${MERIT_ADJUSTMENT_RANGE}
  ${PERF_RATING_OPTION}
  query CondensedTablePeopleLoadingBoundaryQuery(
    $compCycleId: Int!
    $filter: GetParticipantsInput
    $currencyCode: CurrencyCode
    $employeeIds: [Int!]!
    $limit: Int
    $offset: Int
    $sortBy: String
    $sortDir: String
    $isAdmin: Boolean!
    $skipEligibility: Boolean
    $actingManagerEmployeeId: Int
  ) {
    compCycle2(id: $compCycleId) {
      id
      name
      closeDate
      ...CondensedTablePeople2_compCycle

      participants(
        input: $filter
        limit: $limit
        offset: $offset
        sortBy: $sortBy
        sortDir: $sortDir
      ) {
        count
        participants {
          compCycleId
          subjectId
          ...CondensedTablePeople2_participant
        }
      }

      meritAdjustmentGuides {
        id
        ...CondensedTable_meritAdjustmentGuide
      }
    }
    positions {
      id
      ...CondensedTablePeople2_position
    }
    valuation {
      id
      ...CondensedTablePeople2_valuation
    }
    compCycleBudgets(
      compCycleId: $compCycleId
      employeeIds: $employeeIds
      currencyCode: $currencyCode
    ) {
      ...CondensedTablePeople2_compCycleBudget
    }
    whoami {
      user {
        id
        ...CondensedTablePeople2_user
      }
    }
    perfRatingOptions(compCycleId: $compCycleId) {
      id
      ...CondensedTable_perfRatingOption
    }
    meritAdjustmentRanges(compCycleId: $compCycleId) {
      id
      ...CondensedTable_meritAdjustmentRange
    }
  }
`;
