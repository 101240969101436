import { gql } from "@apollo/client";
import { pluckOptionsBy } from "@asmbl/shared/filter";
import { distinct, mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { AssembleTypography } from "src/components/AssembleTypography";
import { BehindScheduleBanner } from "src/components/CompCycle/Banners/BehindScheduleBanner/BehindScheduleBanner";
import { AssembleLink } from "../../../components/AssembleLink";
import { useAuth } from "../../../components/Auth/AuthContext";
import { CompleteCompCycleButton } from "../../../components/CompCycle/Buttons/CompleteCompCycleButton";
import {
  CompleteReviewDialog,
  useCompleteReviewDialogState,
} from "../../../components/CompCycle/Dialogs/CompleteReviewDialog";
import { CompleteReviewDialogOpen } from "../../../components/CompCycle/Dialogs/CompleteReviewDialogOpen";
import { CompCycleCsvExportWrapper } from "../../../components/CsvExport/CompCycleExportWrapper";
import { ExplanatoryTooltip } from "../../../components/ExplanatoryTooltip";
import { FilterSelect } from "../../../components/Filter/FilterSelect";
import { FilterParam, getNumericParam } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { GRAY_6, WHITE } from "../../../theme";
import {
  CompCycleReviewRequests_compCycle,
  Noun,
  ReviewRequestStatus,
} from "../../../__generated__/graphql";
import { PhaseProgressBar } from "../Plan/PhaseProgressBar";
import { ApproverCard } from "./ApproverCard";
import { CompCycleManager } from "./CompCycleHomeBoundary";
import { LateTag } from "./LateTag";
import { checkForLateRecs, sortAuthors, UNGROUPED_KEY } from "./util";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "80px",
    background: WHITE,
    padding: theme.spacing(2, 4, 2, 4),
    borderBottom: `1px solid ${GRAY_6}`,
  },
  filterBar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  leftSideFilterBar: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  rightSideFilterBar: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  managerCardWrapper: {
    marginBottom: theme.spacing(2),
  },
  managerCardLink: {
    display: "block",
    borderRadius: "5px",
    overflow: "hidden",
    "&:active": {
      boxShadow: "0px 0px 0px 3px rgba(100, 91, 255, 0.3)",
    },
  },
  filters: {
    padding: theme.spacing(3, 2),
  },
  phaseHeader: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export type CompCycleManagerWithStatus = CompCycleManager & {
  status: ReviewRequestStatus;
};

type Props = {
  authors: CompCycleManager[];
  compCycle: CompCycleReviewRequests_compCycle;
  onComplete: () => Promise<boolean>;
};

export function CompCycleReviewRequests({
  authors,
  compCycle,
  onComplete,
}: Props): JSX.Element {
  const classes = useStyles();
  const { permissions } = useAuth();
  const [dialogState, setDialogState] = useCompleteReviewDialogState(
    compCycle.endedAt
  );
  const canCompleteCompCycle = permissions.canEditGlobal(Noun.CompCycle);

  const urlSearchParams = useURLSearchParams();
  const filterableAuthors = authors.map(addReviewStatus);

  const locationParam = getNumericParam(urlSearchParams, FilterParam.LOCATION);
  const managerParam = getNumericParam(urlSearchParams, FilterParam.MANAGER);

  const filteredAuthors = filterableAuthors
    .filter((a) => locationParam === "all" || a.location?.id === locationParam)
    .filter((a) => managerParam === "all" || a.id === managerParam);

  const sortedAuthors: { [key: string]: CompCycleManagerWithStatus[] } =
    canCompleteCompCycle && compCycle.phases.length > 0
      ? sortAuthors(filteredAuthors, compCycle)
      : { [UNGROUPED_KEY]: filteredAuthors };

  const allCompRecs = mapMaybe(
    authors.flatMap((a) => a.reports),
    (r) => r.compRecommendation
  );

  const uniqueRecs = distinct(allCompRecs, "subjectId");

  const awaitingReviewCount = uniqueRecs.filter(
    (r) => r.reviewStatus === ReviewRequestStatus.AWAITING_REVIEW
  ).length;

  const currentPhaseOrder = compCycle.currentPhase?.phaseOrder;
  const previousPhaseIds =
    currentPhaseOrder != null
      ? compCycle.phases
          .filter((phase) => phase.phaseOrder < currentPhaseOrder)
          .map((phase) => phase.id)
      : [];

  return (
    <>
      {dialogState !== "open" && (
        <CompleteReviewDialog
          compCycle={compCycle}
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      )}
      {dialogState === "open" && (
        <CompleteReviewDialogOpen
          compCycleId={compCycle.id}
          setDialogState={setDialogState}
          onSubmit={onComplete}
          reviewCount={awaitingReviewCount}
        />
      )}
      <PhaseProgressBar compCycle={compCycle} />
      <BehindScheduleBanner
        compCycleId={compCycle.id}
        managers={sortedAuthors}
        previousPhaseIds={previousPhaseIds}
      />
      <div className={classes.filters}>
        <div className={classes.filterBar}>
          <div className={classes.leftSideFilterBar}>
            <FilterSelect
              label="Managers"
              param={FilterParam.MANAGER}
              options={[
                { label: "All Managers", value: "all" },
                ...pluckOptionsBy(
                  filterableAuthors,
                  (author) => author.id,
                  (author) => author.displayName
                ),
              ]}
            />
            <FilterSelect
              label="Locations"
              param={FilterParam.LOCATION}
              options={[
                { label: "All Locations", value: "all" },
                ...pluckOptionsBy(
                  filterableAuthors,
                  (author) => author.location?.id,
                  (author) => author.location?.name
                ),
              ]}
            />
          </div>
          <div className={classes.rightSideFilterBar}>
            <ExplanatoryTooltip
              title="Export your comp changes"
              body={
                <p>
                  Export every change that has been <b>approved</b> by your comp
                  admin or is <b>awaiting approval</b>.
                </p>
              }
            >
              <span>
                <CompCycleCsvExportWrapper
                  compCycleId={compCycle.id}
                  compCycleName={compCycle.name}
                  variant="button"
                />
              </span>
            </ExplanatoryTooltip>
            {canCompleteCompCycle && (
              <CompleteCompCycleButton
                compCycle={compCycle}
                setDialogState={setDialogState}
              />
            )}
          </div>
        </div>
        {Object.keys(sortedAuthors).map((authorKey) => {
          const phase = compCycle.phases.find(
            (phase) => String(phase.id) === authorKey
          );
          const filteredPhaseAuthors = sortedAuthors[authorKey];
          const isPreviousPhase = !!(
            currentPhaseOrder != null &&
            phase &&
            currentPhaseOrder > phase.phaseOrder
          );

          const phaseIncludesLateManagers =
            isPreviousPhase && checkForLateRecs(filteredPhaseAuthors);

          return (
            <div key={authorKey}>
              {authorKey !== UNGROUPED_KEY && phase ? (
                <AssembleTypography
                  variant="productSummaryHeader"
                  className={classes.phaseHeader}
                >
                  Phase {phase.phaseOrder}{" "}
                  {currentPhaseOrder === phase.phaseOrder ? " (Current)" : null}
                  {phaseIncludesLateManagers ? <LateTag /> : null}
                </AssembleTypography>
              ) : null}
              {filteredPhaseAuthors.map((author) =>
                compCycle.endedAt === null ? (
                  <AssembleLink
                    to={`/comp-cycles/${compCycle.id}/requests?manager=${author.id}`}
                    color="textSecondary"
                    underline="none"
                    key={author.id}
                    className={clsx(
                      classes.managerCardWrapper,
                      classes.managerCardLink
                    )}
                  >
                    <ApproverCard
                      manager={author}
                      isActiveCompCycle={true}
                      isPreviousPhase={isPreviousPhase}
                    />
                  </AssembleLink>
                ) : (
                  <div className={classes.managerCardWrapper} key={author.id}>
                    <ApproverCard manager={author} isActiveCompCycle={false} />
                  </div>
                )
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

function addReviewStatus(author: CompCycleManager): CompCycleManagerWithStatus {
  const reports = author.reports;
  if (
    reports.every(
      (r) => r.compRecommendation?.reviewStatus === ReviewRequestStatus.APPROVED
    )
  ) {
    return { ...author, status: ReviewRequestStatus.APPROVED };
  }
  if (
    reports.some(
      (r) => r.compRecommendation?.reviewStatus === ReviewRequestStatus.DENIED
    )
  ) {
    return { ...author, status: ReviewRequestStatus.DENIED };
  }
  if (
    reports.some(
      (r) =>
        r.compRecommendation?.reviewStatus ===
        ReviewRequestStatus.AWAITING_REVIEW
    )
  ) {
    return { ...author, status: ReviewRequestStatus.AWAITING_REVIEW };
  }
  return { ...author, status: ReviewRequestStatus.AWAITING_DEPENDENCY };
}

CompCycleReviewRequests.fragments = {
  compCycle: gql`
    ${PhaseProgressBar.fragments.compCycle}
    fragment CompCycleReviewRequests_compCycle on CompCycle {
      id
      name
      endedAt
      ...PhaseProgressBar_compCycle
    }
  `,
  manager: gql`
    ${ApproverCard.fragments.manager}
    fragment CompCycleReviewRequests_employee on Employee {
      id
      location {
        id
        name
      }
      ...ApproverCard_employee
    }
  `,
};
