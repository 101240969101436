import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { Link, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { CondensedTableNameCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useURLSearchParams } from "../../../../models/URLSearchParams";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { XLG_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles((theme) => ({
  employeeNameCell: {
    display: "flex",
    alignItems: "center",
  },
  nameText: {
    paddingLeft: theme.spacing(0),
    color: "inherit",
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTableNameCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();

  return (
    <div className={classes.employeeNameCell}>
      <AssembleTruncatedTypography
        lines={2}
        title={employee.subject.displayName}
      >
        <Link
          component={RouterLink}
          className={classes.nameText}
          to={`?${urlSearchParams
            .set("employee", employee.subject.id.toString())
            .toString()}`}
          underline="none"
        >
          {employee.subject.displayName}
        </Link>
      </AssembleTruncatedTypography>
    </div>
  );
}

CondensedTableNameCell2.fragments = {
  participant: gql`
    fragment CondensedTableNameCell2_participant on CompCycleParticipant {
      subject {
        id
        displayName
        userId
        photoURL
      }
    }
  `,
};

CondensedTableNameCell2.Header = ColumnIdsToHeaders.get(ColumnIds.NAME);
CondensedTableNameCell2.id = ColumnIds.NAME;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNameCell2,
  Header: CondensedTableNameCell2.Header,
  id: CondensedTableNameCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableNameCell2.column = column;
CondensedTableNameCell2.ordering = () =>
  contramap((e: Participant) => e.subject.displayName);
