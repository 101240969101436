import { gql } from "@apollo/client";
import { Verb } from "@asmbl/shared/permissions";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useURLSearchParams } from "src/models/URLSearchParams";
import {
  Noun,
  CondensedTableActionsCell2_participant as Participant,
  PhaseTimelineStatus,
  RecReviewStatus,
} from "../../../../__generated__/graphql";
import { useAuth } from "../../../../components/Auth/AuthContext";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { ApproveButton2 } from "./Actions/ApproveButton2";
import { DenyButton2 } from "./Actions/DenyButton2";
import { EditRequestButton2 } from "./Actions/EditRequestButton2";
import { LARGE_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles((theme) => ({
  actionsCell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  iconContainer: {
    display: "flex",
    padding: theme.spacing(0.25),
  },
  tooltipContainer: {
    display: "inline-block",
  },
  tooltipSpacing: {
    margin: theme.spacing(2),
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTableActionsCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const classes = useStyles();
  const {
    compCycleId,
    isActiveCycle,
    indirectReportIds,
    currentPhase,
    compCyclePhases,
  } = useTableData();
  const { permissions, employeeId, userId } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const managerId = urlSearchParams.get("manager");
  // if sequential approvals is enabled and the cycle has phases, check if the user has global access
  const isIndirectReport =
    indirectReportIds.includes(employee.subject.id) ||
    (permissions.nounScopes &&
      permissions.nounScopes[Noun.Employee][
        Verb.View
      ]?.indirectReportIDs.includes(employee.subject.id)) === true;

  const isPhaseConfigurationPeriod =
    currentPhase == null && compCyclePhases.length > 0;
  const limitedSeqApprovalPermissions =
    compCyclePhases.length > 0 &&
    currentPhase !== null &&
    !permissions.canViewGlobal(Noun.Employee);
  const hideReviewOptions = !isIndirectReport && limitedSeqApprovalPermissions;
  const isReadOnly = !isActiveCycle;

  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;

  const userPhaseAssignment:
    | {
        status: PhaseTimelineStatus | null;
        phase: { phaseOrder: number } | null;
      }
    | undefined = employee.phaseTimeline.find(
    (assignment) =>
      assignment.assigneeId === employeeId ||
      (actingManagerEmployeeId != null &&
        assignment.assigneeId === actingManagerEmployeeId)
  );

  const isPhased = compCyclePhases.length > 0;

  // If the user is full access, they should still be able to edit even if not assigned to a phase
  const userHasPhaseAssignment = isPhased
    ? userPhaseAssignment != null ||
      permissions.canEditGlobal(Noun.CompRecommendation)
    : true;

  const reviewSubmissionTime =
    employee.compRecommendation?.latestSubmittedReviews.at(0)?.submittedAt ??
    null;
  const itemSubmissionTime =
    employee.compRecommendation?.latestSubmittedItems.at(0)?.submittedAt ??
    null;

  const reviewIsLatestActivity =
    reviewSubmissionTime !== null &&
    itemSubmissionTime !== null &&
    new Date(reviewSubmissionTime).getTime() >=
      new Date(itemSubmissionTime).getTime();

  const recIsRejectedByMe =
    employee.compRecommendation?.latestSubmittedReviews.find(
      ({ status, author: { id } }) =>
        status === RecReviewStatus.DENIED && userId !== null && id === userId
    ) !== undefined && reviewIsLatestActivity;

  const recIsApprovedByMe =
    employee.compRecommendation?.latestSubmittedReviews.find(
      ({ status, author: { id } }) =>
        status === RecReviewStatus.APPROVED && userId !== null && id === userId
    ) !== undefined && reviewIsLatestActivity;

  const recIsEditable =
    employee.compRecommendation?.canICurrentlyEdit ??
    employee.compRecommendation === null;

  const canEditRec = limitedSeqApprovalPermissions ? recIsEditable : true;

  const canReview =
    employee.compRecommendation !== null &&
    employee.compRecommendation.canICurrentlyReview;

  const isAwaiting =
    userPhaseAssignment?.status === PhaseTimelineStatus.NEEDS_REVIEW;

  const lastReviewWasApproval =
    employee.compRecommendation?.latestSubmittedReviews.at(0)?.status ===
      RecReviewStatus.APPROVED && reviewIsLatestActivity;
  const lastReviewWasDenial =
    employee.compRecommendation?.latestSubmittedReviews.at(0)?.status ===
      RecReviewStatus.DENIED && reviewIsLatestActivity;

  const reviewedByCurrentUserOrLater = (): boolean => {
    // if no phases, this should be ignored
    if (compCyclePhases.length === 0) {
      return true;
    }

    if (permissions.canEditGlobal(Noun.CompRecommendation))
      return recIsApprovedByMe;

    const currentUserPhase = employee.phaseTimeline.find(
      (phase) => phase.assigneeId === employeeId
    );

    const assigneeIdsAfterCurrentUser = employee.phaseTimeline
      .filter((phase) =>
        phase.phase && currentUserPhase?.phase
          ? phase.phase.phaseOrder >= currentUserPhase.phase.phaseOrder
          : false
      )
      .map((phase) => phase.assigneeId);

    return (
      employee.compRecommendation?.latestSubmittedReviews.some((recReview) =>
        assigneeIdsAfterCurrentUser.includes(
          recReview.author.employee?.id ?? -1
        )
      ) ?? false
    );
  };

  return (
    <div className={classes.actionsCell}>
      <DenyButton2
        employee={employee}
        compCycleId={compCycleId}
        disabled={!canReview || isReadOnly || recIsRejectedByMe}
        recIsRejected={recIsRejectedByMe || (!isPhased && lastReviewWasDenial)}
        hidden={hideReviewOptions}
        hideTooltip={isAwaiting}
      />
      <EditRequestButton2
        employee={employee}
        isIndirectReport={isIndirectReport}
        isPhaseConfigurationPeriod={isPhaseConfigurationPeriod}
        compCycleId={compCycleId}
        disabled={isReadOnly || !canEditRec}
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        disabledReason={
          !userHasPhaseAssignment
            ? "You are not assigned to a phase in this cycle."
            : "Request locked. Check activity log for details."
        }
      />
      <ApproveButton2
        employee={employee}
        compCycleId={compCycleId}
        disabled={!canReview || isReadOnly || recIsRejectedByMe}
        recIsApproved={
          reviewedByCurrentUserOrLater()
            ? recIsApprovedByMe || (!isPhased && lastReviewWasApproval)
            : !isPhased && lastReviewWasApproval
        }
        hidden={hideReviewOptions}
        hideTooltip={isAwaiting}
      />
    </div>
  );
}

CondensedTableActionsCell2.fragments = {
  participant: gql`
    ${DenyButton2.fragments.participant}
    ${ApproveButton2.fragments.participant}
    ${EditRequestButton2.fragments.participant}
    fragment CondensedTableActionsCell2_participant on CompCycleParticipant {
      ...DenyButton2_participant
      ...ApproveButton2_participant
      ...EditRequestButton2_participant
      subjectId
      compCycleId
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        canICurrentlyReview(actingManagerEmployeeId: $actingManagerEmployeeId)
        canICurrentlyEdit(actingManagerEmployeeId: $actingManagerEmployeeId)
        reviewStatus
        latestSubmittedReviews {
          id
          status
          submittedAt
          author {
            id
            employee {
              id
            }
          }
        }
        latestSubmittedItems {
          id
          submittedAt
        }
      }
      phaseTimeline {
        id
        phaseId
        status
        assigneeId
        employee @include(if: $isAdmin) {
          id
          displayName
        }
        phase {
          id
          phaseOrder
        }
      }
    }
  `,
};

CondensedTableActionsCell2.Header = ColumnIdsToHeaders.get(ColumnIds.ACTIONS);
CondensedTableActionsCell2.id = ColumnIds.ACTIONS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableActionsCell2,
  Header: CondensedTableActionsCell2.Header,
  id: CondensedTableActionsCell2.id,
  width: LARGE_COL_WIDTH,
  defaultCanSort: false,
};
CondensedTableActionsCell2.column = column;
CondensedTableActionsCell2.ordering = () => () => 0; // Can't sort this column
