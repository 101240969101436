import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  CondensedTableStatusSelect_compCycle as CompCycle,
  CompRecommendationStatus,
} from "../../../__generated__/graphql";
import ChipSelect, { Option } from "../../../components/ChipSelect";
import {
  GRAY_3,
  GRAY_4,
  STATUS_ACCEPTED,
  STATUS_ACCEPTED_HOVER,
  STATUS_AWAITING,
  STATUS_AWAITING_BORDER,
  STATUS_AWAITING_COLOR,
  STATUS_FUTURE_PHASE,
  STATUS_FUTURE_PHASE_BORDER,
  STATUS_FUTURE_PHASE_COLOR,
  STATUS_LATE,
  STATUS_LATE_HOVER,
  STATUS_NEEDS_REVIEW,
  STATUS_NEEDS_REVIEW_HOVER,
  STATUS_REJECTED,
  STATUS_REJECTED_HOVER,
  STATUS_REVIEWED,
  STATUS_REVIEWED_HOVER,
  STATUS_SKIPPED,
  STATUS_SKIPPED_HOVER,
} from "../../../theme";
import { useStatusSelect } from "./Contexts/StatusSelectContext";

type Props = {
  compCycle: CompCycle;
};

const useStyles = makeStyles({
  accepted: {
    backgroundColor: STATUS_ACCEPTED,
    "&:hover": {
      backgroundColor: STATUS_ACCEPTED_HOVER,
    },
  },
  rejected: {
    backgroundColor: STATUS_REJECTED,
    "&:hover": {
      backgroundColor: STATUS_REJECTED_HOVER,
    },
  },
  needsReview: {
    backgroundColor: STATUS_NEEDS_REVIEW,
    "&:hover": {
      backgroundColor: STATUS_NEEDS_REVIEW_HOVER,
    },
  },
  late: {
    backgroundColor: STATUS_LATE,
    "&:hover": {
      backgroundColor: STATUS_LATE_HOVER,
    },
  },
  awaiting: {
    border: `1px solid ${STATUS_AWAITING_BORDER}`,
    background: STATUS_AWAITING,
    color: `${STATUS_AWAITING_COLOR} !important`,
    "&:hover": {
      border: `1px solid ${STATUS_AWAITING_BORDER}`,
      background: STATUS_AWAITING,
    },
  },
  all: {
    backgroundColor: GRAY_4,
    "&:hover": {
      backgroundColor: GRAY_3,
    },
  },
  reviewed: {
    backgroundColor: STATUS_REVIEWED,
    "&:hover": {
      backgroundColor: STATUS_REVIEWED_HOVER,
    },
  },
  skipped: {
    background: STATUS_SKIPPED,
    "&:hover": {
      backgroundColor: STATUS_SKIPPED_HOVER,
    },
  },
  futurePhase: {
    border: `1px solid ${STATUS_FUTURE_PHASE_BORDER}`,
    background: STATUS_FUTURE_PHASE,
    color: `${STATUS_FUTURE_PHASE_COLOR} !important`,
    "&:hover": {
      border: `1px solid ${STATUS_FUTURE_PHASE_BORDER}`,
      background: STATUS_FUTURE_PHASE,
    },
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
  },
});

export default function CondensedTableStatusSelect2({ compCycle }: Props) {
  const classes = useStyles();
  const { selectedStatus, setSelectedStatus } = useStatusSelect();
  const handleSelect = (option: Option) => {
    setSelectedStatus(option.value);
  };

  const compCycleWithNoPhases = compCycle.phases.length === 0;
  const today = new Date();

  // if the cycle has phases, check if any are in the future
  const futurePhases = !compCycleWithNoPhases
    ? new Date(
        compCycle.phases.at(-1)?.startDate ?? today.getDate() - 1
      ).getTime() > today.getTime()
    : false;

  return (
    <div className={classes.container} id="status-select">
      <ChipSelect
        options={[
          {
            value: "ALL",
            label: "All Statuses",
            className: classes.all,
          },
          {
            value: CompRecommendationStatus.LATE,
            label: "Late",
            className: classes.late,
            hideOption: compCycleWithNoPhases,
          },
          {
            value: CompRecommendationStatus.NEEDS_REVIEW,
            label: "Needs Review",
            className: classes.needsReview,
          },
          {
            value: CompRecommendationStatus.FUTURE_PHASE,
            label: "Future Phase",
            className: classes.futurePhase,
            hideOption: compCycleWithNoPhases || !futurePhases,
          },
          {
            value: CompRecommendationStatus.REVIEWED,
            label: "Reviewed",
            className: classes.reviewed,
          },
        ]}
        onChange={handleSelect}
        value={selectedStatus}
      />
    </div>
  );
}

CondensedTableStatusSelect2.fragments = {
  compCycle: gql`
    fragment CondensedTableStatusSelect_compCycle on CompCycle2 {
      id
      phases {
        id
        startDate
      }
    }
  `,
};
