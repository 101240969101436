import { mapify } from "@asmbl/shared/utils";
import fastDeepEqual from "fast-deep-equal";
import {
  CompRecommendationStatus,
  CompCycleSingleRecModal_recItem as RecItem,
  RecItem2,
  RecItemInput,
  ReviewRequestStatus,
} from "../../__generated__/graphql";
import { CompRecommendation } from "../../models/CompRecommendation";

export function getSubmittedRecommendation(employee: {
  id: number;
  compRecommendation: {
    reviewStatus: ReviewRequestStatus | CompRecommendationStatus | null;
    latestSubmittedItems: (RecItem | RecItem2)[];
  } | null;
}): CompRecommendation | undefined {
  const currentEmployeeRec = employee.compRecommendation;

  if (!currentEmployeeRec) return;

  // We only want the RecItems from the most recent submission. It's important
  // that we format the submitted items to match the RecItemInput format to be
  // able to compare if data has changed.
  const lastSubmittedItems: RecItemInput[] =
    currentEmployeeRec.latestSubmittedItems.map(toRecItemInput);

  return {
    items: mapify(lastSubmittedItems, "recommendationType"),
    subjectId: employee.id,
    reviewStatus: currentEmployeeRec.reviewStatus,
  };
}

/**
 * Converts a RecItem to a RecItemInput. Be warned: don't use the spread syntax
 * here, or you'll break the mutation.
 */
export function toRecItemInput({
  recommendationType,
  recommendedPosition,
  recommendedCashValue,
  recommendedPercentValue,
  recommendedTitle,
  unitType,
}: RecItem): RecItemInput {
  return {
    recommendationType,
    recommendedCashValue,
    recommendedPercentValue,
    unitType,
    note: null,
    recommendedPositionId: recommendedPosition?.id,
    recommendedTitle,
  };
}

export function recommendationHasBeenTouched(
  current: CompRecommendation,
  original: CompRecommendation | undefined | null
): boolean {
  // If the original recommendation is undefined then we have never made this
  // recommendation so it has been touched
  if (original === undefined || original === null) return true;

  for (const [currentRecItemType, currentRecItem] of current.items) {
    const originalRecItem = original.items.get(currentRecItemType);

    if (!fastDeepEqual(currentRecItem, originalRecItem)) return true;
  }

  return false;
}
