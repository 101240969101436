import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import {
  BudgetType,
  CompCycleSettings,
  CompSubComponentDisplay,
} from "../../../models/Budget";
import { GRAY_1 } from "../../../theme";
import { CompCycleData, CompCycleDataChangeHandler } from "./types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1, 0),
    padding: 0,
  },
  list: {
    marginLeft: theme.spacing(2),
  },
  group: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    color: GRAY_1,
  },
  checkbox: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
}));

type CompComponentSettings = CompCycleData["compComponents"];

type Props = {
  compComponents: CompComponentSettings;
} & (
  | {
      handleChange: CompCycleDataChangeHandler;
    }
  | { readonly: true }
);

const NOOP = () => {
  /* Ignore all changes when in readonly mode */
};

export const CompComponentsForm = (props: Props): JSX.Element => {
  const classes = useStyles();

  const selected = props.compComponents;

  const readonly = "readonly" in props && props.readonly;
  const handleChange = "handleChange" in props ? props.handleChange : NOOP;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const compComponent = event.target.value as CompCycleSettings;
    const updatedSettings = processUpdatedComponents(compComponent, selected);
    handleChange("compComponents", updatedSettings);
  };

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-salary"
              disabled={readonly}
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.SALARY]}
              value={CompCycleSettings.SALARY}
              indeterminate={
                selected[CompCycleSettings.MARKET] ||
                selected[CompCycleSettings.MERIT] ||
                selected[CompCycleSettings.PROMOTION]
              }
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-salary">
              {BudgetType.SALARY}
              {!readonly && " (select at least 1)"}
            </label>
          </ListItemText>
        </ListItem>
        <div className={classes.group}>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.checkbox}>
              <Checkbox
                id="component-checkbox-market"
                disabled={readonly}
                edge="start"
                onChange={onChange}
                checked={selected[CompCycleSettings.MARKET]}
                value={CompCycleSettings.MARKET}
              />
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="component-checkbox-market">
                {CompSubComponentDisplay.salaryMarket}
              </label>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.checkbox}>
              <Checkbox
                id="component-checkbox-merit"
                disabled={readonly}
                edge="start"
                onChange={onChange}
                checked={selected[CompCycleSettings.MERIT]}
                value={CompCycleSettings.MERIT}
              />
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="component-checkbox-merit">
                {CompSubComponentDisplay.salaryMerit}
              </label>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.checkbox}>
              <Checkbox
                id="component-checkbox-promotion"
                disabled={readonly}
                edge="start"
                onChange={onChange}
                checked={selected[CompCycleSettings.PROMOTION]}
                value={CompCycleSettings.PROMOTION}
              />
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="component-checkbox-promotion">
                {CompSubComponentDisplay.salaryPromotion}
              </label>
            </ListItemText>
          </ListItem>
        </div>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-target-commission"
              disabled={readonly}
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.TARGET_COMMISSION]}
              value={CompCycleSettings.TARGET_COMMISSION}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-target-commission">
              {BudgetType.TARGET_COMMISSION}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-target-recurring-bonus"
              disabled={readonly}
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.TARGET_RECURRING_BONUS]}
              value={CompCycleSettings.TARGET_RECURRING_BONUS}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-target-recurring-bonus">
              {BudgetType.TARGET_RECURRING_BONUS}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-actual-recurring-bonus"
              disabled={readonly}
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.ACTUAL_RECURRING_BONUS]}
              value={CompCycleSettings.ACTUAL_RECURRING_BONUS}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-actual-recurring-bonus">
              {BudgetType.ACTUAL_RECURRING_BONUS}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-bonus"
              disabled={readonly}
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.BONUS]}
              value={CompCycleSettings.BONUS}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-bonus">
              {BudgetType.ONE_TIME_BONUS}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-equity"
              disabled={readonly}
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.EQUITY]}
              value={CompCycleSettings.EQUITY}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-equity">
              {BudgetType.EQUITY}
            </label>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

const processUpdatedComponents = (
  component: CompCycleSettings,
  settings: CompComponentSettings
): CompComponentSettings => {
  const updated = { ...settings };

  if (component in settings) {
    const settingsComponent = component as keyof CompComponentSettings;
    updated[settingsComponent] = !settings[settingsComponent];
  }

  const isSalarySubComponent =
    component === CompCycleSettings.PROMOTION ||
    component === CompCycleSettings.MARKET ||
    component === CompCycleSettings.MERIT;
  const isAnySalarySubComponentEnabled =
    updated[CompCycleSettings.PROMOTION] ||
    updated[CompCycleSettings.MARKET] ||
    updated[CompCycleSettings.MERIT];

  if (component === CompCycleSettings.SALARY) {
    return {
      ...updated,
      [CompCycleSettings.MARKET]: updated[CompCycleSettings.SALARY],
      [CompCycleSettings.MERIT]: updated[CompCycleSettings.SALARY],
      [CompCycleSettings.PROMOTION]: updated[CompCycleSettings.SALARY],
    };
  } else if (isSalarySubComponent) {
    updated[CompCycleSettings.SALARY] = isAnySalarySubComponentEnabled;
  }

  return updated;
};
