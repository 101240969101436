import { partition } from "@asmbl/shared/utils";
import {
  CompCycleReviewRequests_compCycle,
  ReviewRequestStatus,
} from "src/__generated__/graphql";
import { CompCycleManagerWithStatus } from "./CompCycleReviewRequests";

export const UNGROUPED_KEY = "all";

export const sortAuthors = (
  filteredAuthors: CompCycleManagerWithStatus[],
  compCycle: CompCycleReviewRequests_compCycle
): { [key: string]: CompCycleManagerWithStatus[] } =>
  filteredAuthors.reduce(
    (grouped, author) => {
      const authorPhase = compCycle.phases.find((phase) => {
        return phase.compCyclePhaseAssignments
          .map((phase) => phase.assigneeId)
          .includes(author.id);
      });

      if (!authorPhase) return grouped;

      grouped[authorPhase.id].push(author);
      return grouped;
    },
    Object.fromEntries<CompCycleManagerWithStatus[]>(
      compCycle.phases.map((phase) => [phase.id, []])
    )
  );

export function checkForLateRecs(
  managers: CompCycleManagerWithStatus[]
): boolean {
  return managers.some((manager) => {
    const [directReports, indirectReports] = partition(
      manager.reports,
      (report) => report.manager?.id === manager.id
    );

    const reportsWithoutRecs = directReports.filter(
      (report) =>
        report.compRecommendation === null ||
        report.compRecommendation.reviewStatus ===
          ReviewRequestStatus.AWAITING_DEPENDENCY
    );

    const reportsWithoutReviews = indirectReports.filter(
      (report) =>
        report.compRecommendation !== null &&
        (report.compRecommendation.reviewStatus !==
          ReviewRequestStatus.AWAITING_DEPENDENCY ||
          (report.compRecommendation.latestSubmittedItems.at(0)?.author.id ===
            manager.user?.id &&
            report.compRecommendation.latestSubmittedReviews.at(0)?.author
              .id === manager.user?.id))
    );
    return reportsWithoutRecs.length > 0 || reportsWithoutReviews.length > 0;
  });
}

export function getLateRecsCounts(managers: CompCycleManagerWithStatus[]): {
  totalCount: number;
  lateManagers: number[];
} {
  let totalCount = 0;
  const lateManagers: number[] = [];
  managers.forEach((manager) => {
    const [directReports, indirectReports] = partition(
      manager.reports,
      (report) => report.manager?.id === manager.id
    );

    const reportsWithoutRecs = directReports.filter(
      (report) =>
        report.compRecommendation === null ||
        report.compRecommendation.reviewStatus ===
          ReviewRequestStatus.AWAITING_DEPENDENCY
    );

    totalCount += reportsWithoutRecs.length;

    const reportsWithoutReviews = indirectReports.filter(
      (report) =>
        report.compRecommendation !== null &&
        (report.compRecommendation.reviewStatus !==
          ReviewRequestStatus.AWAITING_DEPENDENCY ||
          (report.compRecommendation.latestSubmittedItems.at(0)?.author.id ===
            manager.user?.id &&
            report.compRecommendation.latestSubmittedReviews.at(0)?.author
              .id === manager.user?.id))
    );

    totalCount += reportsWithoutReviews.length;

    if (reportsWithoutRecs.length > 0 || reportsWithoutReviews.length > 0)
      lateManagers.push(manager.id);
  });

  return { totalCount, lateManagers };
}
