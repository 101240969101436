import { exchangeFromTo } from "@asmbl/shared/currency";
import {
  add,
  isZero,
  money,
  Money,
  ratio,
  subtract,
  zero,
} from "@asmbl/shared/money";
import { ButtonBase, Link, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import { useTrack } from "../../../analytics";
import { getAccurateSimpleCashLabel } from "../../../models/Currency";
import { PURPLE_1 } from "../../../theme";
import {
  CashCompType,
  EquityGrantMethod,
} from "../../../__generated__/graphql";
import { useCompStructure } from "../../CompStructureContext";
import { useCurrencies } from "../../CurrenciesContext";
import { BudgetDetail } from "./BudgetDetail";

const useStyles = makeStyles(() => ({
  toggleButton: {
    color: PURPLE_1,
    font: "unset",
    textTransform: "inherit",
    textAlign: "right",
  },
}));

type Props = {
  budgetType: string;
  variableCompType: CashCompType.COMMISSION | CashCompType.RECURRING_BONUS;
  items: RecItemWithSubject[];
  compCycleBudget?: Money | null;
};

export function BudgetVariableDetail({
  budgetType,
  variableCompType,
  items,
  compCycleBudget,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { currencies, defaultCurrency } = useCurrencies();
  const [showTotal, setShowTotal] = useState(true);

  const toggleCash = () => {
    const wasTotal = showTotal;
    setShowTotal(!wasTotal);
    trackEvent({
      object: "Variable Comp Display in Budget Drawer",
      action: "Toggled",
      variableDisplay: wasTotal ? "total" : "incremental",
    });
  };

  const compCycleCurrency =
    (compCycleBudget && currencies.get(compCycleBudget.currency)) ??
    defaultCurrency;

  const used = items
    .flatMap((item) => {
      // No change from this item, so skip it
      if (!item.recommendedCashValue) return [];

      const itemCurrency = currencies.get(item.recommendedCashValue.currency);
      // We can't convert this currency, so skip it.
      if (!itemCurrency) return [];

      return exchangeFromTo(
        item.recommendedCashValue,
        itemCurrency,
        compCycleCurrency
      );
    })
    .reduce(add, money(0, compCycleCurrency.code));

  const zer0 = zero(compCycleCurrency.code);

  const incremental = items
    .filter((i) => i.recommendedCashValue != null)
    .map((i) => [
      i.recommendedCashValue ?? zer0,
      i.subject.activeCashCompensation?.find((i) => i.type === variableCompType)
        ?.annualCashEquivalent ?? zer0,
    ])
    .map(([newCash, currentCash]) => subtract(newCash, currentCash))
    .reduce(add, zer0);

  const allocated = compCycleBudget ?? null;

  const percentUsed =
    allocated === null || isZero(allocated)
      ? null
      : ratio(used, allocated) * 100;

  return (
    <BudgetDetail
      budgetType={budgetType}
      percentUsed={percentUsed}
      title={
        compStructure?.equityGrantMethod !== EquityGrantMethod.CASH ? (
          <Link
            component={ButtonBase}
            className={classes.toggleButton}
            onClick={toggleCash}
          >
            {budgetType} {showTotal ? "Total" : "(Incremental)"}
          </Link>
        ) : (
          `${budgetType}`
        )
      }
      hideBudgetBar={!showTotal}
      usedOfAllocated={
        showTotal && allocated
          ? `${getAccurateSimpleCashLabel(
              used
            )} of ${getAccurateSimpleCashLabel(allocated)}`
          : showTotal
            ? getAccurateSimpleCashLabel(used)
            : `${incremental.value > 0 ? "+" : ""}${getAccurateSimpleCashLabel(
                incremental
              )}`
      }
    />
  );
}
