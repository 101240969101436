import { JobStructureScope } from "@asmbl/shared/permissions";
import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import React from "react";
import { Hint } from "../components/JobStructureSelect/Hint";

interface Data {
  id: number;
  name: string;
}

export interface TreeNode {
  data: Data;
  category: "Organization" | "Department" | "Ladder" | "Position";
  hint: JSX.Element;
  selected: boolean;
  inherited: boolean;
  indeterminate: boolean;
  children?: TreeNode[];
}

export function mapTree(
  root: Readonly<TreeNode>,
  fn: (node: Readonly<TreeNode>) => TreeNode
): TreeNode {
  return {
    ...fn(root),
    children: root.children?.map((child) => mapTree(child, fn)),
  };
}

interface Department {
  id: number;
  name: string;
  ladders: Ladder[];
}

interface Ladder {
  id: number;
  name: string;
  positions: Position[];
}

interface Position {
  id: number;
  level: number;
  name: string;
}

export interface JobStructure {
  organization: {
    name: string;
  };
  departments: Department[];
}

function byName(a: { name: string }, b: { name: string }): number {
  return caseInsensitiveComparator(a.name, b.name);
}

function byLevel(a: { level: number }, b: { level: number }): number {
  return a.level - b.level;
}

function countPositionsInJobStructure(jobStructure: JobStructure): number {
  return jobStructure.departments
    .flatMap((d) => d.ladders)
    .flatMap((l) => l.positions).length;
}
function countPositionsInDepartment(department: Department): number {
  return department.ladders.flatMap((l) => l.positions).length;
}
function countPositionsInLadder(ladder: Ladder): number {
  return ladder.positions.length;
}

export function selectionToTree(
  jobStructure: JobStructure,
  selection: NonNullable<JobStructureScope>,
  optionalInheritedSelection?: JobStructureScope
): TreeNode {
  const inheritedSelection = optionalInheritedSelection ?? {
    global: false,
    departmentIDs: [],
    ladderIDs: [],
    positionIDs: [],
  };
  const tree: TreeNode = {
    data: {
      id: 0,
      name: jobStructure.organization.name,
    },
    category: "Organization",
    hint: React.createElement(Hint, {
      text: countPositionsInJobStructure(jobStructure).toString(),
      isPosition: false,
    }),
    selected: selection.global,
    inherited: inheritedSelection.global,
    indeterminate: false,
    children: jobStructure.departments
      .slice()
      .sort(byName)
      .map((department) => ({
        data: department,
        category: "Department",
        hint: React.createElement(Hint, {
          text: countPositionsInDepartment(department).toString(),
          isPosition: false,
        }),
        selected: selection.departmentIDs.includes(department.id),
        inherited: inheritedSelection.departmentIDs.includes(department.id),
        indeterminate: false,
        children: department.ladders
          .slice()
          .sort(byName)
          .map((ladder) => ({
            data: ladder,
            category: "Ladder",
            hint: React.createElement(Hint, {
              text: countPositionsInLadder(ladder).toString(),
              isPosition: false,
            }),
            selected: selection.ladderIDs.includes(ladder.id),
            inherited: inheritedSelection.ladderIDs.includes(ladder.id),
            indeterminate: false,
            children: ladder.positions
              .slice()
              .sort(byLevel)
              .reverse()
              .map((position) => ({
                data: position,
                category: "Position",
                hint: React.createElement(Hint, {
                  text: position.level.toString(),
                  isPosition: true,
                }),
                selected: selection.positionIDs.includes(position.id),
                inherited: inheritedSelection.positionIDs.includes(position.id),
                indeterminate: false,
              })),
          })),
      })),
  };

  // Propagate selection state across parent and child nodes
  const departments = tree.children ?? [];
  departments.forEach((department) => {
    const ladders = department.children ?? [];

    department.selected = department.selected || tree.selected;
    department.inherited = department.inherited || tree.inherited;

    ladders.forEach((ladder) => {
      /*
        On the way DOWN, mark all child ladders and positions as selected
        if an ancestor is selected.
        */
      ladder.selected = ladder.selected || department.selected;
      ladder.inherited = ladder.inherited || department.inherited;

      const positions = ladder.children ?? [];

      positions.forEach((position) => {
        position.selected = position.selected || ladder.selected;
        position.inherited = position.inherited || ladder.inherited;
      });

      /*
        On the way UP, mark ladders and departments as selected if any
        children are selected. Mark as indeterminate if there is a mix,
        and therefore determinate if ALL children are selected.
        */
      ladder.selected = positions.some((p) => p.selected);
      ladder.inherited = positions.some((p) => p.inherited);
      ladder.indeterminate =
        positions.some((p) => p.indeterminate) ||
        ((ladder.selected || ladder.inherited) &&
          positions.some((p) => !p.selected && !p.inherited));
    });

    department.selected = ladders.some((l) => l.selected);
    department.inherited = ladders.some((l) => l.inherited);
    department.indeterminate =
      ladders.some((l) => l.indeterminate) ||
      ((department.selected || department.inherited) &&
        ladders.some((l) => !l.selected && !l.inherited));
  });

  tree.selected = departments.some((d) => d.selected);
  tree.inherited = departments.some((d) => d.inherited);
  tree.indeterminate =
    departments.some((d) => d.indeterminate) ||
    ((tree.selected || tree.inherited) &&
      departments.some((d) => !d.selected && !d.inherited));

  return tree;
}

export function treeToSelection(
  tree: TreeNode
): NonNullable<JobStructureScope> {
  if (tree.selected && !tree.indeterminate) {
    return { global: true, departmentIDs: [], ladderIDs: [], positionIDs: [] };
  }

  const selection: NonNullable<JobStructureScope> = {
    global: false,
    departmentIDs: [],
    ladderIDs: [],
    positionIDs: [],
  };

  tree.children?.forEach((department) => {
    if (!department.selected) {
      return;
    }
    if (!department.indeterminate) {
      selection.departmentIDs.push(department.data.id);
      return;
    }

    department.children?.forEach((ladder) => {
      if (!ladder.selected) {
        return;
      }
      if (!ladder.indeterminate) {
        selection.ladderIDs.push(ladder.data.id);
        return;
      }

      ladder.children?.forEach((position) => {
        if (position.selected) {
          selection.positionIDs.push(position.data.id);
        }
      });
    });
  });

  return selection;
}

export function countPositionsSelected(tree: Readonly<TreeNode>): number {
  if (tree.children === undefined) {
    // We are at the Position level.
    return tree.selected ? 1 : 0;
  }
  if (!tree.selected) {
    return 0;
  }

  return tree.children.reduce(
    (numPositions, child) =>
      numPositions + (child.selected ? countPositionsSelected(child) : 0),
    0
  );
}
