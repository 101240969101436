import { gql } from "@apollo/client";
import { preferredPrice } from "@asmbl/shared/money";
import { getSalaryCashComp } from "src/models/CashCompensation";
import {
  CompCyclePersonRow_compCycle,
  CompCyclePersonRow_participant,
  CompCyclePersonRow_position,
  CompCyclePersonRow_valuation,
  CompCycleSingleRec_perfRatingOption as PerfRatingOption,
  RecItemInput,
  RecItemType,
} from "../../../__generated__/graphql";
import { CompRecommendation } from "../../../models/CompRecommendation";
import { HeaderConfig } from "../../../models/HeaderConfig";
import { useCurrencies } from "../../CurrenciesContext";
import { CompCycleGrouping } from "../types";
import { ActualVariablePayRows } from "./ActualVariablePayRows";
import { CashRows } from "./CashRows";
import { EquityRows } from "./EquityRows";
import { LastRow } from "./LineItemRows/LastRow";
import { ReviewerNoteRow } from "./LineItemRows/ReviewerNote";
import { PersonRowHeaders } from "./PersonRowHeaders";
import { PositionLoadingBoundary } from "./PositionLoadingBoundary";
import { TargetVariablePayRows } from "./TargetVariablePayRows";

interface Props {
  employee: CompCyclePersonRow_participant;
  compCycle: CompCyclePersonRow_compCycle;
  positions: CompCyclePersonRow_position[];
  submittedRecommendation: CompRecommendation | null;
  revisedRecommendation: CompRecommendation | null;
  currentValuation: CompCyclePersonRow_valuation;
  onChangeRecommendation: (
    employeeId: number,
    newRecommendation: CompRecommendation | null
  ) => unknown;
  filter: CompCycleGrouping;
  headerConfig: HeaderConfig;
  guidance?: number;
  perfRatingOptions: PerfRatingOption[];
  setRevisedPerfRating: (perfRating: string) => unknown;
}

export function CompCyclePersonRow({
  employee,
  compCycle,
  positions,
  submittedRecommendation,
  revisedRecommendation,
  currentValuation,
  onChangeRecommendation,
  filter,
  headerConfig,
  guidance,
  perfRatingOptions,
  setRevisedPerfRating,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const { subject } = employee;
  const handleNewRecommendationItem = (
    newRecommendationItem: RecItemInput | null
  ) => {
    const items = new Map(revisedRecommendation?.items ?? []);
    if (newRecommendationItem !== null) {
      items.set(
        newRecommendationItem.recommendationType,
        newRecommendationItem
      );
    }

    onChangeRecommendation(employee.subjectId, {
      subjectId: employee.subjectId,
      reviewStatus: revisedRecommendation?.reviewStatus,
      items,
    });
  };

  const currencyCode =
    getSalaryCashComp(subject.activeCashCompensation)?.annualCashEquivalent
      .currency ?? defaultCurrencyCode;

  const locationGroupId = subject.location?.id;

  const promotedPositionId =
    revisedRecommendation?.items.get(RecItemType.PROMOTION)
      ?.recommendedPositionId ?? null;

  return (
    <PositionLoadingBoundary
      currencyCode={currencyCode}
      marketId={subject.location?.marketId ?? null}
      locationGroupId={locationGroupId}
      promotedPositionId={promotedPositionId}
    >
      {(promotedPosition) => (
        <>
          <PersonRowHeaders
            employee={employee}
            draftRecommendation={revisedRecommendation}
            sharePrice={preferredPrice(
              currentValuation.fdso,
              currentValuation.valuation
            )}
            promotedPosition={promotedPosition}
            headerConfig={headerConfig}
            valuation={currentValuation.valuation}
            perfRatingOptions={perfRatingOptions}
            setRevisedPerfRating={setRevisedPerfRating}
          />
          {compCycle.allowSalary && (
            <CashRows
              show={filter === "salary" || filter === "all"}
              employee={subject}
              positions={positions}
              promotedPosition={promotedPosition}
              compCycle={compCycle}
              submittedRecommendation={submittedRecommendation}
              revisedRecommendation={revisedRecommendation}
              handleNewRecommendationItem={handleNewRecommendationItem}
              guidance={guidance}
            />
          )}
          {(compCycle.allowTargetCommission ||
            compCycle.allowTargetRecurringBonus) && (
            <TargetVariablePayRows
              show={filter === "target" || filter === "all"}
              employee={subject}
              promotedPosition={promotedPosition}
              compCycle={compCycle}
              submittedRecommendation={submittedRecommendation}
              revisedRecommendation={revisedRecommendation}
              handleNewRecommendationItem={handleNewRecommendationItem}
            />
          )}
          {(compCycle.allowBonus || compCycle.allowActualRecurringBonus) && (
            <ActualVariablePayRows
              show={filter === "actual" || filter === "all"}
              employee={subject}
              compCycle={compCycle}
              submittedRecommendation={submittedRecommendation}
              revisedRecommendation={revisedRecommendation}
              handleNewRecommendationItem={handleNewRecommendationItem}
            />
          )}
          {compCycle.allowEquity && (
            <EquityRows
              show={filter === "equity" || filter === "all"}
              employee={subject}
              currentValuation={currentValuation}
              submittedRecommendation={submittedRecommendation}
              revisedRecommendation={revisedRecommendation}
              handleNewRecommendationItem={handleNewRecommendationItem}
            />
          )}
          <ReviewerNoteRow employee={employee} />
          <LastRow />
        </>
      )}
    </PositionLoadingBoundary>
  );
}

CompCyclePersonRow.fragments = {
  participant: gql`
    ${PersonRowHeaders.fragments.participant}
    ${CashRows.fragments.employee}
    ${TargetVariablePayRows.fragments.employee}
    ${EquityRows.fragments.employee}
    ${ActualVariablePayRows.fragments.employee}
    ${ReviewerNoteRow.fragments.participant}
    fragment CompCyclePersonRow_participant on CompCycleParticipant {
      subjectId
      compCycleId
      ...ReviewerNoteRow_participant
      ...PersonRowHeaders_participant
      subject {
        ...EquityRows_employee
        ...TargetVariablePayRows_employee
        ...CashRows_employee
        ...ActualVariablePayRows_employee
        location {
          id
          marketId
        }
      }
    }
  `,
  position: gql`
    ${CashRows.fragments.position}
    fragment CompCyclePersonRow_position on Position {
      ...CashRows_position
      id
    }
  `,
  valuation: gql`
    ${EquityRows.fragments.valuation}
    fragment CompCyclePersonRow_valuation on Valuation {
      ...EquityRows_valuation
      id
    }
  `,
  compCycle: gql`
    ${CashRows.fragments.compCycle}
    ${TargetVariablePayRows.fragments.compCycle}
    ${ActualVariablePayRows.fragments.compCycle}
    fragment CompCyclePersonRow_compCycle on CompCycle2 {
      ...CashRows_compCycle
      ...TargetVariablePayRows_compCycle
      ...ActualVariablePayRows_compCycle
      allowEquity
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
    }
  `,
};
