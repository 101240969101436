import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Noun } from "@asmbl/shared/permissions";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useTrack } from "../../analytics";
import { AccessBoundary } from "../../components/AccessBoundary";
import { NoFeatureAccess } from "../../components/EmptyState/EmptyState";
import { NoRouteFound } from "../../components/EmptyState/NoRouteFound";
import {
  PreviewFeature,
  useFeatureFlags,
} from "../../components/FeatureContext";
import {
  EmployeeDetailDrawer,
  useEmployeeDetail,
} from "../People/EmployeeDetailDrawer/EmployeeDetailDrawer";
import { BudgetPage } from "./Budgets/BudgetPage";
import { BudgetsVsRequestsPage } from "./BudgetsVsRequests/BudgetsVsRequestsPage";
import { CompCyclesLoadingBoundary } from "./CompCycleList/CompCyclesLoadingBoundary";
import { CreateCompCycleBoundary } from "./CreateCompCycleBoundary";
import { CompCycleHome } from "./Home/CompCycleHome";
import { CompCycleHeader } from "./Navigation/CompCycleHeader";
import { CompCycleTabs } from "./Navigation/NavigationTabBar";
import { CondensedReviewPage } from "./Plan/CondensedReviewPage";
import { ReportingPage } from "./Reporting/ReportingPage";
import { CompCycleSettingsLoadingBoundary } from "./Settings/CompCycleSettingsLoadingBoundary";

export function CompCyclesRouter(): JSX.Element {
  const { Track } = useTrack({ area: "Comp Cycles" });
  const currentPath = window.location.pathname;
  const { isEnabled } = useFeatureFlags();
  const hasDashboardAccess = isEnabled(
    FeatureFlag.IndividualAccessToCompCycleDashboard
  );

  return (
    <Track>
      <Routes>
        {/* `/new` Eating the route so the comp cycle header doesn't render */}
        <Route path="new" />
        <Route path=":compCycleId/*">
          <Route path="*" element={<CompCycleHeader />} />
          <Route
            // Edit and update configurable settings
            path="settings/*"
            element={
              <AccessBoundary
                verb="view"
                scope="any"
                every={[Noun.CompCycle, Noun.CompCycleBudget]}
                fallback={<NoFeatureAccess feature="Comp Cycle Settings" />}
              >
                <CompCycleSettingsLoadingBoundary />
              </AccessBoundary>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route
          index // Lists all the existing compCycles, both active and not
          element={
            <AccessBoundary
              verb="view"
              scope="any"
              every={[Noun.Employee, Noun.JobStructure, Noun.CompCycle]}
              fallback={
                <NoFeatureAccess feature="Compensation Recommendations" />
              }
            >
              <CompCyclesLoadingBoundary />
            </AccessBoundary>
          }
        />
        <Route
          path="new" // Create a new comp cycle
          element={
            <AccessBoundary
              verb="view"
              scope="any"
              every={[
                Noun.Employee,
                Noun.JobStructure,
                Noun.CompCycle,
                Noun.CompStructure,
              ]}
              fallback={
                <NoFeatureAccess feature="Compensation Recommendations" />
              }
            >
              <CreateCompCycleBoundary />
            </AccessBoundary>
          }
        />
        <Route
          path=":compCycleId" // Lists all the recommendations grouped by manager
        >
          <Route
            index
            element={
              <AccessBoundary
                verb="view"
                scope="any"
                every={[Noun.Employee, Noun.JobStructure, Noun.CompCycle]}
                fallback={
                  <NoFeatureAccess feature="Compensation Recommendations" />
                }
              >
                <CompCycleHome />
              </AccessBoundary>
            }
          />
          <Route // Condensed table to review recs
            path={CompCycleTabs.CompRequests}
            element={
              <AccessBoundary
                verb="view"
                scope="any"
                every={[
                  Noun.Employee,
                  Noun.JobStructure,
                  Noun.CompRecommendation,
                ]}
                fallback={
                  <NoFeatureAccess feature="Compensation Recommendations" />
                }
              >
                <CondensedReviewPage />
              </AccessBoundary>
            }
          />

          <Route
            path={CompCycleTabs.CompChanges}
            element={
              // unfortunately I cannot use the useParams() hook to
              // get the comp cycleId since the route happens
              // before the hook is available so I am just
              // using the window.location.pathname + the string manipulation
              // /comp-cycles/:compCycleId/requests
              <Navigate
                replace
                to={`${currentPath.slice(
                  0,
                  currentPath.lastIndexOf("/")
                )}/requests`}
              />
            }
          />

          <Route
            path="compare"
            element={
              <AccessBoundary
                verb="view"
                scope="any"
                every={[Noun.Employee, Noun.CompCycle, Noun.CompCycleBudget]}
                fallback={
                  <NoFeatureAccess feature="Compensation Recommendations" />
                }
              >
                <BudgetsVsRequestsPage />
              </AccessBoundary>
            }
          />
          <Route
            path="analysis/:dashboard"
            element={
              <PreviewFeature
                flag={FeatureFlag.CompCycleDashboard}
                fallback={<NoRouteFound />}
              >
                {hasDashboardAccess ? (
                  <ReportingPage />
                ) : (
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[
                      Noun.Employee,
                      Noun.CompCycle,
                      Noun.JobStructure,
                      Noun.CompRecommendation,
                    ]}
                    fallback={
                      <NoFeatureAccess feature="Compensation Recommendations" />
                    }
                  >
                    <ReportingPage />
                  </AccessBoundary>
                )}
              </PreviewFeature>
            }
          />
          <Route // Cycle's budget overview
            path="budget"
          >
            <Route
              index
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Employee, Noun.CompCycle, Noun.CompCycleBudget]}
                  fallback={<NoFeatureAccess feature="Budgets" />}
                >
                  <BudgetPage />
                </AccessBoundary>
              }
            />
            <Route // Cycle's budget rooted on a specific employee
              path=":employeeId"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Employee, Noun.CompCycle, Noun.CompCycleBudget]}
                  fallback={<NoFeatureAccess feature="Budgets" />}
                >
                  <BudgetPage />
                </AccessBoundary>
              }
            />
          </Route>
        </Route>
      </Routes>
      <Routes>
        <Route
          path=":compCycleId/*"
          element={<CompCycleEmployeeDetailDrawer />}
        />
      </Routes>
    </Track>
  );
}

function CompCycleEmployeeDetailDrawer(): JSX.Element | null {
  const employeeDetailId = useEmployeeDetail();
  const { compCycleId } = useParams<{ compCycleId: string }>();

  const cycleId = compCycleId != null ? parseInt(compCycleId) : NaN;
  return (
    <EmployeeDetailDrawer
      id={employeeDetailId}
      compCycleId={isNaN(cycleId) ? undefined : cycleId}
    />
  );
}
