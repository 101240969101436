import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AllCompleteReviewDialogState } from "../Dialogs/CompleteReviewDialog";

interface Props {
  compCycle: { name: string; endedAt: string | Date | null; id: number };
  setDialogState: (state: AllCompleteReviewDialogState) => void;
}

export function CompleteCompCycleButton({
  compCycle,
  setDialogState,
}: Props): JSX.Element | null {
  return (
    <AssembleButton
      variant="contained"
      onClick={() => setDialogState("open")}
      disabled={compCycle.endedAt !== null}
      id="page-complete-comp-cycle-button"
      label="Close Cycle"
      size="medium"
    />
  );
}
