import { MONTHS_IN_A_YEAR } from "./constants";

/**
 *
 * @param date
 * @returns The date string in the format YYYY-MM-DD in UTC
 */
export function plainDateToUTCString(date: Date): string {
  return date.toISOString().split("T")[0];
}

/**
 *
 * @param date
 * @returns The date string in the format YYYY-MM-DD without converting to UTC
 */
export function plainDateToLocaleString(date: Date = new Date()): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/**
 *
 * @param date
 * @returns a string in the format of "MONTH DAY at TIME DAY_PERIOD"
 */
export function formatTimestamp(
  date: string | undefined | null | Date
): string {
  if (date == null) {
    return "";
  }

  const properDate = typeof date === "string" ? new Date(date) : date;

  return Intl.DateTimeFormat("en-us", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
  }).format(properDate);
}

/**
 *
 * @param date
 * @returns a string in the format of "MONTH DAY, YEAR"
 */
export function getFormattedDate(
  date: string | undefined | null | Date
): string {
  if (date == null) {
    return "";
  }

  const properDate = typeof date === "string" ? new Date(date) : date;

  return Intl.DateTimeFormat("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  }).format(properDate);
}

/**
 *
 * @param date
 * @returns a string in the format of "MONTH(short), YEAR"
 */
export function getFormattedShortDate(
  date: string | undefined | null | Date
): string {
  if (date == null) {
    return "";
  }

  const properDate = typeof date === "string" ? new Date(date) : date;

  return Intl.DateTimeFormat("default", {
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  }).format(properDate);
}

/**
 *
 * @param date
 * @returns a string in the format of "MONTH(short) DAY"
 */
export function getFormattedMonthAndDay(
  date: string | undefined | null | Date
): string {
  if (date == null) {
    return "";
  }

  const properDate = typeof date === "string" ? new Date(date) : date;

  return Intl.DateTimeFormat("default", {
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }).format(properDate);
}

/* Return the number of full months elapsed since the date */
export function getMonthsElapsed(
  since: string | Date | null | undefined,
  now: Date = new Date()
): number | undefined {
  if (since == null) return undefined;
  const activeAt = typeof since === "string" ? new Date(since) : since;

  const months =
    (now.getFullYear() - activeAt.getFullYear()) * MONTHS_IN_A_YEAR +
    (now.getMonth() - activeAt.getMonth());

  const isBeforeMonthiversary = now.getDate() < activeAt.getDate();

  return Math.max(months - (isBeforeMonthiversary ? 1 : 0), 0);
}

/**
 * Returns a nicely formatted, short tenure. Anything less than 1 month is
 * displayed as "0 months". `undefined` returns an empty string.
 *
 * Examples:
 *   - 2 years, 4 months
 *   - 1 year
 *   - 2 months
 *
 * If `options.short` is true, returns the same structured string , but with
 * "yr" instead of "year" and "mo" instead of "month"
 */
export function formatElapsedTime(
  months: number | undefined,
  options: { short?: boolean } = { short: false }
): string {
  if (months === undefined) return "";
  if (months < 0) return "0 months";

  const monthPart =
    months % MONTHS_IN_A_YEAR === 1
      ? "1 month"
      : `${months % MONTHS_IN_A_YEAR} months`;
  const years = Math.floor(months / MONTHS_IN_A_YEAR);
  const yearPart = years === 1 ? "1 year" : `${years} years`;

  const result =
    years === 0
      ? monthPart
      : months % MONTHS_IN_A_YEAR === 0
      ? yearPart
      : `${yearPart}, ${monthPart}`;

  return options.short !== true
    ? result
    : result
        .replace(",", "")
        // replace the whitespace before the time units with a unicode literal
        // to prevent the string wrapping after a number (ex: 12 yrs 5 / mos)
        .replace(" month", "\u00A0mo")
        .replace(" year", "\u00A0yr");
}

/**
 * Returns a comparison between two dates without the time component.
 * i.e. purely a date comparison
 */
export function isDateBefore(
  a: Date | null | undefined,
  b: Date | null | undefined
): boolean {
  if (a == null || b == null) return false;
  const aDate = new Date(a.toDateString());
  const bDate = new Date(b.toDateString());
  return aDate.getTime() < bDate.getTime();
}

/**
 * Returns a comparison between two dates without the time component.
 * i.e. purely a date comparison
 */
export function isDateEqual(
  a: Date | null | undefined,
  b: Date | null | undefined
): boolean {
  if (a == null || b == null) return false;
  const aDate = new Date(a.toDateString());
  const bDate = new Date(b.toDateString());
  return aDate.getTime() === bDate.getTime();
}

/**
 * Simple helper function used for better code readability. This returns a date
 * with the time set to the start of the day.
 *
 * `setUTCHours` modifies the date, but we are instantiating a new date object
 * to make this more functional
 *
 * @param date
 * @returns a new date with the time set to the start of the day
 */
export function startOfDay(date: Date): Date {
  return new Date(date.setUTCHours(0, 0, 0, 0));
}

// 1_000 milliseconds in a second * 60 seconds in a minute * 60 minutes in an
// hour * 24 hours in a day
export const DAYS_IN_MS = 1_000 * 60 * 60 * 24;
