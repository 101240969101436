import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import {
  CondensedTableEquityUnitsCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableEquityUnitsCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const equityGrantItem = recItems.find(
    (item) => item.recommendationType === RecItemType.EQUITY_GRANT
  );

  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(equityGrantItem?.recommendedEquityUnitCount ?? 0)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableEquityUnitsCell2.fragments = {
  participant: gql`
    fragment CondensedTableEquityUnitsCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedEquityUnitCount
        }
      }
    }
  `,
};

CondensedTableEquityUnitsCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.EQUITY_UNITS
);
CondensedTableEquityUnitsCell2.id = ColumnIds.EQUITY_UNITS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableEquityUnitsCell2,
  Header: CondensedTableEquityUnitsCell2.Header,
  id: CondensedTableEquityUnitsCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableEquityUnitsCell2.column = column;
CondensedTableEquityUnitsCell2.ordering = () =>
  contramap((e: Participant) => {
    const equityItem = e.compRecommendation?.latestSubmittedItems.find(
      (item) => item.recommendationType === RecItemType.EQUITY_GRANT
    );
    return equityItem?.recommendedEquityUnitCount ?? 0;
  });
