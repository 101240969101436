import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { mapMaybe } from "@asmbl/shared/utils";
import { createContext, ReactNode, useContext, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import { useFeatureFlags } from "../FeatureContext";
import UnsavedChangesWarning from "../UnsavedChangesWarning";
/**
 * Unfortunately, the d3-org-chart library is having issues with
 * storing the `chart` in a context, once we navigate away from the
 * org chart & we come back the chart panning is broken.
 *
 * So the `chart` value we have here is simply for other components to
 * able to access the chart data, NOT the chart that is actually rendered
 * on the screen.
 *
 * For example, given the chart, we are able to find the actual nodes on
 * each layer
 */

export enum SelectionMode {
  Managers = "managers",
  Layers = "layers",
}

type CompCycleOrganizationChartSelectionContextValue = {
  selectionMode: SelectionMode;
  layerData: { [key: number]: number[] | undefined };
  selectedLayers: { [key: string]: boolean | undefined };
  layerDepth: number;
  selectedEmployees: Set<number>;
  employeeData: Employee[];
  setLayerData: (data: { [key: number]: number[] | undefined }) => void;
  setSelectedLayers: (key: string) => void;
  clearSelectedLayers: () => void;
  clearChartSelections: () => void;
  setLayerDepth: (depth: number) => void;
  setSelectedEmployees: (emps: Set<number>) => void;
  setEmployeeData: (data: Employee[]) => void;
};

type CompCycleOrganizationChartViewContextValue = {
  chartState: "view" | "edit";
  setChartState: (state: "view" | "edit") => void;
  pageEdited: boolean;
  setPageEditState: (setPage: boolean) => void;
  chartLoaded: boolean;
  setChartLoadState: (setPage: boolean) => void;
};

const CompCycleOrganizationChartSelectionContext =
  createContext<CompCycleOrganizationChartSelectionContextValue>({
    selectionMode: SelectionMode.Layers,
    layerData: {},
    employeeData: [],
    selectedLayers: {},
    layerDepth: -1,
    selectedEmployees: new Set(),
    setLayerData: () => new Object(),
    setSelectedLayers: () => new Object(),
    clearSelectedLayers: () => new Object(),
    setLayerDepth: () => new Object(),
    setSelectedEmployees: () => new Set(),
    setEmployeeData: () => [],
    clearChartSelections: () => new Object(),
  } as CompCycleOrganizationChartSelectionContextValue);

const CompCycleOrganizationChartViewContext =
  createContext<CompCycleOrganizationChartViewContextValue>({
    chartState: "view",
    setChartState: () => new Object(),
    pageEdited: false,
    setPageEditState: () => false,
    chartLoaded: false,
    setChartLoadState: () => false,
  } as CompCycleOrganizationChartViewContextValue);

export const CompCycleOrganizationChartSelectionProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { trackEvent } = useTrack();
  const { isEnabled } = useFeatureFlags();
  const intercom = useIntercom();
  const seqApprovalsManagerEnabled = isEnabled(
    FeatureFlag.SequentialApprovalsManagerFlow
  );
  const selectionMode = seqApprovalsManagerEnabled
    ? SelectionMode.Managers
    : SelectionMode.Layers;

  const [layerDepth, setLayerDepth] = useState(-1);
  const [layerData, setLayerData] = useState<{
    [key: number]: number[] | undefined;
  }>({});

  const [selectedLayers, setSelectedLayers] = useState<{
    [key: string]: boolean | undefined;
  }>({});
  const [selectedEmployees, setSelectedEmployees] = useState<Set<number>>(
    new Set()
  );
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);

  const handleSelectedLayersChange = (key: string) => {
    setSelectedLayers((state) => {
      const currentValue = state[key];

      return {
        ...state,
        [key]: currentValue === undefined ? true : !currentValue,
      };
    });

    const currLayerSelection = mapMaybe(
      Object.entries(layerData),
      ([key, value]) => {
        if (value !== undefined) return parseInt(key);
      }
    );

    const layers = currLayerSelection.join(", ");
    // current selection may be higher if there are non-mgr layers
    const allLayersSelected = currLayerSelection.length >= layerDepth;

    trackEvent({
      object: "Phase Layer Selection",
      action: "Added",
      layers,
      layerDepth,
      allLayersSelected,
    });

    intercom.trackEvent("Phase Layer Added", {
      layers,
      layerDepth,
      allLayersSelected,
    });
  };

  const clearSelectedLayers = () => setSelectedLayers({});

  const clearChartSelections = () => {
    setSelectedLayers({});
    setSelectedEmployees(new Set());
  };

  return (
    <CompCycleOrganizationChartSelectionContext.Provider
      value={{
        selectionMode,
        layerData,
        employeeData,
        layerDepth,
        selectedLayers,
        selectedEmployees,
        setLayerData,
        setSelectedLayers: handleSelectedLayersChange,
        clearSelectedLayers,
        setLayerDepth,
        setSelectedEmployees,
        setEmployeeData,
        clearChartSelections,
      }}
    >
      {children}
    </CompCycleOrganizationChartSelectionContext.Provider>
  );
};

export const CompCycleOrganizationChartViewsProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [chartState, setChartState] = useState<"view" | "edit">("view");
  const [pageEdited, setPageEdited] = useState(false);
  const [chartLoaded, setChartLoaded] = useState(false);
  const setPageEditState = (setPage: boolean) => {
    if (setPage === false && pageEdited) {
      setChartState("view");
    }
    setPageEdited(setPage);
  };

  return (
    <CompCycleOrganizationChartViewContext.Provider
      value={{
        chartState,
        setChartState,
        pageEdited,
        setPageEditState,
        chartLoaded,
        setChartLoadState: setChartLoaded,
      }}
    >
      <UnsavedChangesWarning pageEdited={pageEdited} />
      {children}
    </CompCycleOrganizationChartViewContext.Provider>
  );
};

export const useCompCycleOrganizationChartSelectionData =
  (): CompCycleOrganizationChartSelectionContextValue => {
    return useContext(CompCycleOrganizationChartSelectionContext);
  };

export const useCompCycleOrganizationChartViewsData =
  (): CompCycleOrganizationChartViewContextValue => {
    return useContext(CompCycleOrganizationChartViewContext);
  };
