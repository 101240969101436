import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { activeComp } from "../../../../models/CashCompensation";
import {
  CashCompType,
  CondensedTableCurrentTargetCommissionPercentCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

function getCommissionPercent(employee: Participant): number {
  const activeCashComp = activeComp(
    employee.subject.activeCashCompensation ?? [],
    new Date()
  );
  return activeCashComp.get(CashCompType.COMMISSION)?.percentOfSalary ?? 0;
}

export function CondensedTableCurrentTargetCommissionPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(getCommissionPercent(employee) / 100, {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentTargetCommissionPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentTargetCommissionPercentCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          percentOfSalary
        }
      }
    }
  `,
};

CondensedTableCurrentTargetCommissionPercentCell2.Header =
  ColumnIdsToHeaders.get(ColumnIds.CURRENT_TARGET_COMMISSION_PERCENT);
CondensedTableCurrentTargetCommissionPercentCell2.id =
  ColumnIds.CURRENT_TARGET_COMMISSION_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentTargetCommissionPercentCell2,
  Header: CondensedTableCurrentTargetCommissionPercentCell2.Header,
  id: CondensedTableCurrentTargetCommissionPercentCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableCurrentTargetCommissionPercentCell2.column = column;
CondensedTableCurrentTargetCommissionPercentCell2.ordering = () =>
  contramap(getCommissionPercent);
