import { Button, makeStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import { GRAY_2, GRAY_5, PURPLE_1, WHITE } from "../../theme";
import { SearchableFilterProps } from "./SearchableFilter";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    marginRight: theme.spacing(0.5),
  },
  buttonContainer: {
    display: "flex",
    flex: "1 0",
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    marginRight: theme.spacing(1),
  },
  addFilterButton: {
    whiteSpace: "nowrap",
    color: GRAY_2,
    backgroundColor: WHITE,
    borderColor: GRAY_5,
    boxShadow:
      "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",
    // Hover Focus Active
    "&:hover, &:focus, &:active": {
      backgroundColor: "white",
      border: `1px solid ${PURPLE_1}`,
      color: `${PURPLE_1}`,
    },
    // Focus & Active
    "&:focus, &:active": {
      boxShadow:
        "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
    },
  },
  resetButton: {
    whiteSpace: "nowrap",
    marginLeft: "auto",
    color: GRAY_2,
    borderColor: GRAY_5,
    boxShadow:
      "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",
    // Hover Focus Active
    "&:hover, &:focus, &:active": {
      backgroundColor: "white",
      border: `1px solid ${PURPLE_1}`,
      color: `${PURPLE_1}`,
    },
    // Focus & Active
    "&:focus, &:active": {
      boxShadow:
        "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
    },
  },
}));

type Child = ReactElement<SearchableFilterProps<number | string>>;
type Props = {
  children: Child | Child[];
  activeFilters?: string[];
  onReset(): unknown;
};

/**
 * Given 1 or more SearchableFilter children, this component will conditionally
 * render the filters if a user selects them.
 *
 * It automatically excludes any filters whose `options` length is less than 2.
 */
export function SearchableFilterContainer({
  children,
  activeFilters = [],
  onReset,
}: Props): JSX.Element {
  const arrayChildren = React.Children.toArray(children) as Child[];
  // Only display filters with more than one option
  const childFilters = arrayChildren.filter((c) => c.props.options.length > 1);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<string[]>(activeFilters);

  const handleAddFilter = (newName: string) => () => {
    setOpen(false);
    setActive((prev) => [...prev, newName]);
  };

  const handleReset = () => {
    setActive([]);
    onReset();
  };

  const onDelete = (name: string) =>
    setActive((prev) => prev.filter((f) => f !== name));

  const available = childFilters
    .map((c) => c.props)
    .filter((c) => !active.includes(c.name));

  return (
    <div className={classes.root}>
      {active.length > 0 && (
        <div className={classes.filterContainer}>
          {active.map((filter) => {
            const child = childFilters.find((c) => c.props.name === filter);
            return child === undefined
              ? undefined
              : React.cloneElement(child, {
                  onDelete: () => onDelete(child.props.name),
                });
          })}
        </div>
      )}
      <div className={classes.buttonContainer}>
        {available.length > 0 && (
          <Button
            ref={setAnchorEl}
            variant="outlined"
            size="small"
            onClick={() => setOpen(true)}
            className={classes.addFilterButton}
            data-intercom-target={"add-filter"}
          >
            + Add Filter
          </Button>
        )}
        {active.length > 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={handleReset}
            className={classes.resetButton}
          >
            Reset
          </Button>
        )}
      </div>

      {/* Remove from DOM to mitigate menu flickering animation */}
      {open && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={() => setOpen(false)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {available.map(({ name, disabled }) => (
            <MenuItem
              key={name}
              onClick={handleAddFilter(name)}
              disabled={disabled}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
