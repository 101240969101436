import { makeStyles } from "@material-ui/core";
import { AssembleLink } from "src/components/AssembleLink";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DismissableInfoBanner } from "src/components/InfoBanner";
import { FilterParam } from "src/models/FilterParams";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { CompCycleManagerWithStatus } from "src/pages/CompCycle/Home/CompCycleReviewRequests";
import {
  getLateRecsCounts,
  UNGROUPED_KEY,
} from "src/pages/CompCycle/Home/util";
import { WHITE } from "src/theme";
import { RelativeCompRecStatus } from "src/__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(1.5) },
  content: {
    paddingRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type Props = {
  compCycleId: number;
  managers: { [key: string]: CompCycleManagerWithStatus[] };
  previousPhaseIds: number[];
};

export function BehindScheduleBanner({
  managers,
  compCycleId,
  previousPhaseIds,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();
  const phaseKeys = Object.keys(managers);
  if (previousPhaseIds.length === 0 || phaseKeys.includes(UNGROUPED_KEY)) {
    return null;
  }

  const { totalCount: count, lateManagers } = getLateRecsCounts(
    Object.keys(managers)
      .filter((key) => previousPhaseIds.includes(parseInt(key)))
      .map((key) => managers[key])
      .flat()
  );

  const updatedParams = urlSearchParams
    .append(FilterParam.MANAGER, lateManagers.join(","))
    .append(FilterParam.STATUS, RelativeCompRecStatus.LATE);

  return count > 0 ? (
    <DismissableInfoBanner
      className={classes.root}
      level="warning"
      centeredClose
    >
      <div className={classes.content}>
        {count === 1 ? (
          <AssembleTypography variant="productSmallBold">
            There is 1 person who is behind schedule and needs requests
            submitted. Let their manager know or submit recommendations on their
            behalf.
          </AssembleTypography>
        ) : (
          <AssembleTypography variant="productSmallBold">
            There are {count} people who are behind schedule and need requests
            submitted. Let their manager know or submit recommendations on their
            behalf.
          </AssembleTypography>
        )}
        <AssembleLink
          underline="none"
          to={`/comp-cycles/${compCycleId}/requests?${updatedParams.toString()}`}
        >
          <AssembleTypography variant="productSmallBold" textColor={WHITE}>
            View People
          </AssembleTypography>
        </AssembleLink>
      </div>
    </DismissableInfoBanner>
  ) : null;
}
