import { gql } from "@apollo/client";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CurrencyCode,
  CondensedTableNewTargetCashCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getTotalCashAfterRecommendation } from "../../../../models/CashCompensation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableNewTargetCashCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();

  const totalCash = getTotalCashAfterRecommendation(
    defaultCurrencyCode,
    employee.subject.activeCashCompensation,
    employee.compRecommendation?.latestSubmittedItems ?? [],
    compStructure?.workingHoursPerYear
  )?.annualTotal;

  return (
    <AssembleTruncatedTypography align="right">
      {totalCash ? getSimpleCashLabel(totalCash) : "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewTargetCashCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewTargetCashCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
        reviewStatus
      }
    }
  `,
};

CondensedTableNewTargetCashCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_TARGET_CASH
);
CondensedTableNewTargetCashCell2.id = ColumnIds.NEW_TARGET_CASH;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTargetCashCell2,
  Header: CondensedTableNewTargetCashCell2.Header,
  id: CondensedTableNewTargetCashCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableNewTargetCashCell2.column = column;
CondensedTableNewTargetCashCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
  workingHoursPerYear,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
  workingHoursPerYear: number | undefined;
}) =>
  contramap((e: Participant) => {
    const totalCashAfterRecommendation =
      getTotalCashAfterRecommendation(
        defaultCurrencyCode,
        e.subject.activeCashCompensation,
        e.compRecommendation?.latestSubmittedItems ?? [],
        workingHoursPerYear
      )?.annualTotal ?? zero(defaultCurrencyCode);

    const currentCurrency = currencies.get(
      totalCashAfterRecommendation.currency
    );
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(
      totalCashAfterRecommendation,
      currentCurrency,
      defaultCurrency
    ).value;
  });
