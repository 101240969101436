import { makeStyles, Typography } from "@material-ui/core";
import {
  CompCycleData,
  CompCycleDataChangeHandler,
} from "src/components/CompCycle/CompCycleWizard/types";
import Section from "src/components/Workflow/Section";
import { GRAY_6, WHITE, WARN_TEXT } from "src/theme";
import SequentialApprovalsIcon from "../../../assets/svgs/illustrations/sequential-approvals.svg?react";
import { ApprovalsAndPhases } from "./ApprovalsAndPhases";
import { CompCycleConfigurationButtonBar } from "./CompCycleConfigurationButtonBar";

const useStyles = makeStyles((theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  contentInner: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  card: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: theme.spacing(2),
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonBarUpdate: {
    justifyContent: "flex-end",
  },
  buttonBarNextContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(2),
  },
  disclaimer: {
    color: WARN_TEXT,
  },
}));

type Props = {
  handleChange: CompCycleDataChangeHandler;
  hierarchyView: boolean;
  onHierarchyView: () => unknown;
  onConfigureHome: () => unknown;
  wizardData: CompCycleData;
  updateStep: (step: number) => unknown;
};

export function CompCycleHierarchyConfiguration({
  handleChange,
  onHierarchyView,
  onConfigureHome,
  hierarchyView,
  wizardData,
  updateStep,
}: Props): JSX.Element {
  const classes = useStyles();

  return hierarchyView ? (
    <ApprovalsAndPhases
      onConfigureHome={onConfigureHome}
      wizardData={wizardData}
      handleChange={handleChange}
      updateStep={updateStep}
    />
  ) : (
    <div className={classes.content}>
      <Section
        active={true}
        header="Configure the sequence and cadence of approvals."
        description={
          <>
            We'll help you set up phases with the right management layers so
            your cycle is gated to the appropriate people at the appropriate
            time.
          </>
        }
      >
        <Typography variant="body2" className={classes.disclaimer}>
          Note: Phases and approval settings can't be edited mid-cycle.
        </Typography>
        <div className={classes.contentInner}>
          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <SequentialApprovalsIcon />
              </div>
            </div>
          </div>
          <CompCycleConfigurationButtonBar
            onHierarchyView={onHierarchyView}
            updateStep={updateStep}
            handleChange={handleChange}
            onConfigureHome={onConfigureHome}
          />
        </div>
      </Section>
    </div>
  );
}
