import { gql } from "@apollo/client";
import { CashBandName } from "@asmbl/shared/constants";
import {
  add,
  formatCurrency,
  money,
  multiply,
  zero,
} from "@asmbl/shared/money";
import { TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import { formatNumber } from "accounting";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CashCompType,
  CompComponent,
  CompUnit,
  RecItemInput,
  RecItemType,
  TargetIncreaseRow_employee,
  TargetIncreaseRow_position,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { ADJUSTED_CASH_BAND_FIELDS } from "../../../../fragments";
import { isBandPointDefined } from "../../../../models/BandPoint";
import {
  getCashRecItem,
  getDraftSalaryIncrease,
} from "../../../../models/CompRecommendation";
import { GRAY_6 } from "../../../../theme";
import { ArrayValue } from "../../../../utils";
import { CondensedBandVisualization } from "../../../CompSlider/CondensedBandVisualization";
import { NullCompSlider } from "../../../CompSlider/NullCompSlider";
import { useCurrencies } from "../../../CurrenciesContext";
import {
  ConfigurableSalaryInputCell,
  InputOption,
} from "../../Cells/ConfigurableTargetInputCell";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

const useStyles = makeStyles(() => ({
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  sliderCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
  },
  noteContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type CashCompensation = ArrayValue<
  TargetIncreaseRow_employee["activeCashCompensation"]
>;
interface Props extends CompCycleLineItemRowProps {
  employee: TargetIncreaseRow_employee;
  label: string;
  recommendationType: RecItemType;
  promotedPosition: TargetIncreaseRow_position | null;
  component: CompComponent;
  cashCompType: CashCompType;
}

export function TargetIncreaseRow({
  employee,
  label,
  promotedPosition,
  revisedRecommendation,
  submittedRecommendation,
  recommendationType,
  cashCompType,
  onChangeRecommendationItem,
  component,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  // look for a draft item first, then check for a saved item
  const currentSalary =
    employee.activeEmployment?.salary ?? employee.activeEmployment?.payRate;
  const targetIncreaseItem =
    revisedRecommendation?.items.get(recommendationType) ??
    submittedRecommendation?.items.get(recommendationType);
  const salaryIncrease = getDraftSalaryIncrease(
    revisedRecommendation,
    currentSalary?.currency ?? defaultCurrencyCode,
    compStructure?.workingHoursPerYear ?? 0
  );
  const newSalary =
    currentSalary != null
      ? add(currentSalary, salaryIncrease.annualCashEquivalent)
      : currentSalary;

  const cashValue =
    targetIncreaseItem != null && newSalary != null
      ? getCashRecItem(targetIncreaseItem, newSalary).recommendedCashValue
      : zero(currentSalary?.currency ?? defaultCurrencyCode);

  const percentValue =
    targetIncreaseItem?.recommendedPercentValue != null
      ? targetIncreaseItem.recommendedPercentValue
      : cashValue && newSalary && newSalary.value !== 0
        ? (100 * cashValue.value) / newSalary.value
        : 0;

  const alternateValueLabel =
    targetIncreaseItem?.unitType !== CompUnit.PERCENT_OF_SALARY
      ? `${formatNumber(percentValue, 2)}%`
      : cashValue != null
        ? formatCurrency(cashValue, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : "";

  const { Track } = useTrack({
    compensationComponent: component,
    employeeId: employee.id,
  });
  const recItemStyle = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();
  const classes = useStyles();

  const adjustedCashBands = promotedPosition
    ? promotedPosition.adjustedCashBands
    : employee.adjustedCashBands;

  const adjustedTargetBand = adjustedCashBands?.find(
    (b) => b.name === CashBandName[cashCompType]
  );

  const submittedItem = submittedRecommendation?.items.get(recommendationType);
  const revisedItem = revisedRecommendation?.items.get(recommendationType);

  const targetCashComp = employee.activeCashCompensation?.find(
    (c) => c.type === cashCompType
  );

  const draftCompUnit = targetIncreaseItem?.unitType ?? CompUnit.CASH;

  const defaultInputOption =
    draftCompUnit === CompUnit.PERCENT_OF_SALARY
      ? InputOption.PERCENT_OF_SALARY
      : InputOption.CASH_VALUE;

  // Use the recommended cashValue or calculate the cash based on the recommendedPercent
  function getInputValue() {
    if (draftCompUnit === CompUnit.CASH) {
      return targetIncreaseItem?.recommendedCashValue;
    }
    if (newSalary == null) {
      return undefined;
    }
    if (targetIncreaseItem?.recommendedPercentValue == null) {
      return undefined;
    }
    const newCashValue = multiply(
      newSalary,
      targetIncreaseItem.recommendedPercentValue / 100
    );
    // Only permit whole number cash recommendations
    return money(Math.round(newCashValue.value), newCashValue.currency);
  }

  const inputValue = getInputValue();

  return (
    <Track>
      <TableRow aria-level={3} className={recItemStyle.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <TableCell
          role="gridcell"
          colSpan={5}
          padding="none"
          className={recItemStyle.textCell}
        >
          <div className={classes.labelContainer}>
            <Typography className={recItemStyle.rowLabel}>{label}</Typography>
            <Typography className={recItemStyle.extraRowText} component="span">
              {formatCurrentTarget(targetCashComp)}
            </Typography>
          </div>
        </TableCell>
        <ConfigurableSalaryInputCell
          value={inputValue ?? null}
          baseValue={newSalary ?? null}
          payCurrencyCode={currentSalary?.currency ?? null}
          onChange={(cashValue, percentValue, option) => {
            onChangeRecommendationItem({
              recommendationType: recommendationType,
              recommendedCashValue:
                option === InputOption.CASH_VALUE ? cashValue : undefined,
              recommendedPercentValue:
                option === InputOption.PERCENT_OF_SALARY
                  ? percentValue
                  : undefined,
              note: targetIncreaseItem?.note,
              unitType:
                option === InputOption.PERCENT_OF_SALARY
                  ? CompUnit.PERCENT_OF_SALARY
                  : CompUnit.CASH,
            });
          }}
          inputOptions={[
            { label: "% of New Salary", value: InputOption.PERCENT_OF_SALARY },
            { label: "Cash Value", value: InputOption.CASH_VALUE },
          ]}
          defaultInputOption={defaultInputOption}
          hasUnpublishedChanges={hasUnpublishedChanges(
            submittedItem,
            revisedItem
          )}
        />

        <TableCell role="gridcell" className={classes.sliderCell}>
          {adjustedTargetBand &&
          adjustedTargetBand.bandPoints.some(isBandPointDefined) ? (
            <CondensedBandVisualization
              value={inputValue ?? zero(defaultCurrencyCode)}
              bandPoints={adjustedTargetBand.bandPoints}
              position={
                promotedPosition ?? employee.activeEmployment?.position ?? null
              }
              size="wide"
              outOfRangeStyle="band"
              valueLabel={label}
              showHourly={false}
            />
          ) : (
            <NullCompSlider variant="condensedWide" />
          )}
        </TableCell>
        <TableCell
          role="gridcell"
          className={recItemStyle.noteTextCell}
          colSpan={2}
        >
          <div className={classes.noteContainer}>
            <Typography className={recItemStyle.extraRowText} component="span">
              {alternateValueLabel}
            </Typography>
            <CompCycleLineItemRowButton
              disabled={
                targetIncreaseItem?.recommendedCashValue === undefined &&
                targetIncreaseItem?.recommendedPercentValue === undefined
              }
              employee={employee}
              note={targetIncreaseItem?.note ?? null}
              onClick={(note: string) =>
                onChangeRecommendationItem({
                  ...targetIncreaseItem,
                  recommendationType,
                  note,
                })
              }
            />
          </div>
        </TableCell>
      </TableRow>
    </Track>
  );
}

TargetIncreaseRow.fragments = {
  employee: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment TargetIncreaseRow_employee on Employee2 {
      id
      displayName
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
        salary
        payPeriod
        payRate
        payCurrencyCode
        position {
          id
          name
          level
          type
          ladder {
            id
            name
            department {
              id
              name
            }
          }
        }
      }
      activeCashCompensation(currencyCode: $currencyCode) {
        activeAt
        type
        annualCashEquivalent
        hourlyCashEquivalent
        percentOfSalary
        unit
        employeeId
      }
      adjustedCashBands {
        id
        ...AdjustedCashBandFields
      }
    }
  `,

  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment TargetIncreaseRow_position on Position {
      id
      name
      level
      type
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
};

function hasUnpublishedChanges(
  submittedItem: RecItemInput | undefined,
  revisedItem: RecItemInput | undefined
): boolean {
  return revisedItem?.unitType === CompUnit.CASH
    ? revisedItem.recommendedCashValue?.value !==
        submittedItem?.recommendedCashValue?.value
    : revisedItem?.recommendedPercentValue !==
        submittedItem?.recommendedPercentValue;
}

function formatCurrentTarget(targetCash: CashCompensation | undefined): string {
  if (targetCash === undefined) return "";

  const cash = formatCurrency(targetCash.annualCashEquivalent, {
    maximumFractionDigits: 0,
  });
  const percent = formatNumber(targetCash.percentOfSalary, 2);
  return `Current target: ${cash} / ${percent}%`;
}
