import { gql } from "@apollo/client";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { isZero, Money, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import {
  CondensedTableSalaryIncreaseCell2_participant as Participant,
  CurrencyCode,
} from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryIncreaseCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const salaryIncrease: Money =
    employee.compRecommendation?.latestSubmittedSalaryIncrease ??
    zero(
      employee.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
    );
  return (
    <AssembleTruncatedTypography align="right">
      {isZero(salaryIncrease) ? "-" : getSimpleCashLabel(salaryIncrease)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryIncreaseCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryIncreaseCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedSalaryIncrease(currencyCode: $currencyCode)
      }
    }
  `,
};

CondensedTableSalaryIncreaseCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_DOLLAR_CHANGE
);
CondensedTableSalaryIncreaseCell2.id = ColumnIds.SALARY_DOLLAR_CHANGE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryIncreaseCell2,
  Header: CondensedTableSalaryIncreaseCell2.Header,
  id: CondensedTableSalaryIncreaseCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableSalaryIncreaseCell2.column = column;
CondensedTableSalaryIncreaseCell2.ordering = ({
  defaultCurrency,
  currencies,
}: {
  defaultCurrency: Currency;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const latestSalaryIncrease =
      e.compRecommendation?.latestSubmittedSalaryIncrease ??
      zero(defaultCurrency.code);

    const currentCurrency = currencies.get(latestSalaryIncrease.currency);

    if (currentCurrency == null) {
      return 0;
    }

    return exchangeFromTo(
      latestSalaryIncrease,
      currentCurrency,
      defaultCurrency
    ).value;
  });
