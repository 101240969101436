import { useCallback, useState } from "react";
import { mapTree, TreeNode } from "../../models/JobStructure";
import { TreeRow } from "./TreeRow";

interface TreeSelectProps {
  node: TreeNode;
  onChange: (node: TreeNode) => unknown;
  indentation: { isLastChild: boolean }[];
  defaultExpand?: boolean;
}

export function TreeSelect({
  node,
  onChange,
  indentation,
  defaultExpand = false,
}: TreeSelectProps): JSX.Element {
  const { data, selected, indeterminate, children, category, hint } = node;
  const [isCollapsed, setCollapsed] = useState<boolean>(!defaultExpand);

  const onNodeToggle = useCallback(() => {
    if (!selected || indeterminate) {
      onChange(
        mapTree(node, (n) => ({ ...n, selected: true, indeterminate: false }))
      );
    } else {
      onChange(
        mapTree(node, (n) => ({ ...n, selected: false, indeterminate: false }))
      );
    }
  }, [indeterminate, node, onChange, selected]);

  const onChildToggle = useCallback(
    (child: TreeNode) => {
      if (children === undefined) {
        return;
      }
      const newChildren = children.map((c) =>
        c.data === child.data ? child : c
      );
      onChange({
        data,
        category,
        hint,
        selected: newChildren.some((child) => child.selected),
        inherited: false,
        indeterminate:
          newChildren.some((child) => child.indeterminate) ||
          (newChildren.some((child) => child.selected) &&
            newChildren.some((child) => !child.selected)),
        children: newChildren,
      });
    },
    [children, data, category, hint, onChange]
  );

  const onCollapseExpand = useCallback(
    () => setCollapsed((isCollapsed) => !isCollapsed),
    []
  );

  return (
    <>
      <TreeRow
        isCollapsible={children !== undefined}
        collapsed={isCollapsed}
        indentation={indentation}
        selected={selected}
        indeterminate={indeterminate}
        onClick={onNodeToggle}
        onCollapseExpand={onCollapseExpand}
        label={data.name}
        category={category}
        hint={hint}
      />
      {!isCollapsed &&
        children?.map((child, c) => {
          const childIndentation = indentation.concat([
            { isLastChild: c === children.length - 1 },
          ]);
          return (
            <TreeSelect
              key={child.data.id}
              indentation={childIndentation}
              node={child}
              onChange={onChildToggle}
              defaultExpand={child.indeterminate}
            />
          );
        })}
    </>
  );
}
