import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { contramap } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { GRAY_4 } from "src/theme";
import {
  CompRecommendationStatus,
  CondensedTableCurrentPositionCell2_participant as Participant,
  PayPeriodType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getPositionName } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  rowActedOn: {
    "& path": {
      stroke: `${GRAY_4} !important`,
    },
  },
  icon: {
    flex: "none",
  },
}));

export function CondensedTableCurrentPositionCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const label = employee.subject.activeEmployment?.position?.name ?? "-";
  const { compStructure } = useCompStructure();
  const classes = useStyles();
  const { isEnabled } = useFeatureFlags();

  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);
  const payPeriodType = employee.subject.activeEmployment?.payPeriod;

  const reviewStatus = employee.compRecommendation?.reviewStatus ?? null;
  const actionNeededStatuses = [
    CompRecommendationStatus.FUTURE_PHASE,
    CompRecommendationStatus.NEEDS_REVIEW,
    CompRecommendationStatus.LATE,
  ];
  const rowActedOn = Boolean(
    reviewStatus && !actionNeededStatuses.includes(reviewStatus)
  );

  return (
    <div className={clsx(classes.root, rowActedOn && classes.rowActedOn)}>
      {showHourly && label !== "-" && (
        <>
          {payPeriodType === PayPeriodType.ANNUAL && (
            <CalendarIcon height="12px" width="12px" className={classes.icon} />
          )}
          {payPeriodType === PayPeriodType.HOURLY && (
            <ClockIcon height="12px" width="12px" className={classes.icon} />
          )}
        </>
      )}
      <AssembleTruncatedTypography lines={1} title={label}>
        {label}
      </AssembleTruncatedTypography>
    </div>
  );
}

CondensedTableCurrentPositionCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentPositionCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          payPeriod
          position {
            id
            name
            type
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        reviewStatus
      }
    }
  `,
};

CondensedTableCurrentPositionCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_POSITION
);
CondensedTableCurrentPositionCell2.id = ColumnIds.CURRENT_POSITION;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentPositionCell2,
  Header: CondensedTableCurrentPositionCell2.Header,
  id: CondensedTableCurrentPositionCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableCurrentPositionCell2.column = column;
CondensedTableCurrentPositionCell2.ordering = () =>
  contramap((e: Participant) => getPositionName(e.subject) ?? "");
