import { gql } from "@apollo/client";
import {
  ActualVariablePayRows_compCycle as CompCycle,
  CurrencyCode,
  ActualVariablePayRows_employee as Employee,
  RecItemInput,
} from "../../../__generated__/graphql";
import { CompRecommendation } from "../../../models/CompRecommendation";
import { CompCycleLineItemRowButton } from "./CompCycleLineItemRowButton";
import { ActualTargetPayRow } from "./LineItemRows/ActualTargetPay";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";
import { OneTimeBonusRow } from "./LineItemRows/OneTimeBonus";

type Props = {
  show: boolean;
  employee: Employee;
  compCycle: CompCycle;
  submittedRecommendation: CompRecommendation | null;
  revisedRecommendation: CompRecommendation | null;
  handleNewRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};

export function ActualVariablePayRows({
  show,
  employee,
  compCycle,
  submittedRecommendation,
  revisedRecommendation,
  handleNewRecommendationItem,
}: Props): JSX.Element {
  const payCurrencyCode = employee.activeEmployment
    ?.payCurrencyCode as CurrencyCode | null;
  return (
    <>
      <HeaderSeparatorRow text="Actual Variable Pay" filtered={!show} first />
      {show && compCycle.allowActualRecurringBonus && (
        <ActualTargetPayRow
          employee={employee}
          currency={payCurrencyCode}
          label="Actual Recurring Bonus"
          revisedRecommendation={revisedRecommendation}
          submittedRecommendation={submittedRecommendation}
          onChangeRecommendationItem={handleNewRecommendationItem}
        />
      )}
      {show && compCycle.allowBonus && (
        <OneTimeBonusRow
          employee={employee}
          currency={payCurrencyCode}
          revisedRecommendation={revisedRecommendation}
          submittedRecommendation={submittedRecommendation}
          onChangeRecommendationItem={handleNewRecommendationItem}
        />
      )}
    </>
  );
}

ActualVariablePayRows.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    ${ActualTargetPayRow.fragments.employee}
    fragment ActualVariablePayRows_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      ...ActualTargetPayRow_employee
      id
      activeEmployment {
        id
        salary
        payCurrencyCode
      }
    }
  `,
  compCycle: gql`
    fragment ActualVariablePayRows_compCycle on CompCycle2 {
      id
      allowBonus
      allowActualRecurringBonus
    }
  `,
};
