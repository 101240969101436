import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeMoney } from "@asmbl/shared/currency";
import { ratio, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import {
  getMeritBonus,
  getPercentChangeBonusToSalary,
} from "../../../../models/CompRecommendation";
import { CondensedTableBonusPercentCell2_participant as Participant } from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableBonusPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { currencies, defaultCurrencyCode } = useCurrencies();
  const salary =
    employee.subject.activeEmployment?.salary ?? zero(defaultCurrencyCode);
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  return (
    <AssembleTruncatedTypography align="right">
      {getPercentChangeBonusToSalary(recItems, salary, currencies)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableBonusPercentCell2.ordering = ({
  currencies,
  defaultCurrency,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrency: Currency;
}) =>
  contramap((employee: Participant) => {
    const items = employee.compRecommendation?.latestSubmittedItems ?? [];
    const currentSalary =
      employee.subject.activeEmployment?.salary ?? zero(defaultCurrency.code);
    const bonusIncreaseCash = getMeritBonus(items, defaultCurrency.code);

    const exchangedBonusIncrease = exchangeMoney(
      bonusIncreaseCash,
      currentSalary.currency,
      { allCurrencies: currencies }
    );

    return currentSalary.value !== 0
      ? ratio(exchangedBonusIncrease, currentSalary) * 100
      : 0;
  });

CondensedTableBonusPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableBonusPercentCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableBonusPercentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.BONUS_PERCENT
);
CondensedTableBonusPercentCell2.id = ColumnIds.BONUS_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableBonusPercentCell2,
  Header: CondensedTableBonusPercentCell2.Header,
  id: CondensedTableBonusPercentCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableBonusPercentCell2.column = column;
