import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CondensedTableSalaryMeritPercentCell2_participant as Participant,
  CurrencyCode,
} from "src/__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { ColumnComponent2 } from "../CondensedTableInner2";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const percent = calculatePercent(
    employee.subject.activeEmployment?.salary,
    getMeritSalaryIncrease(
      recItems,
      employee.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
    ).value
  );

  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(percent / 100, {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryMeritPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritPercentCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableSalaryMeritPercentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_PERCENT
);
CondensedTableSalaryMeritPercentCell2.id = ColumnIds.SALARY_MERIT_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritPercentCell2,
  Header: CondensedTableSalaryMeritPercentCell2.Header,
  id: CondensedTableSalaryMeritPercentCell2.id,
};

CondensedTableSalaryMeritPercentCell2.column = column;
CondensedTableSalaryMeritPercentCell2.ordering = ({
  defaultCurrencyCode,
}: {
  defaultCurrencyCode: CurrencyCode;
}) =>
  contramap((e: Participant) =>
    calculatePercent(
      e.subject.activeEmployment?.salary,
      getMeritSalaryIncrease(
        e.compRecommendation?.latestSubmittedItems ?? [],
        e.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
      ).value
    )
  );

function calculatePercent(
  baseSalary?: Money | null,
  absolute?: number
): number {
  if (baseSalary == null || absolute == null) return 0;
  return baseSalary.value !== 0
    ? Math.round((absolute / baseSalary.value) * 10000) / 100
    : 0;
}
