import { gql } from "@apollo/client";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { CircleCheckIcon } from "src/components/AssembleIcons/Small/CircleCheckIcon";
import { useAuth } from "src/components/Auth/AuthContext";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { useURLSearchParams } from "src/models/URLSearchParams";
import {
  ApproveButton2_participant as Participant,
  RecommendationReviewStatus,
} from "../../../../../__generated__/graphql";
import { useSubmitRecReviews } from "../../../../../mutations/CompRecommendation";
import { DV_GREEN, PURPLE_1 } from "../../../../../theme";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "flex",
    padding: theme.spacing(0.25),
  },
  loadingSpinner: {
    paddingTop: "4px",
    "& > div": {
      height: "16px !important",
      width: "16px !important",
    },
  },
  hidden: {
    visibility: "hidden",
  },
  disabledButton: {
    opacity: 0.3,
  },
}));

type Props = {
  employee: Participant;
  compCycleId: number;
  disabled?: boolean;
  recIsApproved?: boolean;
  hidden?: boolean;
  hideTooltip?: boolean;
};

export function ApproveButton2({
  employee,
  compCycleId,
  recIsApproved,
  hidden = false,
  disabled = false,
  hideTooltip = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const intercom = useIntercom();
  const { trackEvent } = useTrack();
  const { permissions } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const submitRecReview = useSubmitRecReviews();
  const [loading, setLoading] = useState(false);

  const managerId = urlSearchParams.get("manager");

  return (
    <>
      {recIsApproved === true ? (
        <Tooltip
          title="Accepted"
          placement="top"
          arrow
          disableHoverListener={hideTooltip}
          enterDelay={500}
        >
          <div
            className={clsx(classes.iconContainer, {
              [classes.hidden]: hidden,
            })}
            id="review-approved-icon"
            data-cy-visibility={`${hidden ? "hidden" : "visible"}`}
          >
            <CircleCheckIcon color={DV_GREEN} width="16px" height="23px" />
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          title="Accept Request"
          placement="top"
          arrow
          disableHoverListener={hideTooltip}
          enterDelay={500}
        >
          <span className={clsx({ [classes.hidden]: hidden })}>
            <IconButton
              data-cy-visibility={`${hidden ? "hidden" : "visible"}`}
              id="review-approval-icon"
              size="small"
              disabled={disabled}
              className={clsx({ [classes.disabledButton]: disabled })}
              onClick={async () => {
                setLoading(true);
                // if we are an HRBP then we will be submitting the request
                // on behalf of the manager we are filtering under
                await submitRecReview({
                  data: [
                    {
                      subjectId: employee.subjectId,
                      compCycleId,
                      status: RecommendationReviewStatus.APPROVED,
                    },
                  ],
                  compCycleId,
                  actingManagerEmployeeId:
                    managerId != null && permissions.isHRBP()
                      ? Number.parseInt(managerId)
                      : null,
                });
                setLoading(false);
                trackEvent({
                  object: "Review Requests",
                  subArea: "Approve Button",
                  action: "Submitted",
                });
                intercom.trackEvent("Comp Review Accepted");
              }}
            >
              {loading ? (
                <LoadingSpinner className={classes.loadingSpinner} />
              ) : (
                <CircleCheckIcon color={PURPLE_1} width="16px" height="23px" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
}

ApproveButton2.fragments = {
  participant: gql`
    fragment ApproveButton2_participant on CompCycleParticipant {
      subjectId
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
      }
    }
  `,
};
