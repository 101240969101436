import { CashBandName, CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeCurrency } from "@asmbl/shared/currency";
import {
  Money,
  add,
  formatCurrency,
  money,
  moneyComparator,
  zero,
} from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  AdjustedCashBandFields,
  AdjustedCashBandPointFields,
  AdjustedEquityBandPointFields,
} from "../__generated__/graphql";
import {
  BandPoint,
  LargeCompSliderAdjustedBandPoint,
} from "../components/CompSlider/LargeCompSlider";
import { CashCompensation } from "./CashCompensation";

export type AdjustedBandPoint =
  | Omit<AdjustedCashBandPointFields, "__typename">
  | Omit<AdjustedEquityBandPointFields, "__typename">;

/** AdjustedBand APIs always return the full set of active Bands and BandPoints
 * defined in the CompStructure, even if the user has not explicitly defined a
 * value for them. Checking whether the value is defined involves discriminating
 * the union of all possible value types.
 *
 * Returns true if the BandPoint has a defined value (i.e. is not null), even if
 * that value is $0, 0 units, or 0%. Returns false if the value is null.
 */
export function isBandPointDefined(
  point: Pick<AdjustedBandPoint, "value">
): boolean {
  switch (point.value.__typename) {
    case "CashValue":
      return point.value.annualRate !== null;
    case "UnitValue":
      return point.value.unitValue !== null;
    case "PercentValue":
      return point.value.decimalValue !== null;
  }
}

export function formatBandPoints(
  currency: Currency<CurrencyCode>,
  bandPoints: BandPoint[]
): LargeCompSliderAdjustedBandPoint[] {
  return bandPoints.slice().map(({ name, value }) => {
    return {
      name,
      value: exchangeCurrency(money(value, currency.code), currency),
      currencyCode: currency.code,
    };
  });
}

export function sortBandPoints(bandPoints: LargeCompSliderAdjustedBandPoint[]) {
  return bandPoints
    .slice()
    .sort((bandPointA, bandPointB) =>
      moneyComparator(
        bandPointA.value ?? zero(bandPointA.currencyCode),
        bandPointB.value ?? zero(bandPointB.currencyCode)
      )
    );
}

export function getMinAndMaxBandPoints(
  bandPoint: LargeCompSliderAdjustedBandPoint[]
) {
  const minBandPoint = bandPoint[0];
  const maxBandPoint = bandPoint[bandPoint.length - 1];

  return [
    minBandPoint.value ?? zero(minBandPoint.currencyCode),
    maxBandPoint.value ?? zero(maxBandPoint.currencyCode),
  ];
}

/**
 * Our API should prevent bands from containing a mixture of units, but we
 * protect against it on the client anyways. If we don't, we may show incorrect
 * data, which errodes customer trust. Better to show an error message.
 */
export function isBandUnitConsistent(
  referencePoint: Omit<
    AdjustedBandPoint,
    "annualCashEquivalent" | "hourlyCashEquivalent"
  >,
  bandPoints: Omit<
    AdjustedBandPoint,
    "annualCashEquivalent" | "hourlyCashEquivalent"
  >[]
): boolean {
  return bandPoints.every(
    (bandPoint) =>
      bandPoint.value.__typename === referencePoint.value.__typename &&
      // If cash, ensure the currency is the same
      (referencePoint.value.__typename !== "CashValue" ||
        bandPoint.value.__typename !== "CashValue" ||
        referencePoint.value.currencyCode === bandPoint.value.currencyCode)
  );
}

/*
 * Retrieves the raw numeric value stored in a Band Point. BE VERY CAREFUL!
 * This is intended to be used internally in calculations like band penetration
 * and sorting comparators, where we have already ensured the units all match.
 */
function toUnitlessRawValue(point: Pick<AdjustedBandPoint, "value">): number {
  switch (point.value.__typename) {
    case "CashValue":
      return point.value.annualRate?.value ?? 0;
    case "UnitValue":
      return point.value.unitValue ?? 0;
    case "PercentValue":
      return point.value.decimalValue ?? 0;
  }
}

/**
 * Returns the band range penetration of a point on a band. This metric
 * converts a point on a band from an absolute value to a fractional offset,
 * where 0 is the bottom of the band and 1 is the top of band. If the points
 * cannot be compared (e.g. different units), the result is undefined.
 *
 * For bands with a single band point (or where all band points are the same),
 * a point exactly on the band is always 1 (100%), as there is no space for the
 * point to further grow in the band.
 *
 * Points outside the band translate into penetration <0 or >1. Results are
 * guaranteed to be consistent for a single band (e.g. smaller values are more
 * negative) but comparing out-of-band penetration values from different bands
 * is not recommended, as the size of the band has a large impact on the value.
 *
 * This function's arguments are split to facilitate easy mapping over multiple
 * data points.
 * @see https://www.hibob.com/blog/salary-range-penetration-metric/
 */
export const getBandPenetration =
  (band: Omit<AdjustedBandPoint, "annualCashEquivalent">[]) =>
  (
    point: Omit<AdjustedBandPoint, "annualCashEquivalent">
  ): number | undefined => {
    // The band is presumed to be pre-sorted
    const [min, max] = [band.at(0), band.at(-1)];
    if (!min || !max || !isBandUnitConsistent(point, band)) {
      return undefined;
    }

    const [minRaw, pointRaw, maxRaw] = [min, point, max].map(
      toUnitlessRawValue
    );

    let precise = 0;

    // Special case the single-point, to avoid dividing by 0.
    if (minRaw === maxRaw) {
      precise = pointRaw >= maxRaw ? pointRaw - minRaw + 1 : pointRaw - maxRaw;
    } else {
      precise = (pointRaw - minRaw) / (maxRaw - minRaw);
    }

    // We don't care to be more precise than a few decimals, to avoid floating
    // point errors.
    return Math.round(precise * 1000) / 1000;
  };

/**
 * Returns the compa-ratio of a point on a band. The compa-ratio compares
 * the point to the mid-point of the band.
 *
 * @returns The compa-ratio, where 1.0 represents the midpoint of the band.
 */
export const getCompaRatio =
  (
    band: Omit<
      AdjustedBandPoint,
      "annualCashEquivalent" | "hourlyCashEquivalent"
    >[]
  ) =>
  (
    point: Omit<
      AdjustedBandPoint,
      "annualCashEquivalent" | "hourlyCashEquivalent"
    >
  ): number | undefined => {
    // The band is presumed to be pre-sorted
    const [min, max] = [band.at(0), band.at(-1)];
    if (!min || !max || !isBandUnitConsistent(point, band)) {
      return undefined;
    }

    const [minRaw, pointRaw, maxRaw] = [min, point, max].map(
      toUnitlessRawValue
    );

    const midRaw = (minRaw + maxRaw) / 2;
    if (midRaw === 0) {
      return undefined;
    }

    const precise = pointRaw / midRaw;

    // We don't care to be more precise than a few decimals, to avoid floating
    // point errors.
    return Math.round(precise * 1000) / 1000;
  };

export function formatPointValue(
  point: AdjustedBandPoint,
  isHourly = false
): string {
  switch (point.value.__typename) {
    case "CashValue":
      return formatCurrency(
        (isHourly ? point.hourlyCashEquivalent : point.value.annualRate) ??
          zero(point.value.currencyCode),
        {
          notation: "compact",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }
      );
    case "UnitValue":
      return `${formatNumeral(point.value.unitValue ?? 0, {
        notation: "compact",
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })} units`;
    case "PercentValue":
      return formatNumeral(point.value.decimalValue ?? 0, {
        style: "percent",
        minimumSignificantDigits: 2,
        maximumSignificantDigits: 4,
      });
  }
}

export function annualizedCashCompToBandPoint(
  comp: CashCompensation
): AdjustedCashBandPointFields {
  return {
    id: "",
    __typename: "AdjustedCashBandPoint",
    name: CashBandName[comp.type], // This must be defined, but is not relevant
    bandName: CashBandName[comp.type],
    value: {
      __typename: "CashValue",
      annualRate: comp.annualCashEquivalent,
      hourlyRate: comp.hourlyCashEquivalent,
      currencyCode: comp.annualCashEquivalent.currency,
    },
    annualCashEquivalent: comp.annualCashEquivalent,
    hourlyCashEquivalent: comp.hourlyCashEquivalent,
  };
}

// this function combines all of the bandPoint values for a given band
// and returns it in a format the NewCompSlider will accept
export function getBandPointTotalValues(
  bandPointNames: string[],
  bands: AdjustedCashBandFields[] | null,
  defaultCurrency: CurrencyCode
): AdjustedCashBandPointFields[] {
  return bandPointNames
    .map((name) => {
      const relatedBands =
        bands?.flatMap((band) =>
          band.bandPoints.filter(
            (bandPoint: AdjustedCashBandPointFields & { name: string }) =>
              bandPoint.name === name
          )
        ) ?? [];

      const annualValue: Money = relatedBands
        .map((bp) => bp.value.annualRate ?? zero(defaultCurrency))
        .reduce(add, zero(defaultCurrency));
      const hourlyValue: Money = relatedBands
        .map((bp) => bp.value.hourlyRate ?? zero(defaultCurrency))
        .reduce(add, zero(defaultCurrency));
      return {
        ...relatedBands[0],
        name,
        value: {
          annualRate: annualValue,
          hourlyRate: hourlyValue,
          currencyCode: defaultCurrency,
          __typename: "CashValue" as const, // we need this field since some of the Band functions compare the typenames
        },
        annualCashEquivalent: annualValue,
      };
    })
    .sort(contramap((bp) => bp.annualCashEquivalent, moneyComparator));
}

export function getBandPointCashEquivalent(
  bandPoint: {
    value: {
      annualRate: Money | null;
      hourlyRate: Money | null;
    };
  },
  defaultCurrencyCode: CurrencyCode,
  isHourly: boolean
): Money {
  return (
    (isHourly ? bandPoint.value.hourlyRate : bandPoint.value.annualRate) ??
    zero(defaultCurrencyCode)
  );
}
