import { CurrencyCode } from "@asmbl/shared/constants";
import { makeStyles } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { ExplanatoryTooltip } from "../../../components/ExplanatoryTooltip";
import { AutocompleteSelect } from "../../../components/Form/AutocompleteSelect";
import { currencyName } from "../../../models/Currency";
import { GRAY_4 } from "../../../theme";

const useStyles = makeStyles(() => ({
  localOption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "0.5rem",
  },
}));

export type CurrencyCodeWithAll = CurrencyCode | "all";

type Props = {
  selectedCurrency: CurrencyCodeWithAll;
  onChangeSelectedCurrency: (currency: CurrencyCodeWithAll) => void;
};

export function CondensedTableCurrencyPicker({
  selectedCurrency,
  onChangeSelectedCurrency,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currenciesList, defaultCurrencyCode } = useCurrencies();

  const options = [
    { label: "Local Currency", value: "all" },
    ...currenciesList
      .map((currency) => ({
        value: currency.code,
        label: `${currencyName(currency.code)} (${currency.code})`,
      }))
      .sort((a, b) => {
        // setting the organization's default currency to the top of the list
        // (behind the `Local Currency` option)
        if (a.value === defaultCurrencyCode) {
          return -1;
        }

        if (b.value === defaultCurrencyCode) {
          return 1;
        }

        return a.label.localeCompare(b.label);
      }),
  ];

  return (
    <AutocompleteSelect
      size="small"
      onChange={(value: string) =>
        onChangeSelectedCurrency(value as CurrencyCodeWithAll)
      }
      value={selectedCurrency}
      options={options}
      renderOption={(option) => {
        return option.value === "all" ? (
          <span>
            <ExplanatoryTooltip
              title="Local Currency"
              body="The local currency is the currency specific
                to each employee."
              placement="top"
            >
              <div className={classes.localOption}>
                {option.label}
                <InfoIcon color={GRAY_4} width="12px" height="12px" />
              </div>
            </ExplanatoryTooltip>
          </span>
        ) : (
          <span>{option.label}</span>
        );
      }}
    />
  );
}
