import {
  formatCurrency,
  liftMoney,
  map,
  Money,
  totalGrossEquityValue,
  unitsOfTotalGrossValue,
  zero,
} from "@asmbl/shared/money";
import { CompCycleGrouping } from "../components/CompCycle/types";

/* Budgets are broken down into comp components (Salary, Equity, Bonus) and each
 * comp component is optionally divided into further subcomponents (e.g.) Merit,
 * Market, Promo.
 */

export type CompComponent =
  | "salary"
  | "equity"
  | "bonus"
  | "targetCommission"
  | "targetRecurringBonus"
  | "actualRecurringBonus";

export type CompSubComponent =
  | "salaryMarket"
  | "salaryMerit"
  | "salaryPromotion";

export const isCompSubComponent = (input: string): input is CompSubComponent =>
  ["salaryMarket", "salaryMerit", "salaryPromotion"].includes(input);

export enum CompCycleSettings {
  SALARY = "allowSalary",
  MARKET = "allowSalaryMarket",
  MERIT = "allowSalaryMerit",
  PROMOTION = "allowSalaryPromotion",
  BONUS = "allowBonus",
  EQUITY = "allowEquity",
  TARGET_COMMISSION = "allowTargetCommission",
  TARGET_RECURRING_BONUS = "allowTargetRecurringBonus",
  ACTUAL_RECURRING_BONUS = "allowActualRecurringBonus",
}

export enum AllocationUnit {
  CASH = "cash",
  UNIT = "unit",
}

export const CompComponentDisplay: Record<CompComponent, string> = {
  salary: "Total Salary Adjustments",
  equity: "Total New Equity",
  bonus: "Total One-Time Bonus",
  targetCommission: "Total Target Commission",
  targetRecurringBonus: "Total Target Recurring Bonus",
  actualRecurringBonus: "Total Actual Recurring Bonus",
};

export const CompSubComponentDisplay: Record<CompSubComponent, string> = {
  salaryMarket: "Market Adjustment",
  salaryMerit: "Merit Adjustment",
  salaryPromotion: "Promotion Adjustment",
};

export enum BudgetType {
  SALARY = "Salary Adjustments",
  EQUITY = "Equity Grants",
  ONE_TIME_BONUS = "One-Time Bonus",
  MARKET = "Market Adjustments",
  MERIT = "Merit Adjustments",
  PROMOTION = "Promotion Adjustments",
  TOTAL_BUDGET = "Total Comp Budget",
  TOTAL_SALARY = "Total Salary Adjustments",
  ACTUAL_RECURRING_BONUS = "Actual Recurring Bonus",
  TARGET_RECURRING_BONUS = "Target Recurring Bonus",
  TARGET_COMMISSION = "Target Commission",
  TARGET_VAR_PAY = "Target Variable Pay",
  ACTUAL_VAR_PAY = "Actual Variable Pay",
}

/**
 * A shared type to aid in allocating budgets, which can be unset or a Money
 * value. Equity will also be allocated in Units, hence 'type'.
 */
export type Allocation =
  | { unit: AllocationUnit.CASH; value: Money | null }
  | { unit: AllocationUnit.UNIT; value: number | null };

/**
 * Takes an operation on numbers and 'lifts' it to operate on Allocations.
 * This is borrowed from Applicatives in functional programming.
 *
 * @param fn An operation on numbers, such as `(a, b) => a + b`
 * @returns A function that performs the same operation on Allocation objects.
 *   If both Allocations are null, it returns null. Otherwise, it defaults to
 *   the 'zero' value of the allocation (e.g. O USD for cash).
 */
export const liftAllocation =
  (
    fn: (a: number, b: number) => number
  ): ((a: Allocation, b: Allocation) => Allocation) =>
  (a, b) => {
    if (a.unit !== b.unit) {
      throw new Error(
        `Mismatched Allocation units. Cannot compare ${a.unit} and ${b.unit}`
      );
    }

    if (a.value === null && b.value === null) {
      return { unit: a.unit, value: null };
    }

    if (a.unit === AllocationUnit.UNIT && b.unit === AllocationUnit.UNIT) {
      return {
        unit: AllocationUnit.UNIT,
        value: fn(a.value ?? 0, b.value ?? 0),
      };
    }

    if (a.unit === AllocationUnit.CASH && b.unit === AllocationUnit.CASH) {
      const fallbackCurrency = a.value?.currency ?? b.value?.currency;
      if (!fallbackCurrency) {
        // This should never happen, as we check nulls above.
        // ...but Typescript doesn't seem to understand that.
        throw new Error("Cannot liftAllocation with null currency");
      }
      const moneyFunc = liftMoney(fn);
      return {
        unit: AllocationUnit.CASH,
        value: moneyFunc(
          a.value ?? zero(fallbackCurrency),
          b.value ?? zero(fallbackCurrency)
        ),
      };
    }
    return { unit: a.unit, value: null };
  };

/**
 * A BudgetAllocation represents a single budget 'row' in our Allocation table.
 * It aims to define a generic shape so that we can operate on Salary, Equity,
 * Bonus, and any other comp components without copying a lot of code.
 */

export type BudgetAllocation = {
  compCycleId: number;
  employeeId: number | null;
  allocations: (Allocation & {
    compComponent: CompComponent | CompSubComponent;
  })[];
};

/**
 * Takes an operation on numbers and 'lifts' it to operate on BudgetAllocations.
 * This is borrowed from Applicatives in functional programming.
 *
 * When applying the operation to BudgetAllocations, it applies it individually
 * to the totalAllocation and to each subcomponent.
 *
 * @param fn An operation on numbers, such as `(a, b) => a + b`
 * @returns A function that performs the same operation on BudgetAllocations.
 *   It applies the operation to the total and each subcomponent, deferring to
 *   liftAllocation for each one.
 */
export const liftBudget =
  (
    fn: (a: number, b: number) => number
  ): ((a: BudgetAllocation, b: BudgetAllocation) => BudgetAllocation) =>
  (a, b) => {
    const apply = liftAllocation(fn);

    return {
      ...a,
      allocations: a.allocations.map((aAllocation, index) => ({
        compComponent: aAllocation.compComponent,
        ...apply(aAllocation, b.allocations[index]),
      })),
    };
  };

export function budgetAllocationFactory({
  compComponent,
  compCycle,
  equityDisplay,
  valuation,
}: {
  compComponent: Exclude<CompCycleGrouping, "all">;
  compCycle: {
    allowSalaryMarket: boolean;
    allowSalaryMerit: boolean;
    allowSalaryPromotion: boolean;
    allowBonus: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
    allowActualRecurringBonus: boolean;
  };
  equityDisplay: AllocationUnit;
  valuation: { fdso: number; valuation: Money };
}): (budget: {
  compCycleId: number;
  employeeId: number | null;
  salary: Money | null;
  salaryMarket: Money | null;
  salaryMerit: Money | null;
  salaryPromotion: Money | null;
  equity: Money | null;
  bonus: Money | null;
  targetCommission: Money | null;
  targetRecurringBonus: Money | null;
  actualRecurringBonus: Money | null;
}) => BudgetAllocation {
  switch (compComponent) {
    case "salary":
      return salaryBudgetAllocation(compCycle);
    case "equity":
      return equityDisplay === AllocationUnit.CASH
        ? equityBudgetAllocation
        : equityUnitBudgetAllocation(valuation.fdso, valuation.valuation);
    case "actual":
      return actualPayAllocation(compCycle);
    case "target":
      return targetVariablePayAllocation(compCycle);
  }
}

function salaryBudgetAllocation(compCycle: {
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
}) {
  return (budget: {
    compCycleId: number;
    employeeId: number | null;
    salary: Money | null;
    salaryMarket: Money | null;
    salaryMerit: Money | null;
    salaryPromotion: Money | null;
  }): BudgetAllocation => {
    const children: [boolean, CompSubComponent][] = [
      [compCycle.allowSalaryMarket, "salaryMarket"],
      [compCycle.allowSalaryPromotion, "salaryPromotion"],
      [compCycle.allowSalaryMerit, "salaryMerit"],
    ];

    const subComponents = children
      .filter(([predicate]) => predicate)
      .map(([, subcomponent]) => ({
        compComponent: subcomponent,
        unit: AllocationUnit.CASH as const,
        value: budget[subcomponent],
      }));

    return {
      compCycleId: budget.compCycleId,
      employeeId: budget.employeeId,
      allocations: [
        {
          compComponent: "salary",
          unit: AllocationUnit.CASH,
          value: budget.salary,
        },
        ...subComponents,
      ],
    };
  };
}

function equityBudgetAllocation(budget: {
  compCycleId: number;
  employeeId: number | null;
  equity: Money | null;
}): BudgetAllocation {
  return {
    compCycleId: budget.compCycleId,
    employeeId: budget.employeeId,
    allocations: [
      {
        compComponent: "equity",
        unit: AllocationUnit.CASH,
        value: budget.equity,
      },
    ],
  };
}

function equityUnitBudgetAllocation(
  fdso: number,
  valuation: Money
): (budget: {
  compCycleId: number;
  employeeId: number | null;
  equity: Money | null;
}) => BudgetAllocation {
  return (budget) => {
    return {
      compCycleId: budget.compCycleId,
      employeeId: budget.employeeId,
      allocations: [
        {
          compComponent: "equity",
          unit: AllocationUnit.UNIT,
          value:
            budget.equity &&
            Math.round(unitsOfTotalGrossValue(fdso, valuation, budget.equity)),
        },
      ],
    };
  };
}

function actualPayAllocation(compCycle: {
  allowBonus: boolean;
  allowActualRecurringBonus: boolean;
}) {
  return (budget: {
    compCycleId: number;
    employeeId: number | null;
    actualRecurringBonus: Money | null;
    bonus: Money | null;
  }): BudgetAllocation => {
    type ActualComponent = "actualRecurringBonus" | "bonus";

    const variableComponents: [boolean, ActualComponent][] = [
      [compCycle.allowActualRecurringBonus, "actualRecurringBonus"],
      [compCycle.allowBonus, "bonus"],
    ];

    const allocations = variableComponents
      .filter(([predicate]) => predicate)
      .map(([, component]) => ({
        compComponent: component,
        unit: AllocationUnit.CASH as const,
        value: budget[component],
      }));

    return {
      compCycleId: budget.compCycleId,
      employeeId: budget.employeeId,
      allocations,
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function targetVariablePayAllocation(compCycle: {
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
}) {
  return (budget: {
    compCycleId: number;
    employeeId: number | null;
    targetCommission: Money | null;
    targetRecurringBonus: Money | null;
  }): BudgetAllocation => {
    type VariableComponent = "targetCommission" | "targetRecurringBonus";

    const variableComponents: [boolean, VariableComponent][] = [
      [compCycle.allowTargetCommission, "targetCommission"],
      [compCycle.allowTargetRecurringBonus, "targetRecurringBonus"],
    ];

    const allocations = variableComponents
      .filter(([predicate]) => predicate)
      .map(([, component]) => ({
        compComponent: component,
        unit: AllocationUnit.CASH as const,
        value: budget[component],
      }));

    return {
      compCycleId: budget.compCycleId,
      employeeId: budget.employeeId,
      allocations,
    };
  };
}

export function convertAllocationToCash(
  input: Allocation,
  valuation: { fdso: number; valuation: Money }
): Money | null {
  if (input.value === null) {
    return null;
  }
  if (input.unit === AllocationUnit.CASH) {
    return input.value;
  } else {
    return map(
      Math.round,
      totalGrossEquityValue(valuation.fdso, valuation.valuation, input.value)
    );
  }
}

/* This drops the unit, and should only be used when a raw number is needed */
export function allocationToNumber(allocation: Allocation): number | null {
  return (
    (allocation.unit === AllocationUnit.CASH
      ? allocation.value?.value
      : allocation.value) ?? null
  );
}
export function formatBudget(budget: Money | null): string {
  return budget === null
    ? "-"
    : formatCurrency(budget, { maximumFractionDigits: 0 });
}
