import { gql, useQuery } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { contramap } from "@asmbl/shared/sort";
import { groupByKey } from "@asmbl/shared/utils";
import { useParams } from "react-router-dom";
import { useAuth } from "src/components/Auth/AuthContext";
import { ErrorView } from "src/components/ErrorView";
import { buildOrgTree, getAllReports } from "src/models/FilterParams";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useCompleteCompCycle } from "../../../mutations/CompCycle";
import {
  CompCycleHomeBoundaryQuery,
  CompCycleHomeBoundaryQueryVariables,
  CompCycleHomeBoundary_participant,
  CompCycleReviewRequests_employee,
} from "../../../__generated__/graphql";
import { CompCycleReviewRequests } from "./CompCycleReviewRequests";

export type CompCycleManager = CompCycleReviewRequests_employee & {
  reports: CompCycleHomeBoundary_participant[];
};
export function CompCycleHomeBoundary(): JSX.Element {
  const { permissions } = useAuth();
  const canCompleteCompCycle = permissions.canEditGlobal(Noun.CompCycle);
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { data, loading } = useQuery<
    CompCycleHomeBoundaryQuery,
    CompCycleHomeBoundaryQueryVariables
  >(CompCycleHomeBoundary.query, {
    variables: {
      compCycleId: cycleId,
    },
    skip: isNaN(cycleId),
  });

  const completeCompCycle = useCompleteCompCycle(cycleId);

  if (!data?.compCycle) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="This comp cycle was deleted or does not exist. If you think something went wrong, please contact us for support." />
    );
  }

  const managerToReportsMap = groupByKey(
    data.compCycle.participants.employees,
    "manager"
  );

  const orgTree = buildOrgTree(data.compCycle.participants.employees);

  const managersArray: CompCycleManager[] = [];
  for (const [manager] of managerToReportsMap) {
    // Don't show employees without a manager on this page.
    if (manager == null) continue;

    // If there are phase assignments and the current user is not full access,
    // filter out managers who are not in the current phase
    if (
      !canCompleteCompCycle &&
      data.compCycle.currentPhase !== null &&
      data.compCycle.currentPhase.compCyclePhaseAssignments.length > 0 &&
      data.compCycle.currentPhase.compCyclePhaseAssignments.every(
        (a) => a.assigneeId !== manager.id
      )
    ) {
      continue;
    }

    managersArray.push({
      ...manager,
      reports: getAllReports(orgTree, manager.id),
    });
  }

  const sortedManagers = managersArray.sort(contramap((m) => m.displayName));

  return (
    <CompCycleReviewRequests
      authors={sortedManagers}
      compCycle={data.compCycle}
      onComplete={completeCompCycle}
    />
  );
}

CompCycleHomeBoundary.fragments = {
  participant: gql`
    ${CompCycleReviewRequests.fragments.manager}
    fragment CompCycleHomeBoundary_participant on Employee {
      id
      manager {
        ...CompCycleReviewRequests_employee
      }
      compRecommendation(compCycleId: $compCycleId) {
        subjectId
        compCycleId
        reviewStatus
        userRelativeCompRecStatus
        latestSubmittedItems {
          id
          submittedAt
          author {
            id
            name
          }
        }
        latestSubmittedReviews {
          id
          author {
            id
          }
        }
      }
    }
  `,
};

CompCycleHomeBoundary.query = gql`
  ${CompCycleReviewRequests.fragments.compCycle}
  ${CompCycleHomeBoundary.fragments.participant}
  query CompCycleHomeBoundaryQuery($compCycleId: Int!) {
    compCycle(id: $compCycleId) {
      id
      participants {
        employees {
          ...CompCycleHomeBoundary_participant
        }
      }
      currentPhase {
        id
        compCyclePhaseAssignments {
          id
          phaseId
          status
          assigneeId
        }
      }
      ...CompCycleReviewRequests_compCycle
    }
  }
`;
