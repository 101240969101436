import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableNewDepartmentCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { findRecommendedPosition } from "../../../../models/CompRecommendation";
import { getDepartmentName } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableNewDepartmentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { availablePositions } = useTableData();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const newPosition = findRecommendedPosition(recItems, availablePositions);
  const newDepartment = newPosition?.ladder.department.name;
  const oldDepartment =
    employee.subject.activeEmployment?.position?.ladder.department.name;
  const label = newDepartment ?? oldDepartment ?? "-";

  return (
    <AssembleTruncatedTypography lines={2} title={label}>
      {label}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewDepartmentCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewDepartmentCell2_participant on CompCycleParticipant {
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
        }
      }
      subject {
        activeEmployment {
          id
          position {
            id
            ladder {
              id
              department {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableNewDepartmentCell_position on Position {
      id
      ladder {
        id
        department {
          id
          name
        }
      }
    }
  `,
};

CondensedTableNewDepartmentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_DEPARTMENT
);
CondensedTableNewDepartmentCell2.id = ColumnIds.NEW_DEPARTMENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewDepartmentCell2,
  Header: CondensedTableNewDepartmentCell2.Header,
  id: CondensedTableNewDepartmentCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableNewDepartmentCell2.column = column;
CondensedTableNewDepartmentCell2.ordering = () =>
  contramap((e: Participant) => getDepartmentName(e.subject) ?? "");
