import { gql } from "@apollo/client";
import { makeStyles, Toolbar, Typography } from "@material-ui/core";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import {
  CompCycleBudgetBar2_compCycle,
  CompCycleBudgetBar2_compCycleBudget,
  CompCycleBudgetBar2_valuation,
} from "../../../__generated__/graphql";
import { GRAY_4, GRAY_6, GRAY_7, WHITE } from "../../../theme";
import { CompCycleGrouping } from "../types";
import { BudgetBarComponents2 } from "./BudgetBarComponents2";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: "5.75rem",
    backgroundColor: WHITE,
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    width: "100%",
    minWidth: "1220px", // minWidth of the app + intercom chat
  },
  "@media(max-width: 80rem)": {
    toolbar: {
      paddingRight: theme.spacing(13),
    },
  },
  "@media(max-width: 100rem)": {
    toolbar: {
      height: "6.75rem",
    },
  },
  budgetNameContainer: {
    backgroundColor: GRAY_7,
    borderRadius: "5px",
    margin: theme.spacing(0, 2, 0, 1.5),
    padding: theme.spacing(1.25, 2, 0, 2),
    height: "3.75rem",
    minWidth: "6.25rem",
  },
  totalBudgetText: {
    color: GRAY_4,
    fontSize: "1.125rem",
    letterSpacing: "-0.25px",
    lineHeight: "110%",
    fontWeight: 500,
  },
}));

type Props = {
  recommendationItems: RecItemWithSubject[];
  compCycle: CompCycleBudgetBar2_compCycle;
  budget: CompCycleBudgetBar2_compCycleBudget | null;
  valuation: CompCycleBudgetBar2_valuation;
  filter: CompCycleGrouping;
  prefixText?: string;
};

export const CompCycleBudgetBar2 = ({
  recommendationItems,
  compCycle,
  budget,
  valuation,
  filter,
  prefixText,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Toolbar disableGutters className={classes.toolbar}>
      <div className={classes.budgetNameContainer}>
        {prefixText !== undefined ? (
          <>
            <Typography variant="h6" className={classes.totalBudgetText}>
              {prefixText}
            </Typography>
            <Typography variant="h6" className={classes.totalBudgetText}>
              {`${getBudgetName(filter)} Budget`}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" className={classes.totalBudgetText}>
              {getBudgetName(filter)}
            </Typography>
            <Typography variant="h6" className={classes.totalBudgetText}>
              Budget
            </Typography>
          </>
        )}
      </div>
      <BudgetBarComponents2
        filter={filter}
        recommendationItems={recommendationItems}
        compCycle={compCycle}
        budget={budget}
        valuation={valuation}
      />
    </Toolbar>
  );
};

const getBudgetName = (filter: CompCycleGrouping): string => {
  const map: Record<CompCycleGrouping, string> = {
    all: "",
    salary: "Salary",
    equity: "Equity",
    target: "Variable",
    actual: "Actuals",
  };
  return map[filter];
};

CompCycleBudgetBar2.fragments = {
  compCycle: gql`
    ${BudgetBarComponents2.fragments.compCycle}
    fragment CompCycleBudgetBar2_compCycle on CompCycle2 {
      ...BudgetBarComponents2_compCycle
    }
  `,
  compCycleBudget: gql`
    ${BudgetBarComponents2.fragments.compCycleBudget}
    fragment CompCycleBudgetBar2_compCycleBudget on CompCycleBudget {
      ...BudgetBarComponents2_compCycleBudget
    }
  `,
  valuation: gql`
    ${BudgetBarComponents2.fragments.valuation}
    fragment CompCycleBudgetBar2_valuation on Valuation {
      ...BudgetBarComponents2_valuation
    }
  `,
};
