import { ButtonBase, makeStyles } from "@material-ui/core";
import {
  GRAY_1,
  GRAY_2,
  GRAY_5,
  GRAY_6,
  GRAY_7,
  PURPLE_1,
  WHITE,
} from "../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    background: WHITE,
    border: `1px solid ${GRAY_5}`,
    borderRadius: "5px",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    maxHeight: "3rem",
    minHeight: "2rem",
    height: "100%",
    width: "fit-content",
    display: "flex",
  },
  option: {
    // paddingBlock is implied by the height of the parent
    paddingInline: theme.spacing(1),
    height: "100%",
    display: "flex",
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.6875rem",
    letterSpacing: "-0.25px",
    fontWeight: 500,
    lineHeight: 1.7,
    textTransform: "uppercase",
    color: GRAY_2,
    borderRight: `1px solid ${GRAY_6}`,

    "&:first-child": {
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    "&:last-child": {
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      borderRight: "none",
    },

    "&:hover, &:focus": {
      color: PURPLE_1,
    },
  },
  selected: {
    background: GRAY_7,
    color: GRAY_1,
  },
}));

type Props<T> = {
  options: [T, string | JSX.Element][];
  selected: T | null;
  onChange: (value: T) => unknown;
};

/**
 * ComplexToggle represents a set of "radio push buttons", where only one can be
 * selected at a time. It is commonly used in table headers, and matches the
 * designs of the 'complex toggle' in the design system.
 */
export function ComplexToggle<T extends string | number | JSX.Element>({
  options,
  selected,
  onChange,
}: Props<T>): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root} id="complex-toggle">
      {options.map(([value, label], index) => (
        <ButtonBase
          key={index}
          aria-selected={selected === value}
          className={`
            ${classes.option}
            ${selected === value ? classes.selected : ""}
          `}
          onClick={() => onChange(value)}
        >
          {label}
        </ButtonBase>
      ))}
    </div>
  );
}
