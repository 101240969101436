import { Money, moneyComparator, zero } from "@asmbl/shared/money";
import { Paper, makeStyles } from "@material-ui/core";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import {
  EquityRefreshCondensedSlider_equityBand,
  EquityRefreshCondensedSlider_position,
} from "../../../__generated__/graphql";
import { GRAY_2, GRAY_4, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { LargeCompSlider } from "../LargeCompSlider";

const useStyles = makeStyles(() => ({
  paper: {
    background: WHITE,
    borderRadius: "10px",
    width: "100%",
    height: "fit-content",
  },
  title: {
    width: "100%",
    color: GRAY_2,
    marginBottom: "1rem",
  },
  mainSlider: {
    marginBottom: "2rem",
  },
  positionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  positionTitle: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    gap: "4px",
  },
}));

type Props = {
  cashEquivalent?: Money | null;
  band?: EquityRefreshCondensedSlider_equityBand | null;
  position?: EquityRefreshCondensedSlider_position | null;
  isHourly: boolean;
};

export function EquityRefreshPopover({
  cashEquivalent,
  band,
  position,
  isHourly,
}: Props): JSX.Element {
  const classes = useStyles();
  if (band == null) return <>No equity information</>;

  const sortedBandPoints = band.bandPoints
    .slice(0)
    .sort((a, b) =>
      moneyComparator(a.annualCashEquivalent, b.annualCashEquivalent)
    );

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.title}>
        <AssembleTypography variant="h6">Equity Value</AssembleTypography>
      </div>
      <div className={classes.mainSlider}>
        {position !== undefined && (
          <div className={classes.positionContainer}>
            <div className={classes.positionTitle}>
              {isHourly ? (
                <ClockIcon color={GRAY_4} />
              ) : (
                <CalendarIcon color={GRAY_4} />
              )}
              <AssembleTypography variant="productEyebrow" textColor={GRAY_4}>
                {position?.name}
              </AssembleTypography>
            </div>
            {position?.level == null ? null : (
              <AssembleTypography
                variant="productExtraSmall"
                textColor={GRAY_4}
              >
                {`Level ${position.level}`}
              </AssembleTypography>
            )}
          </div>
        )}
        <LargeCompSlider
          value={
            cashEquivalent ??
            zero(sortedBandPoints[0].annualCashEquivalent.currency)
          }
          valueLabel="Equity Value"
          locationAdjustedBandPoints={sortedBandPoints.map((bandPoint) => ({
            name: bandPoint.bandName,
            value: bandPoint.annualCashEquivalent,
            currencyCode:
              cashEquivalent?.currency ??
              bandPoint.annualCashEquivalent.currency,
          }))}
        />
      </div>
    </Paper>
  );
}
