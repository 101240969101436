import { useParams } from "react-router-dom";
import { useTrack } from "../../../analytics";
import { ManagerSelfRedirectLoadingBoundary } from "../Navigation/ManagerSelfRedirectLoadingBoundary";
import { CondensedTableLoadingBoundary } from "./CondensedTableLoadingBoundary";

export function CondensedReviewPage(): JSX.Element | null {
  const { compCycleId: compCycleIdStr } = useParams<{ compCycleId: string }>();
  const compCycleId = compCycleIdStr != null ? parseInt(compCycleIdStr) : NaN;

  const { Track } = useTrack({
    subArea: "Condensed Table View",
    compCycleId,
  });

  if (isNaN(compCycleId)) {
    return null;
  }

  return (
    <Track>
      <ManagerSelfRedirectLoadingBoundary compCycleId={compCycleId}>
        <CondensedTableLoadingBoundary compCycleId={compCycleId} />
      </ManagerSelfRedirectLoadingBoundary>
    </Track>
  );
}
