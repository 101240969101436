import { createContext, ReactNode, useContext, useState } from "react";
import { ColumnIds } from "./ColumnOrderContext";

type PaginationType = {
  currentPage: number;
  pageSize: number;
  setCurrentPage: (page: number) => void;
  totalCount: number;
  setTotalCount: (count: number) => void;
  setPageSize: (size: number) => void;
  sortBy: string;
  sortDir: "asc" | "desc";
  setSortBy: (property: string) => void;
  setSortDir: (direction: "asc" | "desc") => void;
};

export const PaginationContext = createContext<PaginationType>({
  currentPage: 0,
  pageSize: 0,
  setCurrentPage: (_page: number) => {
    // empty default
  },
  totalCount: 0,
  setTotalCount: (_count: number) => {
    // empty default
  },
  setPageSize: (_size: number) => {
    // empty default
  },
  sortBy: ColumnIds.NAME,
  sortDir: "asc",
  setSortBy: (_property: string) => {
    // empty default
  },
  setSortDir: (_direction: "asc" | "desc") => {
    // empty default
  },
});

export const PaginationProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortBy, setSortBy] = useState<string>(ColumnIds.NAME);
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  return (
    <PaginationContext.Provider
      value={{
        pageSize,
        currentPage,
        setCurrentPage,
        totalCount,
        setTotalCount,
        setPageSize,
        sortBy,
        setSortBy,
        sortDir,
        setSortDir,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = (): PaginationType => {
  return useContext(PaginationContext);
};
