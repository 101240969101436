import { gql, useQuery } from "@apollo/client";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { ApprovalsOrganizationChartLoadingBoundary } from "src/components/CompCycle/Approvals/ApprovalsOrganizationChartLoadingBoundary";
import MeritGuidanceLoadingBoundary from "src/components/CompCycle/MeritGuidance";
import CompCyclePerformanceRatings from "src/components/CompCycle/PerformanceRatings/CompCyclePerformanceRatings";
import { CompCycleNoPhases } from "src/components/EmptyState/EmptyState";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CompCycleSettingsQuery,
  CompCycleSettingsQueryVariables,
} from "../../../__generated__/graphql";
import { CompCycleSettingsClose } from "../../../components/CompCycle/CloseCompCycle/CompCycleSettingsClose";
import { CompCycleUploadRequestsLoadingBoundary } from "../../../components/CompCycle/UploadRequests/CompCycleUploadRequestsLoadingBoundary";
import { ErrorView } from "../../../components/ErrorView";
import { useUpdateCompCycleName } from "../../../mutations/CompCycle";
import { EligibilityEditorLoadingBoundary } from "../Eligibility/EligibilityEditorLoadingBoundary";
import { PaginationProvider } from "../Plan/Contexts/PaginationContext";
import { CompCycleSettings } from "./CompCycleSettings";
import { CompCycleSettingsSidebar } from "./CompCycleSettingsSidebar";

const useStyles = makeStyles((theme) => ({
  fullWidthContent: {
    marginLeft: "248px",
    padding: theme.spacing(6, 5),
  },
  centeredContent: {
    padding: theme.spacing(6, 5),
  },
}));

export const CompCycleSettingsLoadingBoundary = (): JSX.Element => {
  const classes = useStyles();
  const { isEnabled } = useFeatureFlags();
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;
  const { data, loading: budgetLoading } = useQuery<
    CompCycleSettingsQuery,
    CompCycleSettingsQueryVariables
  >(CompCycleSettingsLoadingBoundary.query, {
    variables: { compCycleId: cycleId },
    skip: isNaN(cycleId),
  });
  const updateName = useUpdateCompCycleName(cycleId);

  if (!data) {
    if (budgetLoading) {
      return <LinearProgress />;
    }
    return <ErrorView text="Unable to retrieve data." />;
  }

  if (!data.valuation || !data.compCycle) {
    return <ErrorView text="Unable to load comp cycle data." />;
  }

  return (
    <>
      <CompCycleSettingsSidebar compCycleId={data.compCycle.id} />
      <Routes>
        <Route index element={<Navigate to="general" replace />} />
        <Route
          path="general"
          element={
            <div className={classes.centeredContent}>
              <CompCycleSettings
                compCycle={data.compCycle}
                updateName={updateName}
                budget={data.compCycleBudgetDraft}
                valuation={data.valuation}
              />
            </div>
          }
        />
        <Route
          path="performance"
          element={
            <div className={classes.centeredContent}>
              <CompCyclePerformanceRatings
                mode="update"
                compCycleId={data.compCycle.id}
              />
            </div>
          }
        />
        <Route
          path="eligibility"
          element={
            <div className={classes.fullWidthContent}>
              <PaginationProvider>
                <EligibilityEditorLoadingBoundary
                  compCycleId={data.compCycle.id}
                />
              </PaginationProvider>
            </div>
          }
        />
        <Route
          path="guidance"
          element={
            <div className={classes.centeredContent}>
              <MeritGuidanceLoadingBoundary compCycleId={data.compCycle.id} />
            </div>
          }
        />
        <Route
          path="requests"
          element={
            <div className={classes.centeredContent}>
              <CompCycleUploadRequestsLoadingBoundary
                mode="update"
                compCycleId={cycleId}
                compCycleData={{
                  compComponents: {
                    ...data.compCycle,
                  },
                }}
              />
            </div>
          }
        />
        <Route
          path="approvals"
          element={
            data.compCycle.phases.length === 0 ? (
              <CompCycleNoPhases />
            ) : (
              <div className={classes.centeredContent}>
                <ApprovalsOrganizationChartLoadingBoundary
                  compCycleId={data.compCycle.id}
                />
              </div>
            )
          }
        />
        <Route
          path="close"
          element={
            <div className={classes.centeredContent}>
              <CompCycleSettingsClose compCycle={data.compCycle} />
            </div>
          }
        />
      </Routes>
    </>
  );
};

CompCycleSettingsLoadingBoundary.query = gql`
  ${CompCycleSettings.fragments.budget}
  ${CompCycleSettings.fragments.compCycle}
  ${CompCycleSettings.fragments.valuation}
  ${CompCycleSettingsClose.fragments.compCycle}
  query CompCycleSettingsQuery($compCycleId: Int!) {
    compCycleBudgetDraft(compCycleId: $compCycleId) {
      compCycleId
      employeeId
      ...CompCycleSettings_budget
    }
    compCycle(id: $compCycleId) {
      id
      phases {
        id
      }
      ...CompCycleSettingsCloseCompCycle_compCycle
      ...CompCycleSettings_compCycle
    }
    valuation {
      id
      ...CompCycleSettings_valuation
    }
  }
`;
