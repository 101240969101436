import { gql } from "@apollo/client";
import { makeStyles, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { CompCycleSingleRecSubHeader_compCycle as CompCycle } from "../../../__generated__/graphql";
import { getToggleOptions } from "../../../models/CompCycle";
import { withMinWait } from "../../../utils";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { ComplexToggle } from "../../ComplexToggle";
import { CompCycleGrouping } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
  },
  title: {
    alignItems: "center",
    flex: 1,
    fontWeight: 500,
  },
  saveAndCancel: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    columnGap: theme.spacing(2),
  },
  toggleOptions: {
    height: "2rem",
  },
}));

type Props = {
  isSubmittingAndAccepting: boolean;
  disableSaveButton: boolean;
  compCycle: CompCycle;
  filter: CompCycleGrouping;
  handleFilterChange: (filter: CompCycleGrouping) => void;
  handleCancel: () => void;
  handleSave: () => Promise<boolean>;
};

export const CompCycleSingleRecSubHeader = ({
  isSubmittingAndAccepting,
  disableSaveButton,
  compCycle,
  filter,
  handleFilterChange,
  handleCancel,
  handleSave,
}: Props): JSX.Element => {
  const classes = useStyles();
  const intercom = useIntercom();

  const { enqueueSnackbar } = useSnackbar();

  const [modalState, setModalState] = useState<
    "closed" | "open" | "pending" | "success" | "error"
  >("closed");

  const onExit = () => {
    setModalState("closed");
    handleCancel();
  };

  const onSave = async () => {
    setModalState("open");
    const result = await withMinWait(handleSave, 500);
    intercom.trackEvent("Comp Request Saved");
    setModalState(result ? "success" : "error");
    onExit();
    enqueueSnackbar(
      modalState === "error"
        ? "Something went wrong. Please try again"
        : "Your recommendation has been saved.",
      {
        variant: modalState === "error" ? "error" : "success",
        action: <></>,
      }
    );
  };

  const toggleOptions = getToggleOptions(compCycle, {
    includeTheAllOption: true,
  });

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        You're editing a comp change request
      </Typography>

      {toggleOptions.length > 0 && (
        <div className={classes.toggleOptions}>
          <ComplexToggle
            options={toggleOptions}
            selected={filter}
            onChange={handleFilterChange}
          />
        </div>
      )}
      <div className={classes.saveAndCancel}>
        <AssembleButton
          variant="outlined"
          onClick={handleCancel}
          size="small"
          label="Cancel"
          data-intercom-target="cancel-request"
        />
        <AssembleButton
          variant="contained"
          label={
            isSubmittingAndAccepting && compCycle.phases.length > 0
              ? "Submit and Accept Request"
              : "Submit Request"
          }
          data-intercom-target="save-request"
          size="small"
          onClick={onSave}
          disabled={disableSaveButton || modalState === "open"}
          data-cy="submit-request-button"
        />
      </div>
    </div>
  );
};

CompCycleSingleRecSubHeader.fragments = {
  compCycle: gql`
    fragment CompCycleSingleRecSubHeader_compCycle on CompCycle2 {
      id
      allowSalary
      allowSalaryMerit
      allowSalaryMarket
      allowSalaryPromotion
      allowBonus
      allowEquity
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
      phases {
        id
      }
    }
  `,
};
