import { formatCurrency, Money, zero } from "@asmbl/shared/money";
import { makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import { CurrencyCode } from "../../__generated__/graphql";
import { getMinAndMaxBandPoints } from "../../models/BandPoint";
import {
  DV_GREEN,
  DV_ORANGE,
  DV_PINK,
  DV_YELLOW,
  GRAY_1,
  GRAY_3,
  GRAY_9,
  RED,
  WHITE,
} from "../../theme";

const useStyles = makeStyles<Theme, { height?: string }>(() => ({
  container: {
    position: "relative",
    width: "100%",
  },
  band: {
    width: "100%",
    height: ({ height }) => height ?? "25px",
    background: `no-repeat linear-gradient(270deg, ${DV_PINK} 0%, ${DV_ORANGE} 78.13%, ${DV_YELLOW} 100%)`,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  dot: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    backgroundColor: WHITE,
    border: `1px solid ${DV_ORANGE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    left: "0px",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "$outOfRange &": {
      border: `1px solid ${WHITE}`,
    },
    "$belowRange &": {
      background: DV_GREEN,
    },
    "$aboveRange &": {
      background: RED,
    },
  },
  bandPoint: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    border: `1px solid ${WHITE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  outOfRange: {},
  belowRange: {},
  aboveRange: {},
  line: {
    background: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
  },
  label: {
    color: GRAY_3,
    fontSize: "10px",
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    position: "absolute",
    top: "calc(100% + 5px)",
    width: "100%",
  },
  minValueLabel: {
    color: GRAY_1,
    fontSize: "12px",
    position: "absolute",
    top: "calc(100% + 2px)",
    left: 0,
  },
  maxLabel: {
    color: GRAY_1,
    fontSize: "12px",
    position: "absolute",
    top: "calc(100% + 2px)",
    right: 0,
  },
}));

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
export type BandPoint = { name: string; value: number };
export type LargeCompSliderAdjustedBandPoint = {
  name: string;
  value: Money | null;
  currencyCode: CurrencyCode;
};

export type Props = {
  locationAdjustedBandPoints: LargeCompSliderAdjustedBandPoint[];
  value: Money | null;
  height?: string;
  valueLabel?: string;
  isHourly?: boolean;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
export const LargeCompSlider = memo(function LargeCompSlider({
  locationAdjustedBandPoints: bandPoints,
  value: moneyValue,
  height,
  valueLabel = "Salary",
  isHourly = false,
}: Props) {
  const classes = useStyles({ height });

  const value = moneyValue?.value ?? null;
  const [min, max] = getMinAndMaxBandPoints(bandPoints);
  const isBelowRange = value != null ? value < min.value : false;
  const isAboveRange = value != null ? value > max.value : false;
  const isOutOfRange = isBelowRange || isAboveRange;

  const getOffset = (value: number): string => {
    if (value === min.value && value === max.value) {
      return "50%";
    }
    const offset = ((value - min.value) / (max.value - min.value)) * 100;
    if (offset > 100) return "calc(100% + 12px)";
    if (offset < 0) return "-12px";
    return `${offset}%`;
  };

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.band, {
          [classes.outOfRange]: isOutOfRange,
          [classes.belowRange]: isBelowRange,
          [classes.aboveRange]: isAboveRange,
        })}
      >
        {moneyValue != null && value != null && (
          <Tooltip
            placement="top"
            title={`${valueLabel} · ${formatMoney(moneyValue)}${
              isHourly ? "/hr" : ""
            }`}
          >
            <div className={classes.line} style={{ left: getOffset(value) }}>
              <div className={classes.dot} />
            </div>
          </Tooltip>
        )}
        {
          // Display additional band points apart from the min and max
          bandPoints
            .slice(1, bandPoints.length - 1)
            .map(({ name, value: money, currencyCode }) => {
              return (
                <Tooltip
                  key={name}
                  placement="top"
                  title={`${name} · ${formatMoney(
                    money ?? zero(currencyCode)
                  )}`}
                >
                  <div
                    style={{ left: getOffset(money?.value ?? 0) }}
                    className={classes.bandPoint}
                  />
                </Tooltip>
              );
            })
        }
      </div>
      <Typography
        variant="caption"
        className={classes.minValueLabel}
        component="div"
      >
        {formatMoney(min)}
      </Typography>
      <Typography
        variant="caption"
        className={classes.maxLabel}
        component="div"
      >
        {formatMoney(max)}
      </Typography>
      {isOutOfRange && (
        <Typography variant="caption" className={classes.label} component="div">
          {isBelowRange ? "Below Band" : "Above Band"}
        </Typography>
      )}
    </div>
  );
});

function formatMoney(money: Money): string {
  return formatCurrency(money, {
    notation: "standard",
    maximumSignificantDigits: 5,
  });
}
