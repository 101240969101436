import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { isZero, ratio, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getPercentChangeInSalary } from "../../../../models/CompRecommendation";
import { CondensedTableSalaryPercentChangeCell2_participant as Participant } from "../../../../__generated__/graphql";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { Currency, exchangeMoney } from "@asmbl/shared/currency";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryPercentChangeCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode, currencies } = useCurrencies();
  const salaryIncrease =
    employee.compRecommendation?.latestSubmittedSalaryIncrease ??
    zero(defaultCurrencyCode);
  const currentSalary =
    employee.subject.activeEmployment?.salary ?? zero(defaultCurrencyCode);
  const exchangedSalaryIncrease = exchangeMoney(
    salaryIncrease,
    currentSalary.currency,
    {
      allCurrencies: currencies,
    }
  );
  return (
    <AssembleTruncatedTypography align="right">
      {isZero(exchangedSalaryIncrease)
        ? "-"
        : getPercentChangeInSalary(exchangedSalaryIncrease, currentSalary)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryPercentChangeCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>;
}) =>
  contramap((employee: Participant) => {
    const currentSalary =
      employee.subject.activeEmployment?.salary ?? zero(defaultCurrencyCode);

    const salaryIncrease =
      employee.compRecommendation?.latestSubmittedSalaryIncrease ??
      zero(defaultCurrencyCode);

    const exchangedSalaryIncerase = exchangeMoney(
      salaryIncrease,
      currentSalary.currency,
      {
        allCurrencies: currencies,
      }
    );

    return currentSalary.value !== 0
      ? ratio(exchangedSalaryIncerase, currentSalary) * 100
      : 0;
  });

CondensedTableSalaryPercentChangeCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryPercentChangeCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedSalaryIncrease(currencyCode: $currencyCode)
      }
    }
  `,
};

CondensedTableSalaryPercentChangeCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_PERCENT_CHANGE
);
CondensedTableSalaryPercentChangeCell2.id = ColumnIds.SALARY_PERCENT_CHANGE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryPercentChangeCell2,
  Header: CondensedTableSalaryPercentChangeCell2.Header,
  id: CondensedTableSalaryPercentChangeCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableSalaryPercentChangeCell2.column = column;
