import { gql, useQuery } from "@apollo/client";
import { CompCycleNew } from "src/components/CompCycle/CompCycleWizard/CompCycleNew";
import {
  CompCycleOrganizationChartSelectionProvider,
  CompCycleOrganizationChartViewsProvider,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { useTrack } from "../../analytics";
import { ErrorView } from "../../components/ErrorView";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { CreateCompCycleQuery } from "../../__generated__/graphql";

export function CreateCompCycleBoundary(): JSX.Element {
  const { Track } = useTrack({ subArea: "New Comp Cycle" });

  const { data, loading } = useQuery<CreateCompCycleQuery>(
    CreateCompCycleBoundary.query
  );

  if (!data || !data.valuation) {
    return loading ? <LoadingSpinner /> : <ErrorView />;
  }

  return (
    <Track>
      <CompCycleOrganizationChartSelectionProvider>
        <CompCycleOrganizationChartViewsProvider>
          <CompCycleNew valuation={data.valuation} />
        </CompCycleOrganizationChartViewsProvider>
      </CompCycleOrganizationChartSelectionProvider>
    </Track>
  );
}

CreateCompCycleBoundary.query = gql`
  query CreateCompCycleQuery {
    valuation {
      id
      valuation
      fdso
    }
  }
`;
