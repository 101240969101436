import { CurrencyCode } from "@asmbl/shared/constants";
import { annualToHourly, hourlyToAnnual } from "@asmbl/shared/currency";
import { Money } from "@asmbl/shared/money";
import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { CompUnit } from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { useCurrencies } from "../../CurrenciesContext";
import { CashInput, PercentInput } from "./ConfigurableTargetInputCell";
import { DogEarWithTooltip } from "./DogEarWithTooltip";

interface Props {
  onChange: (
    newSalary: Money | undefined,
    unitType: CompUnit | null
  ) => unknown;
  value: Money | null;
  basePay: Money | null;
  payCurrencyCode: CurrencyCode | null;
  isHourly?: boolean;
  disabled?: boolean;
  hasUnpublishedChanges?: boolean;
}

const useStyles = makeStyles(() => ({
  inputCell: {
    position: "relative",
    "&$inputCell": {
      padding: 0,
    },
  },
  inputContainer: { display: "flex", justifyContent: "flex-end" },
}));

export function SalaryInputCell({
  value,
  basePay,
  payCurrencyCode,
  onChange,
  disabled = false,
  hasUnpublishedChanges = false,
  isHourly = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const currency = basePay?.currency ?? payCurrencyCode ?? defaultCurrencyCode;
  const absolute = disabled || !value ? undefined : value;
  const workingHoursPerYear = compStructure?.workingHoursPerYear;

  const [absoluteInput, setAbsoluteInput] = useState<number | undefined>(
    absolute !== undefined && workingHoursPerYear && isHourly
      ? hourlyToAnnual(workingHoursPerYear, absolute).value
      : absolute?.value
  );
  const [hourlyInput, setHourlyInput] = useState<number | undefined>(
    absolute !== undefined && workingHoursPerYear && !isHourly
      ? annualToHourly(workingHoursPerYear, absolute).value
      : absolute?.value
  );

  const [percentInput, setPercentInput] = useState<number | undefined>(
    absolute !== undefined
      ? calculatePercent(basePay, absolute.value)
      : undefined
  );

  // Handle situations where the value prop changes after the component mounts
  useEffect(() => {
    if (absolute) {
      workingHoursPerYear && isHourly
        ? setAbsoluteInput(hourlyToAnnual(workingHoursPerYear, absolute).value)
        : setAbsoluteInput(absolute.value);
      workingHoursPerYear && !isHourly
        ? setHourlyInput(annualToHourly(workingHoursPerYear, absolute).value)
        : setHourlyInput(absolute.value);
      setPercentInput(calculatePercent(basePay, absolute.value));
    }
  }, [absolute, absoluteInput, basePay, isHourly, workingHoursPerYear]);

  const resetInputValues = () => {
    setAbsoluteInput(undefined);
    setPercentInput(undefined);
    setHourlyInput(undefined);
    onChange(undefined, null);
  };

  const handleAbsolute = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (disabled) {
      resetInputValues();
    }
    if (source !== "event") {
      return;
    }

    if (floatValue === undefined) {
      resetInputValues();
    } else {
      setAbsoluteInput(floatValue);
      setPercentInput(calculatePercent(basePay, floatValue));
      if (isHourly) {
        setHourlyInput(
          compStructure?.workingHoursPerYear
            ? convertToHourly(floatValue, compStructure.workingHoursPerYear)
            : undefined
        );
      }

      if (floatValue !== value?.value) {
        onChange({ value: floatValue, currency }, CompUnit.CASH);
      }
    }
  };

  const handleHourly = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (disabled) {
      resetInputValues();
    }
    if (source !== "event") {
      return;
    }

    if (floatValue === undefined) {
      resetInputValues();
    } else {
      setHourlyInput(floatValue);
      if (workingHoursPerYear) {
        setAbsoluteInput(floatValue * workingHoursPerYear);
      }
      if (floatValue !== value?.value) {
        onChange(
          {
            value: floatValue,
            currency,
          },
          CompUnit.HOURLY_CASH
        );
      }
    }
  };

  const handlePercent = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (disabled) {
      resetInputValues();
    }
    if (source !== "event") {
      return;
    }

    if (floatValue === undefined) {
      setAbsoluteInput(undefined);
      setPercentInput(undefined);

      onChange(undefined, null);
    } else {
      setPercentInput(floatValue);

      const newAbsolute =
        basePay && basePay.value !== 0
          ? Math.round(basePay.value * (floatValue / 100))
          : 0;
      setAbsoluteInput(newAbsolute);
      if (newAbsolute !== value?.value) {
        onChange({ value: newAbsolute, currency }, CompUnit.CASH);
      }
    }
  };

  const salaryCell = isHourly ? (
    <CashInput
      disabled={disabled}
      cashInput={hourlyInput}
      handleChange={handleHourly}
      currency={currency}
      isHourly={isHourly}
    />
  ) : (
    <CashInput
      disabled={disabled}
      cashInput={absoluteInput}
      handleChange={handleAbsolute}
      currency={currency}
    />
  );

  const secondCell = isHourly ? (
    <CashInput
      disabled={disabled}
      cashInput={absoluteInput}
      handleChange={handleAbsolute}
      currency={currency}
    />
  ) : (
    <PercentInput
      handleChange={handlePercent}
      disabled={disabled}
      percentInput={percentInput}
    />
  );
  return (
    <TableCell role="gridcell" className={classes.inputCell} colSpan={3}>
      {disabled ? (
        <Tooltip title="Please select a new position first" placement="top">
          <div className={classes.inputContainer}>
            {salaryCell}
            {secondCell}
          </div>
        </Tooltip>
      ) : (
        <div className={classes.inputContainer}>
          <>
            {hasUnpublishedChanges && <DogEarWithTooltip />}
            {salaryCell}
          </>
          {secondCell}
        </div>
      )}
    </TableCell>
  );
}

function convertToHourly(floatValue: number, workingHoursPerYear: number) {
  return Math.round((floatValue / workingHoursPerYear) * 10_000) / 10_000;
}

function calculatePercent(basePay: Money | null, absolute: number): number {
  return basePay && basePay.value !== 0
    ? Math.round((absolute / basePay.value) * 10000) / 100
    : 0;
}
