import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useFeatureFlags } from "src/components/FeatureContext";
import { searchStringsForMatch } from "src/models/Search";
import { ComplexToggle } from "../../../components/ComplexToggle";
import { Paginator } from "../../../components/Pagination/Paginator";
import { SearchBar } from "../../../components/Search/SearchBar";
import { TableHeaderBar } from "../../../components/Table/TableHeaderBar";
import { GRAY_6 } from "../../../theme";
import {
  EligibleIneligibleTable_employee,
  EligibleIneligibleTable_perfRating,
} from "../../../__generated__/graphql";
import { usePagination } from "../Plan/Contexts/PaginationContext";
import { ParticipantsTable } from "./ParticipantsTable";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  header: {
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

type Employee = EligibleIneligibleTable_employee & {
  perfRating: EligibleIneligibleTable_perfRating | null;
};

type PotentialParticipants = {
  eligible: Employee[];
  ineligible: Employee[];
  counts: { eligible: number; ineligible: number };
};

type Props = {
  people: PotentialParticipants | undefined;
  showEligible: boolean;
  setShowEligible: (showEligible: boolean) => unknown;
  searchTerm: string | null;
  setSearchTerm: (search: string | null) => unknown;
  order: "asc" | "desc";
  orderBy: string;
  setOrderParams: (field: string) => unknown;
};

export function EligibleIneligibleTable({
  people,
  showEligible,
  setShowEligible,
  searchTerm,
  setSearchTerm,
  order,
  orderBy,
  setOrderParams,
}: Props): JSX.Element {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();

  const { pageSize, setPageSize, currentPage, setCurrentPage } =
    usePagination();

  const searchedEmployees = useMemo(() => {
    const employees =
      people === undefined
        ? []
        : showEligible
          ? people.eligible
          : people.ineligible;
    return searchTerm === null || searchTerm.length === 0
      ? employees
      : employees.filter((e) =>
          searchStringsForMatch(e.displayName, searchTerm)
        );
  }, [searchTerm, showEligible, people]);

  if (people === undefined) {
    return <div>Loading</div>;
  }

  const pagedEmployees = searchedEmployees.slice(
    pageSize * currentPage,
    pageSize * (currentPage + 1)
  );

  return (
    <div className={classes.root}>
      <>
        <TableHeaderBar
          className={classes.header}
          slotLeft={
            <SearchBar
              searchTerm={
                featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
                  ? undefined
                  : searchTerm ?? ""
              }
              defaultValue={
                featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
                  ? searchTerm
                  : undefined
              }
              handleSearchChange={
                featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
                  ? () => {
                      // noop
                    }
                  : setSearchTerm
              }
              handleOnBlur={
                featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
                  ? setSearchTerm
                  : () => {
                      // noop
                    }
              }
              placeholder={
                featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
                  ? "Type name and press enter"
                  : undefined
              }
            />
          }
          slotRight={
            <Paginator
              page={currentPage}
              setPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              items={
                showEligible ? people.counts.eligible : people.counts.ineligible
              }
              compact
            />
          }
          slotCenter={
            <ComplexToggle
              options={[
                ["eligible", `Eligible (${people.counts.eligible})`],
                ["ineligible", `Ineligible (${people.counts.ineligible})`],
              ]}
              selected={showEligible ? "eligible" : "ineligible"}
              onChange={(v) => setShowEligible(v === "eligible" ? true : false)}
            />
          }
        />
        <ParticipantsTable
          employees={
            featureFlags.isEnabled(FeatureFlag.ClientPlanTable030124)
              ? showEligible
                ? people.eligible
                : people.ineligible
              : pagedEmployees
          }
          order={order}
          orderBy={orderBy}
          setOrderParams={setOrderParams}
        />
      </>
    </div>
  );
}

EligibleIneligibleTable.fragments = {
  employee: gql`
    ${ParticipantsTable.fragments.employee}
    fragment EligibleIneligibleTable_employee on Employee {
      id
      ...ParticipantsTable_employee
    }
  `,
  perfRating: gql`
    ${ParticipantsTable.fragments.perfRating}
    fragment EligibleIneligibleTable_perfRating on EmployeeCompCyclePerfRating {
      id
      ...ParticipantsTable_perfRating
    }
  `,
};
