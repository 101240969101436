import { exchangeFromTo } from "@asmbl/shared/currency";
import { add, isZero, money, Money, ratio } from "@asmbl/shared/money";
import { RecItemInput } from "../../../__generated__/graphql";
import { getAccurateSimpleCashLabel } from "../../../models/Currency";
import { useCurrencies } from "../../CurrenciesContext";
import { BudgetDetail } from "./BudgetDetail";

type Props = {
  budgetType: string;
  items: RecItemInput[];
  compCycleBudget?: Money | null;
};

export function BudgetCashDetail({
  budgetType,
  items,
  compCycleBudget,
}: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();
  const compCycleCurrency =
    (compCycleBudget && currencies.get(compCycleBudget.currency)) ??
    defaultCurrency;

  const used = items
    .flatMap((item) => {
      // No change from this item, so skip it
      if (!item.recommendedCashValue) return [];

      const itemCurrency = currencies.get(item.recommendedCashValue.currency);
      // We can't convert this currency, so skip it.
      if (!itemCurrency) return [];

      return exchangeFromTo(
        item.recommendedCashValue,
        itemCurrency,
        compCycleCurrency
      );
    })
    .reduce(add, money(0, compCycleCurrency.code));

  const allocated = compCycleBudget ?? null;

  const percentUsed =
    allocated === null || isZero(allocated)
      ? null
      : ratio(used, allocated) * 100;

  return (
    <BudgetDetail
      budgetType={budgetType}
      percentUsed={percentUsed}
      usedOfAllocated={
        allocated ? (
          <>
            {getAccurateSimpleCashLabel(used)} of{" "}
            {getAccurateSimpleCashLabel(allocated)}
          </>
        ) : (
          getAccurateSimpleCashLabel(used)
        )
      }
    />
  );
}
