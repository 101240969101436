import { gql } from "@apollo/client";
import { useState } from "react";
import { CompRecAction } from "src/models/CompRecommendation";
import {
  CondensedTablePeople2_compCycleBudget as Budget,
  CondensedTablePeople2_compCycle as CompCycle2,
  CompRecommendationStatus,
  GetParticipantsInput,
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTable_perfRatingOption as PerfRatingOption,
  CondensedTablePeople2_position as Position,
  RecReviewStatus,
  CondensedTablePeople2_user as User,
  CondensedTablePeople2_valuation as Valuation,
} from "../../../__generated__/graphql";
import { CondensedTable2 } from "./CondensedTable2";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { CondensedTableHeader2 } from "./CondensedTableHeader2";

type Props = {
  compCycle: CompCycle2;
  filter: GetParticipantsInput;
  availablePositions: Position[];
  selectedCurrency: CurrencyCodeWithAll;
  onChangeSelectedCurrency: (currency: CurrencyCodeWithAll) => void;
  budget: Budget | null;
  valuation: Valuation;
  user: User;
  setNameSearch: (name: string | null) => void;
  nameSearch?: string | null;
  isAdmin: boolean;
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
};

export function CondensedTablePeople2({
  compCycle,
  filter,
  availablePositions,
  selectedCurrency,
  budget,
  valuation,
  user,
  onChangeSelectedCurrency,
  setNameSearch,
  nameSearch,
  isAdmin,
  meritGuidance,
}: Props): JSX.Element {
  const [actionToggle, setActionToggle] = useState<CompRecAction>(
    CompRecAction.ALL
  );

  const employeesFilteredByAction = compCycle.participants.participants.filter(
    (e) => {
      const latestReview = e.compRecommendation?.latestSubmittedReviews.at(0);

      switch (actionToggle) {
        case CompRecAction.ALL:
          return true;
        case CompRecAction.NEEDS_REVIEW:
          return (
            e.compRecommendation === null ||
            (e.compRecommendation.reviewStatus ===
              CompRecommendationStatus.NEEDS_REVIEW &&
              latestReview?.author.id !== user.id) ||
            e.compRecommendation.reviewStatus ===
              CompRecommendationStatus.LATE ||
            (latestReview?.status === RecReviewStatus.DENIED &&
              latestReview.author.id !== user.id)
          );
        case CompRecAction.REVIEWED:
          return (
            (e.compRecommendation?.reviewStatus ===
              CompRecommendationStatus.REVIEWED &&
              !(
                latestReview?.status === RecReviewStatus.DENIED &&
                latestReview.author.id !== user.id
              )) ||
            (latestReview?.status === RecReviewStatus.APPROVED &&
              latestReview.author.id === user.id)
          );
        default:
          return false;
      }
    }
  );
  return (
    <>
      <CondensedTableHeader2
        onSearchTermChange={setNameSearch}
        selectedCurrency={selectedCurrency}
        onChangeSelectedCurrency={onChangeSelectedCurrency}
        compCycle={compCycle}
        employeeIds={compCycle.participants.participants.map(
          (e) => e.subjectId
        )}
        nameSearch={nameSearch}
        actionToggle={actionToggle}
        setActionToggle={setActionToggle}
        filter={filter}
      />
      <CondensedTable2
        selectedCurrency={selectedCurrency}
        employees={employeesFilteredByAction}
        compCycle={compCycle}
        isActiveCycle={compCycle.closeDate === null}
        availablePositions={availablePositions}
        budget={budget}
        valuation={valuation}
        user={user}
        isAdmin={isAdmin}
        meritGuidance={meritGuidance}
      />
    </>
  );
}

CondensedTablePeople2.fragments = {
  compCycle: gql`
    ${CondensedTableHeader2.fragments.compCycle2}
    ${CondensedTable2.fragments.compCycle}
    ${CondensedTable2.fragments.participant}
    fragment CondensedTablePeople2_compCycle on CompCycle2 {
      id
      name
      closeDate
      ...CondensedTableHeader_compCycle2
      ...CondensedTable2_compCycle
      participants(
        input: $filter
        limit: $limit
        offset: $offset
        sortBy: $sortBy
        sortDir: $sortDir
      ) {
        count
        participants {
          compCycleId
          subjectId
          compRecommendation(skipEligibility: $skipEligibility) {
            latestSubmittedReviews {
              id
              status
              author {
                id
              }
            }
            reviewStatus
          }
          ...CondensedTable2_participant
        }
      }
    }
  `,
  participant: gql`
    ${CondensedTable2.fragments.participant}
    fragment CondensedTablePeople2_participant on CompCycleParticipant {
      subjectId
      compRecommendation(skipEligibility: $skipEligibility) {
        latestSubmittedReviews {
          id
          author {
            id
          }
        }
      }
      ...CondensedTable2_participant
    }
  `,
  position: gql`
    ${CondensedTable2.fragments.position}
    fragment CondensedTablePeople2_position on Position {
      id
      ...CondensedTable2_position
    }
  `,
  valuation: gql`
    ${CondensedTable2.fragments.valuation}
    fragment CondensedTablePeople2_valuation on Valuation {
      id
      ...CondensedTable2_valuation
    }
  `,
  compCycleBudget: gql`
    ${CondensedTable2.fragments.compCycleBudget}
    fragment CondensedTablePeople2_compCycleBudget on CompCycleBudget {
      ...CondensedTable2_compCycleBudget
    }
  `,
  user: gql`
    ${CondensedTable2.fragments.user}
    fragment CondensedTablePeople2_user on User {
      id
      ...CondensedTable2_user
    }
  `,
};
