import { Money } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { Card } from "./Card";
import { CompCardContent } from "./CompCardContent";

type Props = {
  label: string;
  value: Money | string | number;
  description: string;
  className?: string;
  secondLabel?: string;
  secondValue?: Money | string | number;
  secondDescription?: string;
};

const useStyles = makeStyles(() => ({
  centered: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

/**
 * A small card displaying a component of an employee's compensation.
 * This generic component is meant to be specialized by components
 * using colocated fragments to describe the data they need.
 */
export function CompCard({
  label,
  value,
  description,
  className,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Card className={className}>
      <CompCardContent label={label} value={value} description={description} />
    </Card>
  );
}
