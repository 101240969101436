import { makeStyles } from "@material-ui/core";
import { GRAY_4, GRAY_6 } from "../../../theme";

const useStyles = makeStyles(() => ({
  tag: {
    width: 32,
    height: 16,
    background: "white",
    border: `1px solid ${GRAY_6}`,
    borderRadius: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  text: {
    margin: 0,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "140%",
    textTransform: "uppercase",
    color: GRAY_4,
  },
}));

export function OldTag(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.tag}>
      <p className={classes.text}>Old</p>
    </div>
  );
}
