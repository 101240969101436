import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { add, isMoney, zero } from "@asmbl/shared/money";
import { Comparator, ComparatorWithOrder } from "@asmbl/shared/sort";
import { cx } from "@emotion/css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { ComponentType, useEffect, useMemo, useRef } from "react";
import {
  CellProps,
  Column,
  useColumnOrder as useReactTableColumnOrder,
  useTable,
} from "react-table";
import { useVirtual } from "react-virtual";
import {
  CompRecommendationStatus,
  CondensedTable2_compCycle,
  CondensedTable2_compCycleBudget,
  CondensedTable2_user,
  CondensedTable2_valuation,
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTable2_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
  CondensedTable2_position as Position,
  RecItemType,
  TableDataContext2_position,
} from "src/__generated__/graphql";
import { CycleBudgetAppBar2 } from "src/components/CompCycle/CycleBudgetAppBar2";
import { useCompStructure } from "src/components/CompStructureContext";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { SortableTableHeaderCell, useSort } from "src/components/SortableTable";
import { UserViewContextProvider } from "src/contexts/UserViewContext";
import { getCashRecItems } from "src/models/CompRecommendation";
import { GRAY_1, GRAY_4, GRAY_6, GRAY_7, GRAY_9, WHITE } from "src/theme";
import { CondensedTableActionsCell2 as CondensedTableActionsCell } from "./Cells/CondensedTableActionsCell2";
import { CondensedTableActivityCell2 as CondensedTableActivityCell } from "./Cells/CondensedTableActivityCell2";
import { CondensedTableNameCell2 as CondensedTableNameCell } from "./Cells/CondensedTableNameCell2";
import { CondensedTableStatusCell2 as CondensedTableStatusCell } from "./Cells/CondensedTableStatusCell2";
import {
  LARGE_COL_WIDTH,
  XLG_COL_WIDTH,
  rowHeightEstimator,
} from "./Cells/dimensions";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { ColumnIds, useColumnOrder } from "./Contexts/ColumnOrderContext";
import { usePagination } from "./Contexts/PaginationContext";
import { TableDataContextProvider } from "./Contexts/TableDataContext2";

export type ColumnComponent2 = ComponentType<CellProps<Participant>> & {
  id: string;
  column: Column<Participant>;
  sortable?: boolean;
  ordering: (data: {
    currencies: Map<CurrencyCode, Currency>;
    defaultCurrencyCode: CurrencyCode;
    defaultCurrency: Currency;
    meritGuidance: {
      meritAdjustmentGuides: MeritAdjustmentGuide[];
      meritAdjustmentRanges: MeritAdjustmentRange[];
      perfRatingOptions: PerfRatingOption[];
    };
    availablePositions: Position[];
    workingHoursPerYear: number | undefined;
  }) => Comparator<Participant> | ComparatorWithOrder<Participant>;
};

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    overflowX: "auto",
    paddingBottom: "7.5rem",
    zIndex: 0,
    height: "100%",
  },
  root: {
    backgroundColor: WHITE,
    position: "relative",
    width: "100%",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  stickyCell: {
    position: "sticky",
    zIndex: 2,
    background: WHITE,
  },
  headerCell: {
    borderTop: `1px solid ${GRAY_6}`,
    borderLeft: "none",
    borderRight: "none",
    textTransform: "uppercase",
    backgroundColor: WHITE,
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "100%",
    textAlign: "left",
    padding: theme.spacing(0, 1.5),
  },
  row: {
    height: 48,
    "&:hover": {
      "& $tableBodyCell": {
        backgroundColor: `${GRAY_9} !important`,
      },
    },
  },
  tableBodyCell: {
    boxSizing: "border-box",
    boxShadow: `inset -1px 0px 0px ${GRAY_6}`,
    color: GRAY_4,
    fontWeight: 450,
    fontSize: "13px",
    padding: theme.spacing(0, 1.5),
    lineHeight: "130%",
    alignItems: "center",
    height: "48px",
    letterSpacing: "-0.5px",
  },
  nameHeaderCell: {
    borderRight: `1px solid ${GRAY_6}`,
  },
  nameCell: {
    left: 0,
  },
  actionHeaderCell: {
    borderRight: `1px solid ${GRAY_6}`,
  },
  actionCell: {
    position: "sticky",
    left: XLG_COL_WIDTH, // account for the name column
  },
  statusCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
    boxShadow: "3px 0px 6px 0px rgba(10, 36, 64, 0.10)",
  },
  activityCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
  },
  lastDefaultColumn: {
    boxShadow: "none",
  },
  rowNeedsAction: { color: GRAY_1 },
  rowActedOn: {
    color: GRAY_4,
    background: GRAY_7,
  },
}));

type Props = {
  columns: ColumnComponent2[];
  employees: Participant[];
  availablePositions: Position[];
  isActiveCycle: boolean;
  selectedCurrency: CurrencyCodeWithAll;
  valuation: CondensedTable2_valuation;
  budget: CondensedTable2_compCycleBudget | null;
  user: CondensedTable2_user;
  compCycle: CondensedTable2_compCycle;
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
};

export function CondensedTableInner2({
  columns,
  employees,
  compCycle,
  availablePositions,
  isActiveCycle,
  selectedCurrency,
  valuation,
  budget,
  user,
  meritGuidance,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currencies, defaultCurrency } = useCurrencies();
  const { hiddenColumns, columns: orderedColumns } = useColumnOrder();
  const { sortBy, setSortBy, sortDir, setSortDir } = usePagination();
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const workingHoursPerYear = compStructure?.workingHoursPerYear;
  const isPaginated = isEnabled(FeatureFlag.ClientPlanTable030124);

  const filteredCashRecItems = useMemo(() => {
    const salaryIncreaseTypes = new Set([
      RecItemType.MERIT_INCREASE,
      RecItemType.PROMOTION,
      RecItemType.MARKET,
    ]);

    return employees.flatMap((emp) => {
      const latestItems = emp.compRecommendation?.latestSubmittedItems ?? [];

      const adjustments = latestItems
        .filter((item) => salaryIncreaseTypes.has(item.recommendationType))
        .map((i) => i.recommendedCashValue)
        .filter(isMoney);

      const salaryIncrease =
        adjustments.length > 0
          ? adjustments.reduce(add)
          : zero(defaultCurrency.code);

      const currentSalary = emp.subject.activeEmployment?.salary;

      const newSalary =
        currentSalary != null
          ? add(currentSalary, salaryIncrease)
          : currentSalary;

      return newSalary
        ? getCashRecItems(emp.subject, latestItems, newSalary)
        : [];
    });
  }, [employees, defaultCurrency]);

  const columnConfig = useMemo(() => columns.map((c) => c.column), [columns]);

  const customComparators = useMemo(() => {
    const comparators: Record<
      string,
      Comparator<Participant> | ComparatorWithOrder<Participant>
    > = {};
    for (const column of columns) {
      comparators[column.id] = column.ordering({
        currencies,
        defaultCurrencyCode: defaultCurrency.code,
        defaultCurrency,
        meritGuidance,
        availablePositions,
        workingHoursPerYear,
      });
    }
    return comparators;
  }, [
    columns,
    currencies,
    defaultCurrency,
    meritGuidance,
    availablePositions,
    workingHoursPerYear,
  ]);

  const {
    sortedArray: sortedEmployees,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<Participant, string>(
    employees,
    ColumnIds.NAME,
    "asc",
    customComparators
  );

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns: columnConfig,
      data: sortedEmployees,
      initialState: { hiddenColumns },
    },
    useReactTableColumnOrder
  );

  useEffect(() => {
    setColumnOrder(orderedColumns);
  }, [orderedColumns, setColumnOrder]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  const parentRef = useRef<HTMLDivElement>(null);
  // used for budget drawer to take into account horizontal scrollbar
  const scrollBarHeight = parentRef.current
    ? parentRef.current.offsetHeight - parentRef.current.clientHeight
    : 0;

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    estimateSize: rowHeightEstimator,
    overscan: 10,
    paddingStart: 40,
  });

  const onChangeSort = (property: string) => {
    if (isPaginated) {
      setSortBy(property);
      setSortDir(property === sortBy && sortDir === "asc" ? "desc" : "asc");
    }
    handleRequestSort(property);
  };

  return (
    <UserViewContextProvider>
      <TableDataContextProvider
        value={{
          compCycleId: compCycle.id,
          recommendationsPreFill: compCycle.recommendationsPreFill,
          availablePositions:
            availablePositions as unknown as TableDataContext2_position[],
          isActiveCycle,
          selectedCurrency,
          directReportIds:
            user.employee?.directReports.map((report) => report.id) ?? [],
          indirectReportIds:
            user.employee?.indirectReports.map((report) => report.id) ?? [],
          currentPhase: compCycle.currentPhase,
          compCyclePhases: compCycle.phases,
          meritGuidance,
        }}
      >
        <div ref={parentRef} className={classes.scrollContainer}>
          <Table
            {...getTableProps()}
            className={classes.root}
            style={{ height: `${rowVirtualizer.totalSize}px` }}
          >
            <TableHead>
              <TableRow
                className={classes.stickyHeader}
                data-intercom-target={"table-header-thing"}
              >
                {headers.map((header) => {
                  if (!header.isVisible) {
                    return null;
                  }

                  const headerClasses = cx(classes.headerCell, {
                    [classes.stickyHeader]:
                      header.id === CondensedTableNameCell.id ||
                      header.id === CondensedTableActionsCell.id ||
                      header.id === CondensedTableStatusCell.id ||
                      header.id === CondensedTableActivityCell.id,

                    [classes.nameHeaderCell]:
                      header.id === CondensedTableNameCell.id,
                    [classes.nameCell]: header.id === CondensedTableNameCell.id,

                    [classes.actionHeaderCell]:
                      header.id === CondensedTableActionsCell.id,
                    [classes.actionCell]:
                      header.id === CondensedTableActionsCell.id,

                    [classes.statusCell]:
                      header.id === CondensedTableStatusCell.id,
                    [classes.activityCell]:
                      header.id === CondensedTableActivityCell.id,
                  });

                  return header.defaultCanSort === false ? (
                    <TableCell
                      {...header.getHeaderProps({
                        style: {
                          maxWidth: header.width,
                          minWidth: header.width,
                          width: header.width,
                          fontSize: ".6875rem",
                        },
                      })}
                      className={headerClasses}
                    >
                      {header.Header?.toString()}
                    </TableCell>
                  ) : (
                    <SortableTableHeaderCell<Record<string, unknown>>
                      cellTitle={header.Header?.toString()}
                      orderByField={header.id}
                      order={order}
                      isSelected={orderBy === header.id}
                      handleRequestSort={onChangeSort}
                      data-intercom-target={"plan-" + header.id}
                      className={headerClasses}
                      {...header.getHeaderProps({
                        style: {
                          maxWidth: header.width,
                          minWidth: header.width,
                          width: header.width,
                        },
                      })}
                      key={header.getHeaderProps().key}
                      noWrap={false}
                      align="left"
                    />
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rowVirtualizer.virtualItems.map((virtualRow) => {
                const row = rows[virtualRow.index];
                prepareRow(row);

                const reviewStatus =
                  row.original.compRecommendation?.reviewStatus ?? null;

                const actionNeededStatuses = [
                  CompRecommendationStatus.FUTURE_PHASE,
                  CompRecommendationStatus.NEEDS_REVIEW,
                  CompRecommendationStatus.LATE,
                ];

                const rowNeedsAction =
                  reviewStatus == null ||
                  actionNeededStatuses.includes(reviewStatus);

                return (
                  <TableRow
                    {...row.getRowProps()}
                    key={row.getRowProps().key}
                    className={classes.row}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            style: {
                              maxWidth: cell.column.width,
                              width: cell.column.width,
                              minWidth: cell.column.width,
                            },
                          })}
                          key={cell.getCellProps().key}
                          className={cx(classes.tableBodyCell, {
                            [classes.stickyCell]:
                              cell.column.id === CondensedTableNameCell.id ||
                              cell.column.id === CondensedTableActionsCell.id ||
                              cell.column.id === CondensedTableStatusCell.id ||
                              cell.column.id === CondensedTableActivityCell.id,

                            [classes.nameCell]:
                              cell.column.id === CondensedTableNameCell.id,
                            [classes.actionCell]:
                              cell.column.id === CondensedTableActionsCell.id,
                            [classes.statusCell]:
                              cell.column.id === CondensedTableStatusCell.id,
                            [classes.activityCell]:
                              cell.column.id === CondensedTableActivityCell.id,

                            [classes.lastDefaultColumn]:
                              index === row.cells.length,
                            [classes.rowNeedsAction]: rowNeedsAction,
                            [classes.rowActedOn]: !rowNeedsAction,
                          })}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <CycleBudgetAppBar2
          recommendationItems={filteredCashRecItems}
          compCycle={compCycle}
          budget={budget}
          valuation={valuation}
          bottomOffset={scrollBarHeight}
        />
      </TableDataContextProvider>
    </UserViewContextProvider>
  );
}
