import { gql } from "@apollo/client";
import { formatCurrency, Money, money } from "@asmbl/shared/money";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { formatNumber } from "accounting";
import { useState } from "react";
import {
  ActualTargetPayRow_employee,
  CashCompType,
  CompComponent,
  CompUnit,
  CurrencyCode,
  RecItemType,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { GRAY_6 } from "../../../../theme";
import {
  ConfigurableSalaryInputCell,
  InputOption,
} from "../../Cells/ConfigurableTargetInputCell";
import { CashRows } from "../CashRows";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

const useStyles = makeStyles(() => ({
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  sliderCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
  },
  noteContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

interface Props extends CompCycleLineItemRowProps {
  employee: ActualTargetPayRow_employee;
  label: string;
  currency: CurrencyCode | null;
}

const component = CompComponent.ACTUAL_RECURRING_BONUS;
const recommendationType = RecItemType.ACTUAL_RECURRING_BONUS;
const cashCompType = CashCompType.RECURRING_BONUS;

export function ActualTargetPayRow({
  employee,
  currency,
  label,
  revisedRecommendation,
  submittedRecommendation,
  onChangeRecommendationItem,
}: Props): JSX.Element {
  const { Track } = useTrack({
    compensationComponent: component,
    employeeId: employee.id,
  });
  const recItemStyle = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();
  const classes = useStyles();

  const [inputOption, setInputOption] = useState(InputOption.CASH_VALUE);

  const targetPay = revisedRecommendation?.items.get(recommendationType);
  const targetCashComp = employee.activeCashCompensation?.find(
    (c) => c.type === cashCompType
  );
  const cashValue = targetPay?.recommendedCashValue ?? undefined;
  const currentTargetValue = targetCashComp?.annualCashEquivalent;
  const percentValue =
    cashValue && currentTargetValue && currentTargetValue.value !== 0
      ? (100 * cashValue.value) / currentTargetValue.value
      : 0;

  const noPrevRecurringBonus = targetCashComp === undefined;

  const alternateValueLabel =
    noPrevRecurringBonus || cashValue === undefined
      ? ""
      : inputOption === InputOption.CASH_VALUE
        ? `${formatNumber(percentValue, 2)}%`
        : formatCurrency(cashValue, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          });

  const [submitted, revised] = [
    submittedRecommendation,
    revisedRecommendation,
  ].map((x) => x?.items.get(recommendationType));

  const handleChange = (
    newValue: Money<CurrencyCode> | undefined,
    percentValue: number | undefined,
    option: InputOption
  ) => {
    setInputOption(option);
    if (option === InputOption.CASH_VALUE) {
      return onChangeRecommendationItem({
        recommendationType: recommendationType,
        recommendedCashValue: newValue,
        recommendedPercentValue: undefined,
        note: targetPay?.note,
        unitType: CompUnit.CASH,
      });
    }

    if (
      option === InputOption.PERCENT_OF_TARGET &&
      currentTargetValue != null &&
      percentValue != null
    ) {
      const newTarget = money(
        Math.round((currentTargetValue.value * percentValue) / 100),
        currentTargetValue.currency
      );
      return onChangeRecommendationItem({
        recommendationType: recommendationType,
        recommendedCashValue: newTarget,
        recommendedPercentValue: undefined,
        note: targetPay?.note,
        unitType: CompUnit.CASH,
      });
    }
  };

  return (
    <Track>
      <TableRow aria-level={3} className={recItemStyle.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <TableCell
          role="gridcell"
          colSpan={5}
          padding="none"
          className={recItemStyle.textCell}
        >
          <div className={classes.labelContainer}>
            <Typography className={recItemStyle.rowLabel}>{label}</Typography>
            <Typography className={recItemStyle.extraRowText} component="span">
              {targetCashComp !== undefined
                ? `Current target: ${formatCurrency(
                    targetCashComp.annualCashEquivalent,
                    {
                      maximumFractionDigits: 0,
                    }
                  )} / ${formatNumber(targetCashComp.percentOfSalary, 2)}%`
                : ""}
            </Typography>
          </div>
        </TableCell>
        <ConfigurableSalaryInputCell
          value={targetPay?.recommendedCashValue ?? null}
          baseValue={currentTargetValue ?? null}
          onChange={handleChange}
          payCurrencyCode={currency}
          inputOptions={[
            {
              label: "% of Curr. Target",
              value: InputOption.PERCENT_OF_TARGET,
              disabled: noPrevRecurringBonus,
            },
            {
              label: "Cash Value",
              value: InputOption.CASH_VALUE,
            },
          ]}
          defaultInputOption={InputOption.CASH_VALUE}
          hasUnpublishedChanges={hasUnpublishedChanges(
            submitted?.recommendedCashValue?.value,
            revised?.recommendedCashValue?.value
          )}
        />
        <TableCell
          role="gridcell"
          className={recItemStyle.noteTextCell}
          colSpan={4}
        >
          <div className={classes.noteContainer}>
            <Typography className={recItemStyle.extraRowText} component="span">
              {alternateValueLabel}
            </Typography>
            <CompCycleLineItemRowButton
              disabled={
                targetPay?.recommendedCashValue === undefined &&
                targetPay?.recommendedPercentValue === undefined
              }
              employee={employee}
              note={targetPay?.note ?? null}
              onClick={(note: string) =>
                onChangeRecommendationItem({
                  ...targetPay,
                  recommendationType,
                  note,
                })
              }
            />
          </div>
        </TableCell>
        <TableCell role="gridcell" />
      </TableRow>
    </Track>
  );
}

ActualTargetPayRow.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    ${CashRows.fragments.employee}
    fragment ActualTargetPayRow_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      ...CashRows_employee
      id
      activeCashCompensation(currencyCode: $currencyCode) {
        employeeId
        activeAt
        type
        annualCashEquivalent
        percentOfSalary
        unit
      }
    }
  `,
};
