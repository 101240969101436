import { ScheduleType } from "@asmbl/shared/constants";
import {
  Currency,
  exchangeFromTo,
  hourlyToAnnual,
} from "@asmbl/shared/currency";
import { Money, add, divide, multiply, ratio, zero } from "@asmbl/shared/money";
import { byDate } from "@asmbl/shared/sort";
import { mapMaybe, mapify, setify } from "@asmbl/shared/utils";
import {
  CashCompType,
  CompUnit,
  CurrencyCode,
  RecItemType,
} from "../__generated__/graphql";
import { CASH_COMP_COMPONENTS, CashBandName } from "../constants";
import { Item, getCashRecItem } from "./CompRecommendation";
export type CashCompensation = {
  type: CashCompType;
  unit: CompUnit;
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
  percentOfSalary: number;
};

export function isRecurringComp(band: { name: string }): boolean {
  return (
    CASH_COMP_COMPONENTS[band.name as CashBandName].scheduleType !==
    ScheduleType.ONE_TIME
  );
}

export function getPayCashEquivalent(
  comp: Omit<CashCompensation, "percentOfSalary">
): Money {
  return comp.unit === CompUnit.HOURLY_CASH
    ? comp.hourlyCashEquivalent
    : comp.annualCashEquivalent;
}

export type TotalCash<CashComp extends CashCompensation> = {
  annualTotal: Money;
  hourlyTotal: Money;
  subcomponents: CashComp[];
  selectedTypes: Set<CashCompType>;
  currency: CurrencyCode;
};

/**
 * Takes a CashCompensation[], filters out any deselected components (if
 * provided), and sums up the subcomponents into a nice, convenient object.
 *
 * If `selectedTypes` is not passed in, it will default to a set of all of
 * types in `cash`.
 *
 * If all of the relevant CashComp's are filtered out, we still want to track
 * the currency of the employee's TotalCash, hence `currency`.
 */
export function getTotalCash<CashComp extends CashCompensation>(
  cash: CashComp[] | null | undefined,
  selectedTypes?: Set<CashCompType>,
  currencies?: Map<CurrencyCode, Currency>
): TotalCash<CashComp> | undefined {
  const salary = cash?.find(({ type }) => type === CashCompType.SALARY);
  if (cash == null || salary === undefined) return undefined;
  const salaryCash = getPayCashEquivalent(salary);

  const chosenTypes =
    selectedTypes !== undefined
      ? selectedTypes
      : new Set(cash.map(({ type }) => type));

  const subcomponents = cash.filter((sc) => chosenTypes.has(sc.type));
  let annualTotal = zero(salaryCash.currency);
  let hourlyTotal = zero(salaryCash.currency);
  subcomponents.forEach((component) => {
    const isHourlyComponent = component.unit === CompUnit.HOURLY_CASH;
    // only add to hourly total if the component is paid hourly
    if (isHourlyComponent) {
      const hourlyCashValue =
        currencies &&
        component.hourlyCashEquivalent.currency !== salaryCash.currency
          ? exchangeFromTo(
              component.hourlyCashEquivalent,
              currencies.get(component.hourlyCashEquivalent.currency)!,
              currencies.get(salaryCash.currency)!
            )
          : component.hourlyCashEquivalent;
      hourlyTotal = add(hourlyTotal, hourlyCashValue);
    }

    const annualCashValue =
      currencies &&
      component.annualCashEquivalent.currency !== salaryCash.currency
        ? exchangeFromTo(
            component.annualCashEquivalent,
            currencies.get(component.annualCashEquivalent.currency)!,
            currencies.get(salaryCash.currency)!
          )
        : component.annualCashEquivalent;

    annualTotal = add(annualTotal, annualCashValue);
  });

  return {
    annualTotal,
    hourlyTotal,
    subcomponents,
    selectedTypes: chosenTypes,
    currency: salaryCash.currency,
  };
}

/**
 * Returns an employee's total cash after taking a comp recommendation into account.
 */
export function getTotalCashAfterRecommendation<
  CashComp extends CashCompensation,
>(
  defaultCurrencyCode: CurrencyCode,
  cash: CashComp[] | null,
  items: Item[],
  workingHoursPerYear: number | undefined
): TotalCash<CashComp> | undefined {
  if (items.length === 0 || !cash) return getTotalCash(cash);
  const oldSalary = getSalaryCashComp(cash);

  const currency =
    items.find((i) => i.recommendedCashValue?.currency !== undefined)
      ?.recommendedCashValue?.currency ??
    oldSalary?.annualCashEquivalent.currency ??
    defaultCurrencyCode;

  const sumTypes = (types: RecItemType[]) => {
    return items
      .filter((i) => types.includes(i.recommendationType))
      .reduce((partialSum, component) => {
        const annualizedItemValue =
          component.unitType === CompUnit.HOURLY_CASH &&
          component.recommendedCashValue
            ? hourlyToAnnual(
                workingHoursPerYear ?? 0,
                component.recommendedCashValue
              )
            : component.recommendedCashValue;
        return add(partialSum, annualizedItemValue ?? zero(currency));
      }, zero(currency));
  };

  const newSalary = add(
    oldSalary?.annualCashEquivalent ?? zero(currency),
    sumTypes([
      RecItemType.MARKET,
      RecItemType.MERIT_INCREASE,
      RecItemType.PROMOTION,
    ])
  );
  const newSalaryHourly =
    workingHoursPerYear !== undefined
      ? divide(
          add(
            oldSalary?.annualCashEquivalent ?? zero(currency),
            sumTypes([
              RecItemType.MARKET,
              RecItemType.MERIT_INCREASE,
              RecItemType.PROMOTION,
            ])
          ),
          workingHoursPerYear
        )
      : undefined;
  const newSalaryComponent =
    newSalary.value > 0 || oldSalary
      ? {
          type: CashCompType.SALARY,
          annualCashEquivalent: newSalary,
          percentOfSalary: 100,
        }
      : null;

  const newCommissionItem = items.find(
    (i) => i.recommendationType === RecItemType.TARGET_COMMISSION
  );
  const newCommissionCashItem =
    newCommissionItem !== undefined
      ? getCashRecItem(newCommissionItem, newSalary)
      : undefined;
  const oldCommission = cash.find((c) => c.type === CashCompType.COMMISSION);

  const newCommissionValue =
    newCommissionCashItem?.recommendedCashValue ??
    oldCommission?.annualCashEquivalent ??
    zero(currency);
  const newCommissionComponent =
    newCommissionCashItem !== undefined || oldCommission !== undefined
      ? {
          type: CashCompType.COMMISSION,
          annualCashEquivalent: newCommissionValue,
          hourlyCashEquivalent:
            workingHoursPerYear !== undefined
              ? divide(newCommissionValue, workingHoursPerYear)
              : zero(currency),
          percentOfSalary:
            newSalary.value > 0 &&
            newCommissionValue.currency === newSalary.currency
              ? ratio(newCommissionValue, newSalary) * 100
              : null,
        }
      : null;

  const newRecurringBonusItem = items.find(
    (i) => i.recommendationType === RecItemType.TARGET_RECURRING_BONUS
  );
  const newRecurringBonusCashItem =
    newRecurringBonusItem !== undefined
      ? getCashRecItem(newRecurringBonusItem, newSalary)
      : undefined;
  const oldRecurringBonus = cash.find(
    (c) => c.type === CashCompType.RECURRING_BONUS
  );

  const newRecurringBonusValue =
    newRecurringBonusCashItem?.recommendedCashValue ??
    oldRecurringBonus?.annualCashEquivalent ??
    zero(currency);
  const newRecurringBonusComponent =
    newRecurringBonusItem !== undefined || oldRecurringBonus !== undefined
      ? {
          type: CashCompType.RECURRING_BONUS,
          annualCashEquivalent: newRecurringBonusValue,
          hourlyCashEquivalent:
            workingHoursPerYear !== undefined
              ? divide(newRecurringBonusValue, workingHoursPerYear)
              : zero(currency),
          percentOfSalary:
            newSalary.value > 0 &&
            newRecurringBonusValue.currency === newSalary.currency
              ? ratio(newRecurringBonusValue, newSalary) * 100
              : null,
        }
      : null;

  return {
    annualTotal: add(
      newSalary,
      add(
        newCommissionComponent?.annualCashEquivalent ?? zero(currency),
        newRecurringBonusComponent?.annualCashEquivalent ?? zero(currency)
      )
    ),
    hourlyTotal: add(
      newSalaryHourly ?? zero(currency),
      add(
        newCommissionComponent?.hourlyCashEquivalent ?? zero(currency),
        newRecurringBonusComponent?.hourlyCashEquivalent ?? zero(currency)
      )
    ),
    subcomponents: [
      newSalaryComponent,
      newCommissionComponent,
      newRecurringBonusComponent,
    ].filter((c) => c != null) as CashComp[],
    selectedTypes: new Set([
      CashCompType.SALARY,
      CashCompType.COMMISSION,
      CashCompType.RECURRING_BONUS,
    ]),
    currency,
  };
}

function activeCompByType<
  C extends CashCompensation & { activeAt: GraphQL_Date },
>(cashComps: C[], type: CashCompType, activeAt: Date | string): C | undefined {
  const timestamp = new Date(activeAt).getTime();
  return cashComps
    .filter(
      (cashComp) =>
        new Date(cashComp.activeAt).getTime() <= timestamp &&
        cashComp.type === type
    )
    .sort(byDate((c) => c.activeAt))
    .at(-1);
}

export function activeComp<
  C extends CashCompensation & { activeAt: GraphQL_Date },
>(cashComps: C[], activeAt: Date | string): Map<CashCompType, C> {
  const types = Array.from(Object.values(CashCompType));
  return new Map(
    types.flatMap((type) => {
      const comp = activeCompByType(cashComps, type, activeAt);
      return comp ? [[type, comp]] : [];
    })
  );
}

/**
 * Calculates the value of a particular cash compensation item given an explicit
 * map of active comp. A single CashCompensation may change its cash value over time,
 * if it is defined as a percent of salary. By providing a Map with a specific salary,
 * we can recompute the cash value as needed.
 *
 * @param compType The type of variable cash that we are looking for (Commission, etc)
 * @param comp The cash compensation active at a point in time
 * @returns
 */
export function cashValueOf(
  compType: CashCompType,
  comp: Map<
    CashCompType,
    { annualCashEquivalent: Money; percentOfSalary: number; unit: CompUnit }
  >
): Money | undefined {
  const targetComp = comp.get(compType);

  if (!targetComp) {
    return;
  }

  if (targetComp.unit === CompUnit.CASH) {
    return targetComp.annualCashEquivalent;
  }

  // Percent of salary
  const salary = comp.get(CashCompType.SALARY)?.annualCashEquivalent;
  if (!salary) {
    return;
  }
  return multiply(salary, targetComp.percentOfSalary / 100);
}

export function totalCashValue(
  comp: Map<
    CashCompType,
    { annualCashEquivalent: Money; percentOfSalary: number; unit: CompUnit }
  >
): number | null {
  return mapMaybe(
    Array.from(comp.keys()),
    (type) => cashValueOf(type, comp)?.value ?? 0
  ).reduce((a, b) => a + b, 0);
}

/**
 * Zips together a CashCompensation array and a CashBand, matching each
 * CashCompensation with its matching band (if there is one)
 *
 * The complicated return type indicates that `cash` may be undefined OR `band`
 * may be undefined, but they will NEVER BOTH be undefined.
 */
export function zip<
  CashComp extends CashCompensation,
  CashBand extends { name: string },
>(
  cash: CashComp[],
  bands: CashBand[]
): (
  | { type: CashCompType; cash: CashComp; band: CashBand | undefined }
  | { type: CashCompType; cash: CashComp | undefined; band: CashBand }
)[] {
  // First, get all of the CashComps and their matching bands
  const bandMap = mapify(bands, "name");
  const cashAndBands = cash.map((comp) => ({
    type: comp.type,
    cash: comp,
    band: bandMap.get(CashBandName[comp.type]),
  }));

  // Second, get the remaining bands that DON'T have a matching CashComp
  const cashCompTypes = setify(cash, "type");
  const bandsWithoutCash = bands.filter(
    (b) => !cashCompTypes.has(nameToCashCompType(b.name))
  );

  const bandsAndNoCash = bandsWithoutCash.map((band) => ({
    type: nameToCashCompType(band.name),
    band,
    cash: undefined,
  }));

  return [...cashAndBands, ...bandsAndNoCash];
}

/* Removes all parens, switchings everything to uppercase, replaces all spaces
 * with underscores, and casts it to CashCompType.
 *
 * CashCompensation objects have a `type` that is an enum value. CashBand
 * objects have a `name` that *should* be an enum value, but it is actually a
 * string. This is a historical relic that should one day be fixed.
 *
 * Because we're stuck with it for now, we have to make sure CashBand entries
 * with a `name` like "Recurring Bonus" match up with the CashCompType
 * "RECURRING_BONUS"
 */
export function nameToCashCompType(name: string): CashCompType {
  return name
    .replace(/[()]/g, "")
    .toUpperCase()
    .split(" ")
    .join("_") as CashCompType;
}

/**
 * A helper for sorting a list of objects that have a CashCompType.
 * Ensures that Salary is always first, and then alphabetical afterwards.
 */
export function byCashCompType<C extends { type: CashCompType }>(a: C, b: C) {
  return a.type === CashCompType.SALARY
    ? -1
    : b.type === CashCompType.SALARY
      ? 1
      : a.type.toString().localeCompare(b.type.toString());
}

/**
 * A helper for getting the Salary cash comp type.
 */
export function getSalaryCashComp<C extends { type: CashCompType }>(
  cashComp: C[] | null
) {
  if (!cashComp) return null;
  return cashComp.find((c) => c.type === CashCompType.SALARY);
}
