import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CashCompType,
  CondensedTableNewTargetRecurringBonusCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getTotalCashAfterRecommendation } from "../../../../models/CashCompensation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = { row: { original: Participant } };

function getBonus(
  defaultCurrencyCode: CurrencyCode,
  employee: Participant,
  workingHoursPerYear: number | undefined
) {
  const totalCash = getTotalCashAfterRecommendation(
    defaultCurrencyCode,
    employee.subject.activeCashCompensation ?? [],
    employee.compRecommendation?.latestSubmittedItems ?? [],
    workingHoursPerYear
  );

  return totalCash?.subcomponents.find(
    (c) => c.type === CashCompType.RECURRING_BONUS
  )?.annualCashEquivalent;
}

export function CondensedTableNewTargetRecurringBonusCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { defaultCurrencyCode } = useCurrencies();

  const commission = getBonus(
    defaultCurrencyCode,
    employee,
    compStructure?.workingHoursPerYear
  );

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        commission ??
          zero(
            employee.subject.activeEmployment?.salary?.currency ??
              defaultCurrencyCode
          )
      )}{" "}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewTargetRecurringBonusCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewTargetRecurringBonusCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
        }
      }
    }
  `,
};

CondensedTableNewTargetRecurringBonusCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_TARGET_RECURRING_BONUS
);
CondensedTableNewTargetRecurringBonusCell2.id =
  ColumnIds.NEW_TARGET_RECURRING_BONUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTargetRecurringBonusCell2,
  Header: CondensedTableNewTargetRecurringBonusCell2.Header,
  id: CondensedTableNewTargetRecurringBonusCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableNewTargetRecurringBonusCell2.column = column;
CondensedTableNewTargetRecurringBonusCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
  workingHoursPerYear,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
  workingHoursPerYear: number | undefined;
}) =>
  contramap((e: Participant) => {
    const bonus =
      getBonus(defaultCurrencyCode, e, workingHoursPerYear) ??
      zero(defaultCurrencyCode);

    const currentCurrency = currencies.get(bonus.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(bonus, currentCurrency, defaultCurrency).value;
  });
