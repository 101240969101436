import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableCurrentDepartmentCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getDepartmentName } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableCurrentDepartmentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const label =
    employee.subject.activeEmployment?.position?.ladder.department.name ?? "-";

  return (
    <AssembleTruncatedTypography lines={2} title={label}>
      {label}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentDepartmentCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentDepartmentCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          position {
            id
            ladder {
              id
              department {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableCurrentDepartmentCell_position on Position {
      id
      ladder {
        id
        department {
          id
          name
        }
      }
    }
  `,
};

CondensedTableCurrentDepartmentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_DEPARTMENT
);
CondensedTableCurrentDepartmentCell2.id = ColumnIds.CURRENT_DEPARTMENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentDepartmentCell2,
  Header: CondensedTableCurrentDepartmentCell2.Header,
  id: CondensedTableCurrentDepartmentCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableCurrentDepartmentCell2.column = column;
CondensedTableCurrentDepartmentCell2.ordering = () =>
  contramap((e: Participant) => getDepartmentName(e.subject) ?? "");
