import { useLazyQuery } from "@apollo/client";
import { useTrack } from "../../analytics";
import { GET_EMPLOYEE_RECOMMENDATIONS_CSV_EXPORT } from "../../queries";
import { GetEmployeeRecommendationsCsvExport } from "../../__generated__/graphql";
import { CsvDownloader } from "./CsvDownloader";
import { CsvExport } from "./CsvExport";

type CsvExportProps = {
  compCycleId: number;
  compCycleName: string;
  variant: "icon" | "button" | "tableButton";
  employeeIds: number[];
};

export function EmployeeRecsCsvExportWrapper({
  compCycleId,
  compCycleName,
  variant,
  employeeIds,
}: CsvExportProps): JSX.Element {
  const { trackEvent } = useTrack();
  const [getEmployeeRecExport, { loading }] =
    useLazyQuery<GetEmployeeRecommendationsCsvExport>(
      GET_EMPLOYEE_RECOMMENDATIONS_CSV_EXPORT,
      {
        variables: {
          compCycleId,
          employeeIds,
        },
        fetchPolicy: "no-cache",
      }
    );

  const handleClick = async () => {
    trackEvent({ object: "Review Requests", action: "Exported" });
    return getEmployeeRecExport().then(
      (result) => result.data?.exportEmployeeRecommendationsToCsv.data
    );
  };

  return (
    <CsvDownloader
      errorMessage="There are no reviews to export."
      fetch={handleClick}
      filename={`${compCycleName} Employee Requests`}
    >
      {(onDownload) => (
        <CsvExport variant={variant} onClick={onDownload} loading={loading} />
      )}
    </CsvDownloader>
  );
}
