import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Box, LinearProgress } from "@material-ui/core";
import { AssembleButton } from "../components/AssembleButton/AssembleButton";
import { CsvDownloader } from "../components/CsvExport/CsvDownloader";
import { OrgCsvUploader } from "../components/OrgCsvUploader";
import { UploadLevelsButton } from "../components/UploadLevelsButton";
import { UploadUsersWithGrantsButton } from "../components/UploadUsersWithGrantsButton";
import {
  BulkEditOrgQuery,
  GetCsvDataWithDatabaseIds,
} from "../__generated__/graphql";

const jobStructureQuery = gql`
  query GetCsvDataWithDatabaseIds {
    exportOrgJobStructureWithBandsToCsv(includeDatabaseIds: true) {
      data
    }
  }
`;

export function BulkEditOrg(): JSX.Element {
  const { data, loading, error } = useQuery<BulkEditOrgQuery>(
    BulkEditOrg.query
  );

  const [getOrgHierarchy] = useLazyQuery<GetCsvDataWithDatabaseIds>(
    jobStructureQuery,
    { fetchPolicy: "no-cache", nextFetchPolicy: "no-cache" }
  );

  if (loading) return <LinearProgress />;
  if (error) return <p>{error.message}</p>;
  if (!data) return <p>Not found</p>;

  const { employees, positions, organization } = data;

  return (
    <Box>
      <Box display="flex">
        <CsvDownloader
          fetch={() =>
            getOrgHierarchy().then(
              (res) => res.data?.exportOrgJobStructureWithBandsToCsv.data
            )
          }
          filename={`${organization.name} - EDITING.csv`}
        >
          {(onDownload) => (
            <AssembleButton
              onClick={onDownload}
              variant="contained"
              label="Export all org data for editing"
              size="medium"
            />
          )}
        </CsvDownloader>
        <Box m={1} />
        <UploadLevelsButton
          organization={organization}
          employees={employees}
          positions={positions}
        />
        <Box m={1} />
        <UploadUsersWithGrantsButton organization={organization} />
      </Box>
      <OrgCsvUploader orgName={organization.name} />
    </Box>
  );
}

BulkEditOrg.query = gql`
  ${UploadLevelsButton.fragments.organization}
  ${UploadLevelsButton.fragments.employee}
  ${UploadLevelsButton.fragments.position}
  ${UploadUsersWithGrantsButton.fragments.organization}
  query BulkEditOrgQuery {
    organization {
      id
      ...UploadLevelsButton_organization
      ...UploadUsersWithGrantsButton_organization
    }
    employees {
      id
      ...UploadLevelsButton_employee
    }
    positions {
      id
      ...UploadLevelsButton_position
    }
  }
`;
