import { CurrencyCode } from "@asmbl/shared/constants";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CompComponent,
  CompUnit,
  RecItemType,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { CompSubComponent } from "../../../../models/Budget";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { SalaryInputCell } from "../../Cells/SalaryInputCell";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

interface Props extends CompCycleLineItemRowProps {
  label: string;
  baseSalary: Money | null;
  payCurrencyCode: CurrencyCode | null;
  recommendationType: RecItemType;
  subComponent: CompSubComponent;
  isHourly: boolean;
  guidance?: number;
}

export function SalaryIncreaseRow({
  employee,
  label,
  baseSalary,
  payCurrencyCode,
  revisedRecommendation,
  submittedRecommendation,
  recommendationType,
  onChangeRecommendationItem,
  subComponent,
  isHourly,
  guidance,
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const { Track } = useTrack({
    compensationComponent: CompComponent.SALARY,
    compensationSubComponent: subComponent,
    employeeId: employee.id,
  });
  const classes = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();
  const allowsHourly =
    (compStructure?.allowHourlyEmployees ?? false) &&
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles);
  const showHourly = allowsHourly && isHourly;
  const payIncrease = revisedRecommendation?.items.get(recommendationType);
  const [submitted, revised] = [
    submittedRecommendation,
    revisedRecommendation,
  ].map((x) => x?.items.get(recommendationType)?.recommendedCashValue?.value);
  const recCash = payIncrease?.recommendedCashValue ?? null;
  const applyGuidance = () => {
    if (guidance == null || baseSalary == null) return;

    onChangeRecommendationItem({
      note: "Applied from guidance suggestion",
      recommendationType: RecItemType.MERIT_INCREASE,
      recommendedPercentValue: guidance * 100,
      recommendedCashValue: {
        value:
          baseSalary.value !== 0 ? Math.round(baseSalary.value * guidance) : 0,
        currency: baseSalary.currency,
      },
      unitType: isHourly ? CompUnit.HOURLY_CASH : CompUnit.CASH,
    });
  };

  const hasGuidance = guidance != null;

  return (
    <Track>
      <TableRow aria-level={3} className={classes.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <TableCell
          role="gridcell"
          colSpan={5}
          padding="none"
          className={classes.textCell}
        >
          <Typography
            className={clsx(classes.rowLabel, {
              [classes.inline]: hasGuidance,
            })}
          >
            {label}
          </Typography>
          {hasGuidance && (
            <Typography
              className={clsx(
                classes.rowLabel,
                classes.guidanceLabel,
                classes.inline
              )}
            >
              Guidance:{" "}
              {formatNumeral(guidance, {
                style: "percent",
                maximumFractionDigits: 4,
              })}
              <AssembleButton
                size="small"
                variant="text"
                label="Apply"
                style={{ height: 24, marginLeft: 4 }}
                onClick={applyGuidance}
              />
            </Typography>
          )}
        </TableCell>
        <SalaryInputCell
          value={recCash}
          isHourly={showHourly}
          basePay={baseSalary}
          hasUnpublishedChanges={hasUnpublishedChanges(submitted, revised)}
          onChange={(newSalary, unitType) => {
            onChangeRecommendationItem({
              recommendationType,
              recommendedCashValue: newSalary,
              unitType,
              note: payIncrease?.note,
            });
          }}
          payCurrencyCode={payCurrencyCode}
        />
        <TableCell role="gridcell" className={classes.noteTextCell} colSpan={4}>
          <CompCycleLineItemRowButton
            disabled={
              payIncrease?.recommendedCashValue === undefined &&
              payIncrease?.recommendedPercentValue === undefined
            }
            employee={employee}
            note={payIncrease?.note ?? null}
            onClick={(note: string) =>
              onChangeRecommendationItem({
                ...payIncrease,
                recommendationType,
                note,
              })
            }
          />
        </TableCell>
        <TableCell role="gridcell" />
      </TableRow>
    </Track>
  );
}
