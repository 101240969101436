import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import {
  CompRecommendationStatus,
  CondensedTableStatusCell2_participant as Participant,
} from "src/__generated__/graphql";
import { theme } from "src/theme";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useStatusSelect } from "../Contexts/StatusSelectContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { ActivityLogButton } from "./Actions/ActivityLogButton";
import { CondensedTableStatusCellComponent } from "./CondensedTableStatusCellComponent";
import { CondensedTableStatusCellTooltip2 } from "./CondensedTableStatusCellTooltip2";
import { STATUS_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    padding: theme.spacing(1, 0),
    gap: theme.spacing(0.75),
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTableStatusCell2({ row }: Props): JSX.Element | null {
  const { setSelectedStatus } = useStatusSelect();
  const employee = row.original;
  const classes = useStyles();
  const { compCycleId } = useTableData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? `popper-${employee.subject.id}` : undefined;

  const status =
    employee.compRecommendation?.reviewStatus ??
    CompRecommendationStatus.NEEDS_REVIEW;

  return (
    <div onClick={() => setAnchorEl(null)}>
      <CondensedTableStatusCellTooltip2
        id={id}
        open={open}
        anchorEl={anchorEl}
        employee={employee}
      />
      <div
        aria-describedby={id}
        className={classes.root}
        onMouseLeave={() => setAnchorEl(null)}
        onMouseEnter={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
      >
        <CondensedTableStatusCellComponent
          statusToDisplay={status}
          onClick={setSelectedStatus}
        />
        <ActivityLogButton
          employeeId={employee.subject.id}
          compCycleId={compCycleId}
          lastActivityAt={employee.lastActivityAt}
        />
      </div>
    </div>
  );
}

CondensedTableStatusCell2.fragments = {
  participant: gql`
    ${CondensedTableStatusCellTooltip2.fragments.participant}
    fragment CondensedTableStatusCell2_participant on CompCycleParticipant {
      subject {
        id
      }
      lastActivityAt
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
      }
      phaseTimeline {
        id
        phaseId
        status
        phase {
          id
          phaseOrder
        }
      }
      ...CondensedTableStatusCellTooltip2_participant
    }
  `,
  compCycle: gql`
    ${CondensedTableStatusCellTooltip2.fragments.compCycle2}
    fragment CondensedTableStatusCell2_compCycle on CompCycle2 {
      id
      currentPhase {
        id
        phaseOrder
        startDate
      }
      ...CondensedTableStatusCellTooltip2_compCycle2
    }
  `,
};

CondensedTableStatusCell2.Header = ColumnIdsToHeaders.get(ColumnIds.STATUS);
CondensedTableStatusCell2.id = ColumnIds.STATUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableStatusCell2,
  Header: CondensedTableStatusCell2.Header,
  id: CondensedTableStatusCell2.id,
  width: STATUS_COL_WIDTH,
};

CondensedTableStatusCell2.column = column;
CondensedTableStatusCell2.ordering = () =>
  contramap((e: Participant) => {
    return (
      e.compRecommendation?.reviewStatus ??
      CompRecommendationStatus.NEEDS_REVIEW
    );
  });
