import { formatCurrency, Money } from "@asmbl/shared/money";
import { CurrencyCode } from "../__generated__/graphql";

const CURRENCY_NAMES: Record<CurrencyCode, string> = {
  AED: "United Arab Emirates Dirham",
  AMD: "Armenian Dram",
  ARS: "Argentine Peso",
  AUD: "Australian Dollar",
  BHD: "Bahrain Dinar",
  BRL: "Brazilian Real",
  CAD: "Canadian Dollar",
  CHF: "Swiss Franc",
  CLP: "Chilean Peso",
  CNY: "Chinese Yuan",
  COP: "Colombian Peso",
  CZK: "Czech Republic Koruna",
  DKK: "Danish Krone",
  EUR: "Euro",
  GBP: "British Pound",
  HKD: "Hong Kong Dollar",
  HUF: "Hungarian Forint",
  IDR: "Indonesian Rupiah",
  ILS: "Israeli Shekel",
  INR: "Indian Rupee",
  JOD: "Jordanian Dinar",
  JPY: "Japanese Yen",
  KES: "Kenyan Shilling",
  KRW: "Korean Won",
  KWD: "Kuwaiti Dinar",
  MXN: "Mexican Peso",
  NAD: "Namibian Dollar",
  NOK: "Norwegian Krone",
  NZD: "New Zealand Dollar",
  OMR: "Omani Rial",
  PHP: "Philippine Peso",
  PKR: "Pakistani Rupee",
  PLN: "Polish Zloty",
  QAR: "Qatari Rial",
  RON: "Romanian Leu",
  RUB: "Russian Ruble",
  SAR: "Saudi Riyal",
  SEK: "Swedish Krona",
  SGD: "Singapore Dollar",
  THB: "Thai Baht",
  TND: "Tunisian Dinar",
  TRY: "Turkish Lira",
  TWD: "New Taiwan Dollar",
  USD: "US Dollar",
  UYU: "Uruguayan Peso",
  XPF: "Tahitian Franc",
  ZAR: "South African Rand",
} as const;

export function currencyName(code: CurrencyCode): string {
  return CURRENCY_NAMES[code];
}

export function currencySymbol(currency: CurrencyCode): string {
  return formatCurrency({ value: 1, currency }).split(/[\s\d]/)[0];
}

/*
 * Sorting Currencies
 *
 * Currencies are primarily displayed in lexicographic order based
 * on their display name. (example: ZAR is sorted with South Africa)
 *
 * A few currencies are commonly used by customers, so we explicitly rank them
 * higher.
 */

function priorityCurrencyRank(code: CurrencyCode): number {
  switch (code) {
    case CurrencyCode.USD:
      return 0;
    case CurrencyCode.EUR:
      return 1;
    case CurrencyCode.GBP:
      return 2;
    default:
      return Number.MAX_SAFE_INTEGER;
  }
}
export function currencyComparator(a: CurrencyCode, b: CurrencyCode): number {
  // USD, EUR, and GPB are prioritized at the top.
  const priorityComparison = priorityCurrencyRank(a) - priorityCurrencyRank(b);

  if (priorityComparison !== 0) {
    return priorityComparison;
  }

  // Otherwise, lexicographic sort based on the display name
  const aname = currencyName(a);
  const bname = currencyName(b);
  return bname > aname ? -1 : bname < aname ? 1 : 0;
}

export const ALL_CURRENCIES =
  Object.values(CurrencyCode).sort(currencyComparator);

/*
 * Formatting Currencies
 *
 * These functions are convenient wrappers around Intl.NumberFormat.
 * We reuse as much standard, built-in behavior as possible when formatting
 * numbers. However, there are several common formats which are too verbose
 * to rewrite over and over.
 *
 * If you need a different format, you can specify additional options that
 * get passed into the underlying Intl.NumberFormat. Using these helpers is
 * encouraged, and calling Intl.NumberFormat directly in some places is
 * lightly discouraged. Formatting numbers using something other than these
 * helpers or Intl.NumberFormat (e.g. `${value}`) is STRONGLY discouraged,
 * to avoid issues when handling multiple different currencies.
 */

export function getSimpleCashLabel(
  money: Money,
  compact = false,
  isHourly = false
): string {
  return isHourly
    ? getHourlyCashLabel(money)
    : formatCurrency(money, {
        notation: compact ? "compact" : undefined,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
}

export function getAccurateSimpleCashLabel(money: Money): string {
  return formatCurrency(money, {
    notation: "compact",
    maximumSignificantDigits: 3,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function getHourlyCashLabel(money: Money, options = {}): string {
  return `${formatCurrency(money, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  })}/hr`;
}
